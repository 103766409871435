import React, { useEffect, useState } from "react";

import "./company.scss";
import { useTranslation } from "react-i18next";
import { CompanyDto, CompanyPageDto, CompanySuggestion, useGetCompanyPageQuery } from "../../hooks/slices/companySlice";
import { skipToken } from "@reduxjs/toolkit/query";
import { useNavigate } from "react-router-dom";
import { QueryParameters } from "../tenderDetails/main/TenderDetailsView";
import { RaiseUser } from "../../hooks/raiseUser";
import { ErrorPage } from "../error";
import { showErrorPopup, updateSnacc } from "../../hooks/slices/snaccSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { StarFillIcon, StarIcon } from "../../components/icons";
import {
  addCompanyPageInteraction,
  addUserInteraction,
  CompanyPageInteraction,
  removeCompanyPageInteraction,
  removeUserInteraction,
  useAddUserInteractionMutation,
  useRemoveUserInteractionMutation,
  UserInteraction,
  UserInteractionInput,
} from "../../hooks/slices/userSlice";
import { UserInteractionType } from "../../consts/UserInteractionType";
import { ComponentSelector } from "../../components/componentSelector";
import { CompanySummaryPage as CompanyPageNew } from "./companyPage1";

export const CompanyPage: React.FC<{ government: boolean }> = ({ government }) => {
  let queryParameters = QueryParameters();
  let uuid: string = queryParameters.uuid;
  if (!uuid) return <ErrorPage></ErrorPage>;
  else {
    return <CompanyPageNew government={government} />;
  }
};

interface CompanyPageWithDataProps {
  uuid: string;
  languageIso: string;
}

export const CompanyPageWithData: React.FC<CompanyPageWithDataProps> = ({ uuid, languageIso }) => {
  let { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data, isLoading, isSuccess, error, isError } = useGetCompanyPageQuery({ uuid: uuid } ?? skipToken);
  const [companyPage, setCompanyPage] = useState<CompanyPageDto | null>(null);
  const autocomplete = (suggestion: CompanySuggestion) => {
    setCompanyPage(null);
    navigate("/company/" + suggestion.uuid);
  };
  useEffect(() => {
    if (isSuccess && data) {
      setCompanyPage(data);
    } if (error && isError){
      dispatch(updateSnacc({
            message: t("companyPage.unknown_company") + " " + uuid,
            severity: "error",
            open: true
          }
      ))
    }
  }, [data, isSuccess, error, isError]);

  return (
    <>
      <ComponentSelector tabs={[]}>

      </ComponentSelector>
    </>
  );
};

interface CompanyPageCardProps {
  title: string;
}

export const CompanyPageCard: React.FC<CompanyPageCardProps> = ({ title, children }) => {
  return (
    <div className={"company-info-card"}>
      <div className="company-info-card-content">
        <div className="company-info-card-title">
          <button>{title}</button>
        </div>
        <div className="company-info-card-body">{children}</div>
      </div>
    </div>
  );
};

const CompanyInfoCard: React.FC<CompanyPageDto> = ({ company }) => {
  let { t } = useTranslation();
  let description: CompanyDetailsContentItem[] = getCompanyDescriptionRows(company);
  const combinedActivities: CompanyDetailsContentItem[] = getCompanyActivities(company);
  let accreditations: CompanyDetailsContentItem[] = getCompanyAccreditations(company);
  let accreditationsRequirePreview: boolean = accreditations.length > 6;
  let accreditationsPreview = accreditationsRequirePreview ? accreditations.slice(0, 6) : accreditations;
  const [accreditationsUnfolded, setAccreditationsUnfolded] = useState(false);
  return (
    <>
      <CompanyPageCard
        title={t("companyPage.company_information_section_title")}
        children={
          <>
            <FollowCompanyButton company={company} />
            <CompanyDetailsSection title={company.name} content={description} />
            {combinedActivities && combinedActivities.length > 0 && (
              <>
                <CompanyDetailsSection
                  title={t("companyPage.main_activities_section_title")}
                  content={combinedActivities}
                  key={t("companyPage.main_activities_section_title") + " - " + company.uuid}
                />
                {accreditations && accreditations.length > 0 &&
                    <CompanyDetailsSection
                        title={t("companyPage.accreditations_title")}
                        content={accreditationsUnfolded ? accreditations : accreditationsPreview}
                        key={t("companyPage.accreditations_title") + " - " + company.uuid}
                        scroll={false}
                    />}
                {accreditationsRequirePreview &&
                    <div className={"company-info-body-section"} style={{marginTop: '-12px', cursor: 'pointer'}}
                         onClick={() => setAccreditationsUnfolded(!accreditationsUnfolded)}>
                      <div>
                        <div>
                          <p>...</p>
                        </div>
                      </div>
                    </div>
                }

              </>
            )}
          </>
        }
      />
    </>
  );
};

const FollowCompanyButton: React.FC<{ company: CompanyDto }> = ({company}) => {

  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const userUuid = useAppSelector((state) => state.user.uuid);
  const userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
  const companyPageInteractions: CompanyPageInteraction[] = RaiseUser().user?.companyPageInteractions ?? [];
  const [addUserInteractionToDb, {
    isError: addUserInteractionsError,
    error: addUserInteractionsErrorObject
  }] = useAddUserInteractionMutation();
  const [removeUserInteractionFromDb, {
    isError: removeUserInteractionsError,
    error: removeUserInteractionsErrorObject
  }] = useRemoveUserInteractionMutation();

  const findCompanyPageUuid = (): string | undefined => {
    if (userInteractions.length === 0 || companyPageInteractions.length === 0) return undefined;
    else return companyPageInteractions.find(cp => cp.companyPageVatNumber === company.vatNumber)?.companyPageUuid;
  }

  const isFollowedCompany = (): boolean => {return findCompanyPageUuid() != null;}

  let followCompany = () => {
    if (userUuid && company.uuid) {
      let interaction: UserInteraction = { uuid: company.uuid, interactionType: UserInteractionType.FOLLOW_COMPANY };
      let interactionInput: UserInteractionInput = { userUuid: userUuid, interaction: interaction };
      addUserInteractionToDb(interactionInput); //update DB
      dispatch(addUserInteraction(interactionInput.interaction)); //update search state
      dispatch(addCompanyPageInteraction({companyPageUuid: company.uuid, companyPageVatNumber: company.vatNumber, companyPageName: company.name})); //update search state
    }
  }

  let unfollowCompany = () => {
    if (userUuid && company.uuid) {
      let interaction: UserInteraction = { uuid: company.uuid, interactionType: UserInteractionType.FOLLOW_COMPANY };
      let interactionInput: UserInteractionInput = { userUuid: userUuid, interaction: interaction };
      removeUserInteractionFromDb(interactionInput); //update DB
      dispatch(removeUserInteraction(interactionInput.interaction)); //update search state
      dispatch(removeCompanyPageInteraction({companyPageUuid: company.uuid})); //update search state
    }
  }

  useEffect(() => {
    if (removeUserInteractionsError && removeUserInteractionsErrorObject) {
      dispatch(showErrorPopup(removeUserInteractionsErrorObject));
    } else if (addUserInteractionsError && addUserInteractionsErrorObject) {
      dispatch(showErrorPopup(addUserInteractionsErrorObject));
    }
  }, [removeUserInteractionsError, removeUserInteractionsErrorObject]);


  return <span className={"follow-button"}>
            {isFollowedCompany() && <span onClick={() => unfollowCompany()}><StarFillIcon/></span>}
            {!isFollowedCompany() && <span onClick={() => followCompany()} title={t("companyPage.follow_company")}><StarIcon/></span>}
          </span>;
};

export function getCompanyDescriptionRows(company: CompanyDto): CompanyDetailsContentItem[] {
  let description: CompanyDetailsContentItem[] = [];
  if (company.vatNumber != null && company.vatNumber.length > 2)
    description.push({ value: company.vatNumber.slice(2) });
  if (company.street != null) description.push({ value: company.street });
  if (company.postalCode != null && company.city != null)
    description.push({ value: company.postalCode + " " + company.city });
  if (company.website != null) description.push({ value: company.website, href: company.website });
  if (company.phone != null) description.push({ value: company.phone });
  return description;
}

//There are always at most two rsz activities and we're only interested in showing three entries at most, so we take the
//rsz activities first (these are more interesting) and then complete the list with btw activities that are not overlapping
//with the rsz activities.
export function getCompanyActivities(company: CompanyDto): CompanyDetailsContentItem[]{
  const rszActivities = company.activities
      .filter((a) => a.type === 'RSZ')
      .slice(0, 2)
      .map((a) => (a.description.includes(" - ") ? a.description.split(" - ")[1] : a.description));
  const numBtwActivities = rszActivities.length === 1 ? 2 : 1; // If there is only one RSZ activity, show 2 BTW activities, otherwise show 1
  const btwActivities = company.activities
      .filter((a) => a.type === 'BTW' && !rszActivities.includes((a.description.includes(" - ") ? a.description.split(" - ")[1] : a.description)))
      .slice(0, numBtwActivities)
      .map((a) => (a.description.includes(" - ") ? a.description.split(" - ")[1] : a.description));
  const combinedActivities: CompanyDetailsContentItem[] = [...rszActivities, ...btwActivities].map(activity => ({
    value: activity
  })); return combinedActivities;
}

export function getCompanyAccreditations(company: CompanyDto): CompanyDetailsContentItem[]{
  const accreditations: string[] = company.activities
      .filter((a) => a.type === 'ACCREDITATION')
      .map(a => a.description);
  accreditations.sort();
  return accreditations.map(activity => ({value: activity}))
}

export interface CompanyDetailsProps {
  title?: string;
  content?: CompanyDetailsContentItem[];
  columns?: boolean;
  scroll?: boolean;
}

export interface CompanyDetailsContentItem {
  value: string;
  href?: string;
  followCompanyButton?: CompanyDto;
}

export const CompanyDetailsSection: React.FC<CompanyDetailsProps> = ({ title, content, columns, scroll }) => {
  const containsNullValues = (c: CompanyDetailsContentItem[]) => c.some((c) => c.value === null || c.value === undefined);
  const addProtocolToUrl = (url: string) => {
    if (url.startsWith("www")) {
      return "https://" + url;
    } return url;
  }
  return (
    <>
      <div className={"company-info-body-section"}>
        {title && <h2>{title}</h2>}
        <div className={(scroll ? " scroll" : "") + (columns ? " columns" : "")}>
          {content &&
              !containsNullValues(content) &&
              content.map((c, i) => (
                  <div style={{display: 'grid', gridTemplateColumns: '9fr 1fr'}} key={i}>
                    <p>
                      {c.href ?
                          <a className={"hover-highlight"} href={addProtocolToUrl(c.href)}>{c.value}</a> : c.value}
                    </p>
                    {c.followCompanyButton && <FollowCompanyButton company={c.followCompanyButton}/>}
                  </div>

              ))}
        </div>
      </div>
    </>
  );
};
