import {
    useTranslation,
} from "react-i18next";
import {
    useNavigate,
} from "react-router-dom";
import { 
    getProgressBarPercentage, 
} from "../../getProgressBarPercentage";


export const useProjectsContractedProgressCircle = (
    companyName: string,
    originalCompanyName: string,
    projectsContracted: number,
) => {
    const {t} = useTranslation();
    const navigate = useNavigate();

    const topText = t("companyPage.projects_contracted_title_1");
    const middleText = projectsContracted + "";
    const bottomText = t("companyPage.projects_contracted_title_2");

    const progressPercentage = getProgressBarPercentage(projectsContracted)

    return {
        topText,
        middleText,
        bottomText,
        progressPercentage,
    }
}

