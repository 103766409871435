import React from 'react';
import styles from './index.module.scss';

export interface SubTabListProps{
    isActivated?: boolean;
}
export const SubTabList: React.FC<SubTabListProps> = ({
    children,

    isActivated = false,
}) => {
    return (<div 
        className={`
            ${styles['container']}
            ${isActivated ? styles['active'] : ''}
        `}
    >
        <div
            className={styles['tab-list']}
        >
            {children}
        </div>
    </div>);
};