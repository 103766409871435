import styles from "./companyPage1.module.scss";
import "../search/results/searchResults.scss";

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TenderCard, TenderCardConfiguration, TenderCardSkeleton } from "../search/results/body/TenderCard";
import { useAppSelector } from "../../app/hooks";
import { CompaniesList } from "../../components/companiesList";
import { CompanyCard } from "../../components/companySummaryCard";
import { UserInteractionType } from "../../consts/UserInteractionType";
import { RaiseUser, UserLanguage } from "../../hooks/raiseUser";
import {
  BuyerDto,
  CompanyPageDto,
  CompetitorDto,
  RecentTenderDto,
  useGetCompanyPageBuyersQuery,
  useGetCompanyPageCompetitorsQuery,
  useGetCompanyPageQuery,
} from "../../hooks/slices/companySlice";
import { LabelTenderDto } from "../../hooks/slices/labelSlice";
import { QueryParameters } from "../../pages/tenderDetails/main/TenderDetailsView";
import { LabelCategory } from "../../types/label";

import {
  CompanyPageHeaderInfoElements,
  CompanyPageHeaderTitle,
  CompanyPageSubTabList,
  CompanyPageTabs,
} from "./companyPage1Parts";
import { CompanyOverviewCard } from "../../components/companyOverviewCard";

import { CompanyExperienceProgressCircle } from "../../components/companySummaryCard/subComponents/companyExperienceProgressCircle";
import { Loader } from "../../components/loader";
import { skipToken } from "@reduxjs/toolkit/query";
import { CompanyOrientationProgressCircle } from "../../components/companySummaryCard/subComponents/companyOrientationProgressCircle";
import { CompanyLotValueProgressCircle } from "../../components/companySummaryCard/subComponents/companyLotValueProgressCircle";
import { Paginate } from "../../components/paginate";
import { CompanyWinRateProgressCircle } from "../../components/companySummaryCard/subComponents/companyWinRateProgressCircle";
import { TenderSearchInput, useGetTenderSearchMutation } from "../../hooks/slices/tenderSearchSlice";
import { SearchType } from "../../consts/searchType";
import { SearchPhase } from "../../consts/searchPhase";

const PAGE_SIZE_TENDERS = 20;
const PAGE_SIZE_COMPANIES = 20;

export const CompanySummaryPage: React.FC<{government: boolean}> = ({government}) => {
  let queryParameters = QueryParameters();
  let uuid: string = queryParameters.uuid;
  let {i18n} = useTranslation();
  let languageIso = queryParameters.tenderLanguage ?? UserLanguage(i18n.language);
  let { t } = useTranslation();
  const userInStore = RaiseUser();
  const userInteractions=userInStore.user?.userInteractions ?? [];
  const leads = userInteractions
    .filter(i => i.interactionType === UserInteractionType.MAKE_LEAD)
    .map(i => i.uuid)
  ;
  const isDeletedOpportunities = userInteractions
    .filter(i => i.interactionType === UserInteractionType.DELETE_OPPORTUNITY)
    .map(i => i.uuid)
  ;
  const labels: LabelTenderDto[] | undefined = useAppSelector(
    (state) => state.tenderSearch.responseLabels
  );
  const getLabels = (tenderUuid: string): LabelTenderDto[] => {
      if (labels !== undefined) return labels.filter(l => l.tenderUuid === tenderUuid);
      else return [];
  }
  const getLabelCategories = (): LabelCategory[] => {
      return (userInStore.user && userInStore.user.labelCategories !== undefined) 
        ? userInStore.user.labelCategories 
        : []
      ;
  }
  //There are five queries that need to be performed here: (1) the company page, (2) the tenders won by the company,
  // (3) the tenders participated in by the company, (4) the competitors of the company, and (5) the buyers of the
  // company. The first is performed automatically and the others are performed via the setFetch... functions.
  const { data: companyPage, isLoading: companyPageIsLoading } = useGetCompanyPageQuery({ uuid: uuid });
  const [fetchTendersWon, setFetchTendersWon] = useState<boolean>(false);
  const [tendersWonPage, setTendersWonPage] = useState<number>(0);
  const [getTendersWon, {data: tendersWonSearchOutput, isLoading: tendersWonIsLoading}] = useGetTenderSearchMutation();
  const [fetchTendersParticipatedIn, setFetchTendersParticipatedIn] = useState<boolean>(false);
  const [tendersParticipatedInPage, setTendersParticipatedInPage] = useState<number>(0);
  const [getTendersParticipatedIn, {data: tendersParticipatedInSearchOutput, isLoading: tendersParticipatedIsLoading}] = useGetTenderSearchMutation();
  const [fetchTendersContractedOpen, setFetchTendersContractedOpen] = useState<boolean>(false);
  const [tendersContractedOpenPage, setTendersContractedOpenPage] = useState<number>(0);
  const [getTendersContractedOpen, {data: tendersContractedOpenSearchOutput, isLoading: tendersContractedOpenIsLoading}] = useGetTenderSearchMutation();
  const [fetchTendersContractedClosed, setFetchTendersContractedClosed] = useState<boolean>(false);
  const [tendersContractedClosedPage, setTendersContractedClosedPage] = useState<number>(0);
  const [getTendersContractedClosed, {data: tendersContractedClosedSearchOutput, isLoading: tendersContractedClosedIsLoading}] = useGetTenderSearchMutation();
  const [ fetchCompetitors, setFetchCompetitors] = useState<boolean>(false);
  const [ competitorsPage, setCompetitorsPage ] = useState<number>(0);
  const { data: competitors, isFetching: competitorsIsLoading } = useGetCompanyPageCompetitorsQuery(
    fetchCompetitors
    ? { uuid: uuid,
        page: competitorsPage,
        pageSize: PAGE_SIZE_COMPANIES,
    } : skipToken);
  const [ fetchBuyers, setFetchBuyers ] = useState<boolean>(false);
  const [ buyersPage, setBuyersPage ] = useState<number>(0);
  const { data: buyers, isFetching: buyersAreLoading } = useGetCompanyPageBuyersQuery(
    fetchBuyers
    ? { uuid: uuid,
        page: buyersPage,
        pageSize: PAGE_SIZE_COMPANIES, 
    } : skipToken);
  // let competitorsInput: string | undefined = companyPage?.competitors.length > 0 ? companyPage?.company.uuid : undefined;
  const [ lastSelectedTenderingOption, setLastSelectedTenderingOption ] = useState<string[]>(["tendering", "tenders_recently_won"]);
  
  const companyPageData: CompanyPageDto | null = companyPage ?? null;

  let tendersParticipatedIn: RecentTenderDto[] = companyPageData?.tendersParticipatedIn ?? [];
  let tendersParticipatedInWithAwards: RecentTenderDto[] = tendersParticipatedIn.filter(t => t.type === "CONTRACT_AWARD");
  let tendersWon: RecentTenderDto[] = companyPageData?.tendersWon ?? [];
  let tendersWonUuids: string[] = tendersWon.map(t => t.uuid);
  let tendersLost: RecentTenderDto[] = tendersParticipatedInWithAwards.filter(t => t.type === "CONTRACT_AWARD" && !tendersWonUuids.includes(t.uuid));

  let allTendersContracted: RecentTenderDto[] = companyPageData?.tendersContractedOpen ?? [];
    if(companyPageData?.tendersContractedClosed) allTendersContracted = allTendersContracted.concat(companyPageData.tendersContractedClosed);

  const hasTendersWon : boolean = companyPageData ? companyPageData.tendersWon.length > 0: false;
  const hasTendersParticipatedIn :boolean= companyPageData ? companyPageData.tendersParticipatedIn.length > 0: false;
  const hasBuyers :boolean = companyPageData ? companyPageData.buyers.length > 0: false;
  const hasCompetitors : boolean = companyPageData ? companyPageData.competitors.length > 0: false;
  const hasTendersFocus : boolean = companyPageData ? companyPageData.mainActivities.length > 0: false;
  const hasTendersContractedOpen : boolean = companyPageData ? companyPageData.tendersContractedOpen.length > 0: false;
  const hasTendersContractedClosed : boolean = companyPageData ? companyPageData.tendersContractedClosed.length > 0: false;
  const hasTenderingContent : boolean = government
          ? (hasTendersContractedOpen || hasTendersContractedClosed)
          : (hasTendersParticipatedIn || hasBuyers || hasTendersFocus);


  const [activeTab, setActiveTab] = useState<string[]>(["company"]);

  const tenderCardConfiguration: TenderCardConfiguration = {
    showProgress: false,
    showDescription: true,
    showCompanies: true,
    showCueHighlights: true,
    showQueryHighlights: false,
    showLatestPublicationTypeInUpperRightCorner: true,
    showPublicationDetails: true,
    showTags: true,
    showLeadsIcon: true,
    showFollowIcon: true,
    showDeleteOpportunityIcon: true,
    showProfiles: false
  };

  useEffect(() => {
      if(companyPage) {
        if (government){
          if (hasTendersContractedOpen) setFetchTendersContractedOpen(true);
          if (hasTendersContractedClosed) setFetchTendersContractedClosed(true);
          if(hasTendersContractedOpen) setActiveTab(["tendering", "tenders_contracted_open"]);
          else if (hasTendersContractedClosed) setActiveTab(["tendering", "tenders_contracted_closed"]);
        } else {
          if(hasTendersWon) setFetchTendersWon(true);
          if(hasTendersParticipatedIn) setFetchTendersParticipatedIn(true);
          if(hasCompetitors) setFetchCompetitors(true);
          if(hasBuyers) setFetchBuyers(true);
          if (hasTendersWon) setActiveTab(["tendering", "tenders_recently_won"]);
          else if (hasTendersParticipatedIn) setActiveTab(["tendering", "tenders_recently_participated_in"]);
        }
      }
  }, [companyPage]);
  useEffect(() => {
    if (companyPage){
      getTendersWon(tenderSearchInput(companyPage.tendersWon.map(t => t.uuid), tendersWonPage, PAGE_SIZE_TENDERS));
    }
  }, [fetchTendersWon, tendersWonPage]);
  useEffect(() => {
    if (companyPage) {
      getTendersParticipatedIn(tenderSearchInput(companyPage.tendersParticipatedIn.map(t => t.uuid), tendersParticipatedInPage, PAGE_SIZE_TENDERS));
    }
  }, [fetchTendersParticipatedIn, tendersParticipatedInPage]);
  useEffect(() => {
    if (companyPage) {
        getTendersContractedOpen(tenderSearchInput(companyPage.tendersContractedOpen.map(t => t.uuid), tendersContractedOpenPage, PAGE_SIZE_TENDERS));
    }
  }, [fetchTendersContractedOpen, tendersContractedOpenPage]);
  useEffect(() => {
    if (companyPage) {
        getTendersContractedClosed(tenderSearchInput(companyPage.tendersContractedClosed.map(t => t.uuid), tendersContractedClosedPage, PAGE_SIZE_TENDERS));
    }
  }, [fetchTendersContractedClosed, tendersContractedClosedPage]);

  const companyIsFollowed = userInteractions.some(i => 
      i.interactionType === UserInteractionType.FOLLOW_COMPANY 
      && i.uuid === companyPage?.company.uuid
  );

  return (<div className={styles['container']}>
    {companyPageIsLoading  && <div className={styles['spacer-while-loading']}><Loader /></div> }
    {companyPage &&  companyPageData && <CompanyCard>
      <div className={styles['company-header']}>
        <div className={styles['company-header-left']}>
          <CompanyPageHeaderTitle
            company={companyPage.company}
            companyIsFollowed={companyIsFollowed}
          />
          <CompanyPageHeaderInfoElements
            city={companyPage.company.city}
            postalCode={companyPage.company.postalCode}
            street={companyPage.company.street}
            vatNumber={companyPage.company.vatNumber}
            website={companyPage.company.website}
          />
          <div /> {/* empty div for spacing */}
        </div>
        <div className={styles['company-header-right']}>
          {!government &&
          <CompanyWinRateProgressCircle 
            tendersWon={tendersWon}
            tendersLost={tendersLost}
          />}
          <CompanyLotValueProgressCircle tenders={government ? allTendersContracted : tendersWon}/>
          <CompanyOrientationProgressCircle tenders={government ? allTendersContracted : tendersWon}/>
          <CompanyExperienceProgressCircle tenders={government ? allTendersContracted : tendersWon}/>
        </div>

      </div>

      <CompanyCard.TabsContainer>
       <CompanyPageTabs activeTab={activeTab} setActiveTab={setActiveTab}
          tabs={[
            ...(hasTenderingContent ? [{ index: lastSelectedTenderingOption, label: t("companyPage.tendering")}] : []),
            { index: ["company"], label :t("companyPage.company_information_section_title")},
            ...((companyPageData.competitors.length > 0 && !government)
              ? [{ 
                index: ["competitors"], 
                label: `
                  ${t("companyPage.competitors")} 
                  (${companyPageData.competitors.length })
                `, 
              }] 
              : []
            )
          ]}
        />

        <div className={styles['tab-panel-container']}>  
          <> {/* Info */}
            {companyPageData && <CompanyCard.TabPanelInfo
                isActive={(activeTab[0] === 'company')}
                companyPage={companyPageData}
            />}
          </>
          <> {/* Tendering */}
            <CompanyPageSubTabList activeTab={activeTab} setActiveTab={setActiveTab} setLastSelectedOption={setLastSelectedTenderingOption}
              indexStart="tendering" subTabs={[
                ...( (!government && hasTendersWon)
                  ? [{ 
                    indexEnd: "tenders_recently_won", 
                    label: `
                      ${t("companyPage.recently_won_section_title")} 
                      (${companyPageData.tendersWon.length})
                    `, 
                  }]
                  : []
                ),
                ...( (!government && hasTendersParticipatedIn)
                  ? [{ 
                    indexEnd: "tenders_recently_participated_in", 
                    label: `
                      ${t("companyPage.recently_participated_in_section_title")}
                      (${companyPageData.tendersParticipatedIn.length})
                    `,
                  }]
                  : []
                ),
              ...( (government && hasTendersContractedOpen)
                      ? [{
                        indexEnd: "tenders_contracted_open",
                        label: `
                      ${t("companyPage.contracted_open_section_title")}
                      (${companyPageData.tendersContractedOpen.length})
                    `,
                      }]
                      : []
              ),
              ...( (government && hasTendersContractedClosed)
                      ? [{
                        indexEnd: "tenders_contracted_closed",
                        label: `
                      ${t("companyPage.contracted_closed_section_title")}
                      (${companyPageData.tendersContractedClosed.length})
                    `,
                      }]
                      : []
              ),
                ...( (!government && hasBuyers)
                  ? [{ 
                    indexEnd: "tenders_main_buyers", 
                    label: `
                      ${t("companyPage.main_buyers_section_title")}
                      (${buyers?.buyers.length ?? ""})
                    `,
                  }]
                  : []
                ),
                ...( hasTendersFocus 
                  ? [{ 
                    indexEnd: "tenders_focus", 
                    label: `
                      ${t("companyPage.main_activities_section_title")}
                    ` ,
                  }]
                  : []),
              ]}
            />
            <> {/* Tendering SubTabs */}
              { (tendersWonSearchOutput || tendersWonIsLoading) && <CompanyCard.TabPanelTendersRecentlyWon
                isActive={ 
                  (activeTab[0] === 'tendering') 
                  && (activeTab[1] === 'tenders_recently_won')
                }
              >
                <div className="search-filter-section-alignment">
                  <div className='search-main-card-show'>
                    {tendersWonSearchOutput && tendersWonSearchOutput.tenders.map((hit, i) => <TenderCard
                      key={hit.tender.uuid}
                      tenderSearchHit={hit}
                      languageIso={languageIso}
                      configuration={tenderCardConfiguration}
                      isLead={leads.includes(hit.tender.uuid)}
                      isDeletedOpportunity={isDeletedOpportunities.includes(hit.tender.uuid)}
                      getLabels={getLabels}
                      getLabelCategories={getLabelCategories}
                    />)}
                    {tendersWonIsLoading && <SearchResultsSkeleton/>}
                  </div>
                  <Paginate
                    currentPage={tendersWonPage}
                    totalHits={companyPage.tendersWon.length}
                    itemsPerPage={PAGE_SIZE_TENDERS}
                    setPage={setTendersWonPage}
                  />
                </div>
              </CompanyCard.TabPanelTendersRecentlyWon>}
          
              { (tendersParticipatedInSearchOutput || tendersParticipatedIsLoading) && <CompanyCard.TabPanelTendersRecentlyParticipatedIn
                isActive={ 
                  (activeTab[0] === 'tendering') 
                  && (activeTab[1] === 'tenders_recently_participated_in')
                }
              >
                <div className="search-filter-section-alignment">
                  <div className='search-main-card-show'>
                    {tendersParticipatedInSearchOutput && tendersParticipatedInSearchOutput?.tenders.map((hit, i) => <TenderCard
                      key={hit.tender.uuid}
                      tenderSearchHit={hit}
                      languageIso={languageIso}
                      configuration={tenderCardConfiguration}
                      isLead={leads.includes(hit.tender.uuid)}
                      isDeletedOpportunity={isDeletedOpportunities.includes(hit.tender.uuid)}
                      getLabels={getLabels}
                      getLabelCategories={getLabelCategories}
                    />)}
                    {tendersParticipatedIsLoading && <SearchResultsSkeleton/>}
                  </div>
                  <Paginate
                    currentPage={tendersParticipatedInPage}
                    totalHits={companyPage.tendersParticipatedIn.length}
                    itemsPerPage={PAGE_SIZE_TENDERS}
                    setPage={setTendersParticipatedInPage}
                  />
                </div>
              </CompanyCard.TabPanelTendersRecentlyParticipatedIn>}

              { (tendersContractedOpenSearchOutput || tendersContractedOpenIsLoading) && <CompanyCard.TabPanelTendersRecentlyParticipatedIn
                  isActive={
                      (activeTab[0] === 'tendering')
                      && (activeTab[1] === 'tenders_contracted_open')
                  }
              >
                <div className="search-filter-section-alignment">
                  <div className='search-main-card-show'>
                    {tendersContractedOpenSearchOutput && tendersContractedOpenSearchOutput?.tenders.map((hit, i) => <TenderCard
                        key={hit.tender.uuid}
                        tenderSearchHit={hit}
                        languageIso={languageIso}
                        configuration={tenderCardConfiguration}
                        isLead={leads.includes(hit.tender.uuid)}
                        isDeletedOpportunity={isDeletedOpportunities.includes(hit.tender.uuid)}
                        getLabels={getLabels}
                        getLabelCategories={getLabelCategories}
                    />)}
                    {tendersContractedOpenIsLoading && <SearchResultsSkeleton/>}
                  </div>
                  <Paginate
                      currentPage={tendersContractedOpenPage}
                      totalHits={companyPage.tendersContractedOpen.length}
                      itemsPerPage={PAGE_SIZE_TENDERS}
                      setPage={setTendersContractedOpenPage}
                  />
                </div>
              </CompanyCard.TabPanelTendersRecentlyParticipatedIn>}

              { (tendersContractedClosedSearchOutput || tendersContractedClosedIsLoading) && <CompanyCard.TabPanelTendersRecentlyParticipatedIn
                  isActive={
                      (activeTab[0] === 'tendering')
                      && (activeTab[1] === 'tenders_contracted_closed')
                  }
              >
                <div className="search-filter-section-alignment">
                  <div className='search-main-card-show'>
                    {tendersContractedClosedSearchOutput && tendersContractedClosedSearchOutput?.tenders.map((hit, i) => <TenderCard
                        key={hit.tender.uuid}
                        tenderSearchHit={hit}
                        languageIso={languageIso}
                        configuration={tenderCardConfiguration}
                        isLead={leads.includes(hit.tender.uuid)}
                        isDeletedOpportunity={isDeletedOpportunities.includes(hit.tender.uuid)}
                        getLabels={getLabels}
                        getLabelCategories={getLabelCategories}
                    />)}
                    {tendersContractedClosedIsLoading && <SearchResultsSkeleton/>}
                  </div>
                  <Paginate
                      currentPage={tendersContractedClosedPage}
                      totalHits={companyPage.tendersContractedClosed.length}
                      itemsPerPage={PAGE_SIZE_TENDERS}
                      setPage={setTendersContractedClosedPage}
                  />
                </div>
              </CompanyCard.TabPanelTendersRecentlyParticipatedIn>}

              <CompanyCard.TabPanelBuyers
                isActive={
                  (activeTab[0] === 'tendering')
                  && (activeTab[1] === 'tenders_main_buyers')
                }
              >
                <CompaniesList
                  fill={true}
                > 
                  {buyersAreLoading && <SearchResultsSkeleton/>}
                  {!buyersAreLoading && buyers && buyers.buyers
                    .filter(b => b.sharedProjectsWon.length>0)
                    .map((buyer: BuyerDto, i) =>
                      <CompanyOverviewCard 
                        key={buyer.company.uuid} 
                        originalCompanyName={companyPageData.company.name} 
                        company={buyer.company} 
                        projectsContracted={buyer.projectsContracted} 
                        sharedProjectsWon={buyer.sharedProjectsWon}
                        companyType={"contracting authority"}
                      />
                    )}
                </CompaniesList>
                <Paginate
                  currentPage={buyersPage}
                  totalHits={buyers?.buyers.length ?? 0}
                  itemsPerPage={PAGE_SIZE_COMPANIES}
                  setPage={setBuyersPage}
                />
              </CompanyCard.TabPanelBuyers>

              <CompanyCard.TabPanelTendersFocus
                isActive={ 
                  (activeTab[0] === 'tendering') 
                  && (activeTab[1] === 'tenders_focus')
                }
                mainActivities={companyPageData.mainActivities}
              />
            </>
          </>

          <> {/* Competitors */}
            <CompanyCard.TabPanel
              isActive={ (activeTab[0] === 'competitors') }
            >
              <CompaniesList
                fill={true}
              > 
                {competitorsIsLoading && <SearchResultsSkeleton/>}
                {!competitorsIsLoading && competitors && competitors.competitors
                  .filter(b => b.sharedProjects)
                  .map((competitor: CompetitorDto, i) =>
                    <CompanyOverviewCard 
                      key={competitor.company.uuid} 
                      company={competitor.company} 
                      originalCompanyName={companyPageData?.company.name} 
                      sharedProjects={competitor.sharedProjects}
                      companyType={"company"}
                    />
                )}
              </CompaniesList>
              <Paginate
                currentPage={competitorsPage}
                totalHits={competitors?.totalHits ?? 0}
                itemsPerPage={PAGE_SIZE_COMPANIES}
                setPage={setCompetitorsPage}
              />
            </CompanyCard.TabPanel>
          </>

        </div>
      </CompanyCard.TabsContainer>
    </CompanyCard> }
  </div>);
};

function tenderSearchInput(tenderUuids: string[], page: number, pageSize: number): TenderSearchInput {
  return {
    metadata: {
      searchType: SearchType.SEARCH,
      searchPhase: SearchPhase.SEARCH,
      page: page,
      pageSize: pageSize,
      sortBy: 'PUBLICATION_DATE_DESC',
      tenderUuidFilters: tenderUuids,
    },
    mainInput: {
      name: "uuid_search",
    }, filterInputs: []
  }
}

const SearchResultsSkeleton = () => {
  return <>
    <TenderCardSkeleton/>
    <TenderCardSkeleton/>
    <TenderCardSkeleton/>
    <TenderCardSkeleton/>
  </>
}