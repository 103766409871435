import React from 'react';
import styles from './index.module.scss';


export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    variant?: 'follow';
}
export const Button: React.FC<ButtonProps> = ({ 
    children, 
    variant=null,
    ...props
 }) => {
    return (<button
        className={`
            ${styles['button']}
            ${variant ? styles[variant] : null}
        `}
        {...props}
    >
        { children } 
    </button>);
};
