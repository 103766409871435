import React, {useEffect, useRef, useState} from "react";
import "./searchSidebar.scss";
import {
    DateFilterType,
    resetAggregations,
    SearchResponse,
    TenderSearchInput,
    TenderSearchState,
    updateAggregations,
    updateHighlights,
    updateResponseLabels,
    updateSearchFilters,
    updateSearchInput,
    updateSearchResponse,
    useGetTenderSearchMutation
} from "../../../hooks/slices/tenderSearchSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {Country} from "../../../consts/countries";
import {useTranslation} from "react-i18next";
import {SearchFilter} from "../../../consts/SearchFilter";
import {SearchConfiguration} from "../GenericSearchPage";
import {CodeType} from "../../../types/code";
import "react-datepicker/dist/react-datepicker.css";
import {CloseIcon} from "../../../components/icons";
import {CodesAutocompleteDropdownWithDispatch} from "./inputs/CodesAutoCompleteDropdown";
import {useGetLabelsForSearchMutation} from "../../../hooks/slices/labelSlice";
import {SearchType} from "../../../consts/searchType";

import {Feature, FeatureName} from "../../../consts/features";
import {RaiseCurrentPlan} from "../../../hooks/raiseCurrentPlan";
import {showErrorPopup} from "../../../hooks/slices/snaccSlice";
import {AggregationUtil} from "../../../utils/aggregations";
import {SearchFilterToggle} from "./inputs/FilterToggle";
import {DateRangePicker} from "./inputs/DateRangePicker";
import {CheckBox} from "./inputs/CheckBox";
import {LabelsCheckBox} from "./inputs/LabelsCheckBox";
import {SearchFilterUtil} from "../../../utils/searchFilters";
import {FeatureUtil} from "../../../utils/features";
import {SearchUtil} from "../../../utils/search";
import {RaiseSavedSearches, SavedSearchesInStore} from "../../../hooks/raiseSavedSearches";
import {RaiseUser} from "../../../hooks/raiseUser";
import {SearchPhase} from "../../../consts/searchPhase";
import {SavedSearchUtil} from "../../../utils/savedsearches";
import {QueryInput} from "../../../components/queryInput";
import {Checkbox} from "../../../components/checkbox";

export const SearchSidebar: React.FC<SearchConfiguration> = (props) => {
    const {t, i18n} = useTranslation();
    const dispatch = useAppDispatch();
    const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
    const savedSearchesInStore: SavedSearchesInStore = RaiseSavedSearches();
    let savedSearchNames = SavedSearchUtil.getSavedSearchNames(savedSearchesInStore, t, i18n.language);
    let userInteractions = RaiseUser().user?.userInteractions ?? [];
    // const userCanAddMoreProfiles = () => FeatureUtil.userHasExtraSavedSearchAvailable(savedSearchesInStore.tenderSearches, features);
    const tenderSearchInStore: TenderSearchState = useAppSelector((state) => state.tenderSearch);
    const [showMobileSideBar, setShowMobileSidebar] = useState(false);
    //Every search triggers three api calls: the actual search, the labels (only the ones that apply to the page returned) and the aggregations
    const [getTenderSearch, {data: searchResults, isSuccess: searchIsSuccess, isLoading: searchIsLoading, error: searchError}] = useGetTenderSearchMutation();
    const [getHighlights, {data: highlights, error: highlightError}] = useGetTenderSearchMutation();
    const [getLabels, {data: labels, error: labelError}] = useGetLabelsForSearchMutation();
    const [getAggregations, {data: aggregations, error: aggError}] = useGetTenderSearchMutation();

    //When a user has a limited amount of opportunities per update mail, they cannot use the filters
    let opportunitiesLimit : number | undefined;
    if(tenderSearchInStore.searchType === SearchType.OPPORTUNITIES) {
        opportunitiesLimit = FeatureUtil.getFeatureLimit(features, FeatureName.UPDATE_MAIL_OPPORTUNITIES);
    }
    //An error popup is show if something goes wrong with these api calls
    useEffect(() => {
        if(searchError) dispatch(showErrorPopup(searchError));
        if(aggError) dispatch(showErrorPopup(aggError));
        if(labelError) dispatch(showErrorPopup(labelError));
        if(highlightError) dispatch(showErrorPopup(highlightError));
    }, [searchError, aggError, labelError]);
    //The actual search methods
    const searchTenders = () => {
        if (savedSearchesInStore !== undefined){
            let searchParameters: TenderSearchInput = SearchUtil.getSearchParameters(
                tenderSearchInStore,
                false,
                props,
                getAvailableCountriesFromStore(),
                savedSearchesInStore.savedSearches, SearchPhase.SEARCH, t, i18n.language);
            if (props.sidebar.queryType !== SearchType.DUMMY && !inputAlreadyInStore(searchParameters)) {
                dispatch(updateSearchInput(searchParameters));//dispatches the parameters to the store
                getTenderSearch(searchParameters);//this performs the actual search
            }
        }
    };
    const searchHighlights = (tenderSearchResults: SearchResponse) => {
        // if (tenderSearchInStore.searchInput?.metadata.addMainQueryHighlights || tenderSearchInStore.searchInput?.metadata.addFilterQueryHighlights){
            //note that the highlight search always searched page 0 (c.f. page: searchPhase === SearchPhase.HIGHLIGHTS ? 0 : searchFilterState.page)
            let tenderUuids = tenderSearchResults.tenders.map(t => t.tender.uuid);
            let highlightsInput = SearchUtil.getSearchParameters(
                tenderSearchInStore,
                true,
                props,
                getAvailableCountriesFromStore(),
                savedSearchesInStore.savedSearches, SearchPhase.HIGHLIGHTS, t, i18n.language);
            highlightsInput.metadata.fieldsToFetch = ["uuid"];
            highlightsInput.metadata.tenderUuidFilters = tenderUuids;
            getHighlights(highlightsInput);
        // }
    }
    const searchLabelsForPage = (tenderSearchResults: SearchResponse) => {
        let tenderUuids = tenderSearchResults.tenders.map(t => t.tender.uuid);
        getLabels({tenderUuids: tenderUuids});
    }
    const searchAggregations = (tenderSearchResults: SearchResponse) => {
        if (tenderSearchInStore.searchFilters.page === 0 && tenderSearchResults.totalHits < 10000) {
            //fetch aggregations
            let aggregationsInput = SearchUtil.getSearchParameters(
                tenderSearchInStore,
                true,
                props,
                getAvailableCountriesFromStore(),
                savedSearchesInStore.savedSearches, SearchPhase.AGGREGATIONS, t, i18n.language);
            aggregationsInput.metadata.fieldsToFetch = ["uuid"];
            getAggregations(aggregationsInput);
        } else if (tenderSearchResults.totalHits >= 10000) {
            dispatch(resetAggregations());
        }
    }
    //this runs the initial query, it only gets called once (the array never changes)
    //if there is a previous query of the same type in the store, that gets used
    useEffect(() => {
        let previousQueryAvailable = !(tenderSearchInStore.searchResponse === undefined || tenderSearchInStore.searchInput === undefined || tenderSearchInStore.searchType === undefined || tenderSearchInStore.searchFilters === undefined)
        if (!previousQueryAvailable || tenderSearchInStore.searchType !== props.sidebar.queryType) {
            searchTenders();
        }
    }, []);
    //This triggers whenever 'tenderSearchResults' is updated and
    //  (a) puts the new query and results in the redux store
    //  (b) fetches the relevant labels for the new page
    //  (c) fetches the aggregations (if less than 10k results)
    useEffect(() => {
        if (searchIsSuccess && searchResults !== undefined) {
            dispatch(updateSearchResponse(searchResults));
            searchHighlights(searchResults); //second search
            searchLabelsForPage(searchResults); //third search
            searchAggregations(searchResults); //fourth search
        }
    }, [searchIsSuccess, searchResults]);
    //re-runs the search if the search filters are changed (first search)
    //initialRender because these are called on start and we don't want that
    const initialRender = useRef(true);
    useEffect(() => {
        if (initialRender.current) initialRender.current = false;
        else if(tenderSearchInStore.searchFilters) {
            searchTenders();
        }
    }, [tenderSearchInStore.searchFilters]);
    //updates the highlights (second search)
    useEffect(() => {
        if (highlights != null) {
            dispatch(updateHighlights(highlights.tenders));
        }
    }, [highlights]);
    //updates the labels (third search)
    useEffect(() => {
        if (labels != null) {
            dispatch(updateResponseLabels(labels.labelsAndTenders));
        }
    }, [labels]);
    //updates the aggregations (fourth search)
    useEffect(() => {
        if (aggregations != null) {
            dispatch(updateAggregations(aggregations.aggregations));
        }
    }, [aggregations]);
    //Other utilitiy methods
    const inputAlreadyInStore = (input: TenderSearchInput): boolean => {
        if (tenderSearchInStore.searchInput === undefined || tenderSearchInStore.searchResponse === undefined) return false;
        return JSON.stringify(input) === JSON.stringify(tenderSearchInStore.searchInput);
    }
    const getAvailableCountriesFromStore = (): Country[] => {
        if (features === undefined) return [];
        else return FeatureUtil.getCountriesFromPublisherFeatures(features);
    }
    const getAggregation = (searchFilter: SearchFilter): string => {
        return AggregationUtil.getAggregationBracketedString(searchFilter, tenderSearchInStore.aggregations);
    }
    const getLabelAggregation = (labelName: string) => {
        return AggregationUtil.getLabelAggregationBracketedString(labelName, tenderSearchInStore.aggregations);
    }
    return (
        <>
            <div className='filter-button-hidden'>
                <div className='filter-button-style'>
                    <div className='fill-button'>
                        <button onClick={() => setShowMobileSidebar(!showMobileSideBar)}>Filter</button>
                    </div>
                </div>
            </div>
            <div className={showMobileSideBar ? 'filter-sidebar sidebar-sticky' : 'sidebar-sticky sidebar-tab-h'}>
                <div className={tenderSearchInStore.searchType === SearchType.OPPORTUNITIES && opportunitiesLimit ? 'sidebar-design disabled' : 'sidebar-design'}>
                    <div className={'filter-title'}>
                        <span>Filters</span>
                        {showMobileSideBar && <span onClick={() => setShowMobileSidebar(!showMobileSideBar)}>
                                <CloseIcon/>
                            </span>}
                    </div>
                    <div className={'top-search'}>
                        <QueryInput query={tenderSearchInStore.searchFilters.query}
                                    queryPlaceholder={props.sidebar.queryPlaceHolder}
                                    isLoading={searchIsLoading}
                                    updateQueryAndSearch={(q) => dispatch(updateSearchFilters({query: q, userInteractions: userInteractions}))}/>
                    </div>
                    {/*This checkbox only has one option, search addenda, hence the label, and is frozen for basic users*/}
                    <SearchFilterToggle label={t("publications.searchAddenda")}
                                        relevantFilters={[SearchFilter.FIELD_GROUP_ADDENDA]}
                                        frozen={!FeatureUtil.hasFeature(features, FeatureName.SEARCH_ADDENDA)}
                                        addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}
                    />
                    {props.sidebar.showSearchSectorFilter &&
                        <Checkbox label={t("publications.toggleSearchSector")}
                                             selected={tenderSearchInStore.searchFilters.savedSearches != null && tenderSearchInStore.searchFilters.savedSearches.length > 0}
                                             onChange={() => dispatch(updateSearchFilters({
                                                 savedSearches: (tenderSearchInStore.searchFilters.savedSearches != null && tenderSearchInStore.searchFilters.savedSearches.length > 0) ? [] : savedSearchNames,
                                                 userInteractions: userInteractions}))}
                        />
                    }
                    {props.sidebar.queryType == SearchType.AWARDS &&
                    <SearchFilterToggle label={t("publications.searchAwardCompanyNames")}
                                  relevantFilters={[SearchFilter.FIELD_GROUP_AWARD_COMPANY_NAMES]}
                                  frozen={false}
                                  addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}
                    />}
                    {props.sidebar.queryType == SearchType.AWARDS &&
                        <SearchFilterToggle label={t("publications.searchBidCompanyNames")}
                        relevantFilters={[SearchFilter.FIELD_GROUP_BID_COMPANY_NAMES]}
                        frozen={false}
                        addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}
                        />}
                    <CheckBox label={t("detailsSideBar.languages")} relevantFilters={FeatureUtil.getRelevantLanguageFiltersFromFeatures(features)}
                              searchSidebarConfiguration={props.sidebar}
                              getAggregation={getAggregation} addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}/>
                    {props.sidebar.showPublicationDateFilters && <DateRangePicker type={DateFilterType.PUBLICATION_DATE} searchType={props.sidebar.queryType}/>}
                    {props.sidebar.showDeadlineDateFilters &&<DateRangePicker type={DateFilterType.DEADLINE} searchType={props.sidebar.queryType}/>}
                    {props.sidebar.showAwardOrOpeningReportDateFilters &&<DateRangePicker type={DateFilterType.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE} searchType={props.sidebar.queryType}/>}
                    {props.sidebar.showEstimatedRenewalDateFilters &&<DateRangePicker type={DateFilterType.ESTIMATED_RENEWAL_PUBLICATION_DATE} searchType={props.sidebar.queryType}/>}
                    <CheckBox label={t("publications.countries")}
                              relevantFilters={SearchFilterUtil.getCountryFiltersFromAvailableCountries(getAvailableCountriesFromStore())}
                              searchSidebarConfiguration={props.sidebar}
                              getAggregation={getAggregation} addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}/>
                    <CheckBox label={t("publications.publicationType")} relevantFilters={SearchFilterUtil.getPublicationTypeFilters()}
                              searchSidebarConfiguration={props.sidebar}
                              getAggregation={getAggregation} addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}/>
                    <CheckBox label={t("publications.contractTypeTitle")} relevantFilters={SearchFilterUtil.getContractTypeFilters()}
                              searchSidebarConfiguration={props.sidebar}
                              getAggregation={getAggregation} addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}/>
                    <CheckBox label={t("publications.contractingAuthorityTitle")}
                              relevantFilters={SearchFilterUtil.getContractingAuthorityTypeFilters()}
                              searchSidebarConfiguration={props.sidebar}
                              getAggregation={getAggregation} addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}/>
                    <CheckBox label={t("publications.publicationRadiusTitle")}
                              relevantFilters={SearchFilterUtil.getPublicationRadiusFilters()}
                              getAggregation={getAggregation} addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}
                              searchSidebarConfiguration={props.sidebar}
                              optionsAreExclusive={true}/>
                    {/*<AutocompleteDropdown label={"Region codes"} relevantFilters={getPublicationRadiusFilters()} searchFilters={searchFilters} updateSearchFilters={updateSearchFilters} getAggregation={getAggregation}/>*/}
                    <CheckBox label={t("publications.procedureTypeTitle")} relevantFilters={SearchFilterUtil.getProcedureTypeFilters()}
                              searchSidebarConfiguration={props.sidebar}
                              getAggregation={getAggregation} addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}/>
                    <CheckBox label={t("publications.shelteredTitle")}
                              relevantFilters={[SearchFilter.IS_SHELTERED_WORKSHOP, SearchFilter.IS_SHELTERED_PROGRAM]}
                              searchSidebarConfiguration={props.sidebar}
                              getAggregation={getAggregation} addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}/>
                    <CheckBox label={t("publications.frameworkTitle")}
                              relevantFilters={[SearchFilter.IS_FRAMEWORK_AGREEMENT_YES, SearchFilter.IS_FRAMEWORK_AGREEMENT_NO]}
                              searchSidebarConfiguration={props.sidebar} optionsAreExclusive={true}
                              getAggregation={getAggregation} addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}/>
                    <CodesAutocompleteDropdownWithDispatch codeType={CodeType.CPV} />
                    <CodesAutocompleteDropdownWithDispatch codeType={CodeType.NUTS} />
                    {props.sidebar.showAccreditations && <CodesAutocompleteDropdownWithDispatch codeType={CodeType.ACCREDITATION} />}
                    <CheckBox label={t("publications.addenda")} relevantFilters={SearchFilterUtil.getAddendaFilters()}
                              searchSidebarConfiguration={props.sidebar} addOrRemoveSearchFilter={SearchFilterUtil.addOrRemoveSearchFilter}
                              getAggregation={getAggregation} optionsAreExclusive={true}/>
                    {props.sidebar.showLabels && FeatureUtil.hasFeature(features, FeatureName.LABELS) &&
                        <LabelsCheckBox selectedLabels={tenderSearchInStore.searchFilters.labelFilters ? tenderSearchInStore.searchFilters.labelFilters : []}
                                        getLabelAggregation={getLabelAggregation}/>}
                    {/*{userCanAddMoreProfiles() && <AddSavedSearchButton query={tenderSearchInStore.searchFilters.query}/>}*/}
                </div>
            </div>
        </>
    )
}


