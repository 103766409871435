import React from 'react';
import styles from './index.module.scss';

import { TabPanel } from '../';
import {CompanyPageDto} from "../../../../hooks/slices/companySlice";
import {StringUtil} from "../../../../utils/strings";
import {
    CompanyDetailsContentItem,
    getCompanyAccreditations,
    getCompanyActivities,
    getCompanyDescriptionRows
} from "../../../../pages/company";
import {CompanyUtil} from "../../../../utils/company";
import {useTranslation} from "react-i18next";
import {IconWithTextAndCopyButton} from "../../../iconWithTextAndCopyButton";
import {EmailIcon, PhoneIcon, WebsiteIcon} from "../../../icons";
import {IconWithLink} from "../../../iconWithLink";


export interface TabPanelInfoProps extends React.HTMLAttributes<HTMLDivElement> {
    isActive?: boolean;
    companyPage: CompanyPageDto
}
export const TabPanelInfo: React.FC<TabPanelInfoProps> = ({ 
    children, 
    isActive=false,
    companyPage,
    ...props 
}) => {
    let {t} = useTranslation();
    const combinedActivities: string[] = getCompanyActivities(companyPage.company)
        .map(activity => activity.value)
        .filter(StringUtil.distinct)
        .filter(activity => activity.trim() !== '')
    ;
    let accreditations: CompanyDetailsContentItem[] = getCompanyAccreditations(companyPage.company);
    let contactInformationAvailable: boolean = companyPage.company.website != null || companyPage.company.phone != null || companyPage.company.email != null;
    let address: string | undefined = CompanyUtil.getCompanyAddress(companyPage.company);
    if (!companyPage) return <></>
    return (<TabPanel 
        isActive={isActive}
        {...props}
    >
        <div className={styles["container-info"]}>
            <div className={styles["info-card"]}>
                <h1>{t("companyPage.company_info_details_title")}</h1>
                <p>{companyPage.company.name}</p>
                <p>{address}</p>
                {companyPage.company.startDate && <p>{t("companyPage.company_info_start_date_title") + ": " +companyPage.company.startDate}</p>}
            </div>
            {contactInformationAvailable && <div className={styles["info-card"]}>
                <h1>{t("companyPage.company_info_contact_title")}</h1>
                {companyPage.company.phone && <IconWithTextAndCopyButton icon={<PhoneIcon/>} text={companyPage.company.phone}/>}
                {companyPage.company.email && <IconWithTextAndCopyButton icon={<EmailIcon/>} text={companyPage.company.email}/>}
                {companyPage.company.website && <IconWithLink icon={<WebsiteIcon/>} text={companyPage.company.website}/>}
            </div>}
            {combinedActivities.length > 0 && <div className={styles["info-card"]}>
                <h1>{t("companyPage.company_info_activities_title")}</h1>
                {combinedActivities.map(activity => <p>{activity}</p>)}
            </div>}

            {accreditations.length > 0 && <div className={styles["info-card"]}>
                <h1>{t("companyPage.company_info_accreditations_title")}</h1>
                {accreditations.map(activity => <p>{activity.value}</p>)}
            </div>}
        </div>
    </TabPanel>);
}
