import React, {useEffect} from "react";
import {CloseIcon} from "../../../../components/icons";
import {useTranslation} from "react-i18next";
import {RaiseUser, UserLanguage} from "../../../../hooks/raiseUser";
import {CompanyPageInteraction, UserInteraction, UserState} from "../../../../hooks/slices/userSlice";
import {useAppDispatch} from "../../../../app/hooks";
import {updateSearchFilters} from "../../../../hooks/slices/tenderSearchSlice";
import {FADE_IN} from "../../../../consts/FramerMotionVariants";
import {motion} from "framer-motion";
import {
    CompanySuggestion,
    CompanySuggestionsSource,
    useGetCompaniesByVatNumberInQuery
} from "../../../../hooks/slices/companySlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {Language} from "../../../../consts/languages";
import {MultilingualFieldUtil} from "../../../../utils/multilingualfield";



export const CompanyFilterButtons: React.FC< {vatNumberFilters: string[]}> = ({
                                                                   vatNumberFilters
                                                               }) => {
    const dispatch = useAppDispatch();
    let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
    // let companyFilters: CompanyPageInteraction[] = useFindCompanyNames(vatNumberFilters);
    let companyFilters: CompanyPageInteraction[] = useFindCompanyFilters(vatNumberFilters);
    // const followedCompaniesSelected: CompanyPageInteraction[] = followedCompanies.filter(c => vatNumberFilters.includes(c.companyPageVatNumber));
    const removeCompanyFilter = (company: CompanyPageInteraction) => {
        dispatch(updateSearchFilters({
            participantVatNumbers: vatNumberFilters.filter(v => v !== company.companyPageVatNumber),
            contractingAuthorityVatNumbers: vatNumberFilters.filter(v => v !== company.companyPageVatNumber),
            userInteractions:userInteractions}));
    }
    if(vatNumberFilters == null || companyFilters.length === 0) return <></>
    return <>
        {companyFilters.map((c, i) => <CompanyFilterButton key={i} company={c} removeCompanyFilter={removeCompanyFilter}/>)}
    </>
}

const CompanyFilterButton: React.FC<{company: CompanyPageInteraction, removeCompanyFilter: (company: CompanyPageInteraction) => void}> = ({company, removeCompanyFilter}) => {
    const {t} = useTranslation();
    return <>
        <motion.button variants={FADE_IN}>
            <span>{company.companyPageName}</span>
            <span onClick={() => removeCompanyFilter(company)} style={{cursor:"pointer"}}><CloseIcon/></span>
        </motion.button>
    </>
}

const useFindCompanyFilters = (vatNumbers: string[]) : CompanyPageInteraction[]=> {
    return useFindOtherCompanyFilters(vatNumbers);
}

const useFindFollowedCompanyFilters = (vatNumbers: string[]) : CompanyPageInteraction[]=> {
    let user: UserState | undefined = RaiseUser().user;
    const followedCompanies: CompanyPageInteraction[] = user?.companyPageInteractions ?? [];
    return followedCompanies.filter(c => vatNumbers.includes(c.companyPageVatNumber));
}

const useFindOtherCompanyFilters = (vatNumbers: string[]) : CompanyPageInteraction[]=> {
    const [companyFilters, setCompanyFilters] = React.useState<CompanyPageInteraction[]>([]);
    const {i18n} = useTranslation();
    const language: Language = UserLanguage(i18n.language);
    let companiesQuery = vatNumbers.length > 0 ?  {vatNumbers: vatNumbers, source: CompanySuggestionsSource.COMPANY_PAGE_ANY} : undefined;
    const {data, isLoading: fetchingCompanies, isSuccess, error} = useGetCompaniesByVatNumberInQuery(companiesQuery ?? skipToken);
    useEffect(() => {
        if(isSuccess && data){
            let suggestions: CompanySuggestion[] = data.suggestions;
            let newCompanies: CompanyPageInteraction[] = suggestions.map(c => ({
                companyPageName: MultilingualFieldUtil.translate(c.name, language),
                companyPageVatNumber: c.vatNumber,
                companyPageUuid: c.uuid}));
            setCompanyFilters(newCompanies);
        }
    }, [data, isSuccess]);
    return companyFilters;
}