import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";
import { ProgressCircle } from "../../../progressCircle";
import { RecentTenderDto } from "../../../../hooks/slices/companySlice";

export interface CompanyWinRateProgressCircleProps {
  tendersWon: RecentTenderDto[];
  tendersLost: RecentTenderDto[];
}
export const CompanyWinRateProgressCircle: React.FC<CompanyWinRateProgressCircleProps> = ({
    tendersWon=[],
    tendersLost=[],
}) => {
    const {t} = useTranslation();
    if (tendersWon.length === 0 
        && tendersLost.length === 0
    ) return <> </>;
    const progressPercentage = getProgressBarPercentage(tendersWon, tendersLost);

    return (<div className={styles['container']}>
        <p className={styles['top-text']}>
            {t("companyPage.win_ratio_title_1")}
        </p>
        <ProgressCircle 
            progressPercentage={Math.round(progressPercentage)}
            size={100}
            stroke={12}
            text={`
                ${Math.round(progressPercentage)} % 
            `}
            color={"var(--money-green)"}
        />

        <p className={styles['bottom-text']}>
            {t("companyPage.win_ratio_title_2")}
        </p>
    </div>);
}

const getProgressBarPercentage = (
    tenderWon: RecentTenderDto[], 
    tenderLost: RecentTenderDto[],
): number => {
    let amountOfTendersWon = tenderWon.length;
    let amountOfTendersLost = tenderLost.length;
    let totalTenders = amountOfTendersWon + amountOfTendersLost;
    let percentage = amountOfTendersWon / totalTenders * 100;
    return percentage;
}