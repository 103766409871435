import {TenderSearchHighlightedAddendum, TenderSearchHit} from "../../../../hooks/slices/tenderSearchSlice";
import {LabelTenderDto} from "../../../../hooks/slices/labelSlice";
import {LabelCategory} from "../../../../types/label";
import React, {useEffect, useState} from "react";
import {useAppDispatch, useAppSelector} from "../../../../app/hooks";
import {
  addUserInteraction,
  removeUserInteraction,
  useAddUserInteractionMutation,
  useRemoveUserInteractionMutation,
  UserInteraction,
  UserInteractionInput
} from "../../../../hooks/slices/userSlice";
import {showErrorPopup} from "../../../../hooks/slices/snaccSlice";
import {UserInteractionType} from "../../../../consts/UserInteractionType";
import {getCodeTranslations, getEstimatedTotalValue} from "../../../tenderDetails/main/TenderDetailsPage";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {FlagsForTender} from "../../../../components/flags/flags";
import {DateUtil} from "../../../../utils/date";
import {ProgressBar} from "./ProgressBar";
import {Labels} from "./Labels";
import {S3FileDocument, Tender} from "../../../../types/tender";
import {
  CraneBlueIcon,
  DeadlineBlueIcon,
  EuroCircledBlueIcon,
  PublicationDateBlueIcon,
  RegionCodeBlueIcon, SparklesBlueIcon, SparklesIcon, SparklesOrangeIcon,
  StarBlueFillIcon,
  StarBlueIcon,
  SubjectCodeBlueIcon,
  TrashBlueFillIcon,
  TrashBlueIcon
} from "../../../../components/icons";
import {TFunction} from "i18next";
import {Feature, FeatureName} from "../../../../consts/features";
import {RaiseCurrentPlan} from "../../../../hooks/raiseCurrentPlan";
import {FeatureUtil} from "../../../../utils/features";
import {usePostDownloadFileMutation} from "../../../../hooks/slices/tenderDetailsSlice";
import {Loader} from "../../../../components/loader";
import {CompanyLink, CompanyLinks, CompanyNameWithVat} from "../../../company/CompanyLink";
import {CompanyUtil} from "../../../../utils/company";
import {MultilingualFieldUtil} from "../../../../utils/multilingualfield";
import {RaiseSavedSearches, SavedSearchesInStore} from "../../../../hooks/raiseSavedSearches";
import {SavedSearchUtil} from "../../../../utils/savedsearches";
import {StringUtil} from "../../../../utils/strings";
import {useGetSummaryMutation} from "../../../../hooks/slices/aiSlice";
import {S3FileUtil} from "../../../../utils/s3Files";
import {AISummary} from "../../../../components/aiSummary";

interface TenderCardProps {
  tenderSearchHit: TenderSearchHit;
  languageIso: string;
  configuration: TenderCardConfiguration;
  isLead: boolean;
  // isFollowedTender: boolean;
  isDeletedOpportunity: boolean;
  getLabels: (tenderUuid: string) => LabelTenderDto[];
  getLabelCategories: () => LabelCategory[];
}

export interface TenderCardConfiguration {
  showProgress?: boolean;
  showEstimatedRenewalDate?: boolean;
  showDescription?: boolean;
  showCompanies?: boolean;
  showCueHighlights?: boolean;
  showQueryHighlights?: boolean;
  showAddendumHighlights?: boolean;
  showLatestPublicationTypeInUpperRightCorner?: boolean;
  showScoreInUpperRightCorner?: boolean;
  showPublicationDetails?: boolean;
  showTags?: boolean;
  showLeadsIcon?: boolean;
  showFollowIcon?: boolean;
  showDeleteOpportunityIcon?: boolean;
  showAwardDateInsteadOfDeadline?: boolean;
  showProfiles?: boolean;
  showTenderAISummaryButton?: boolean;
}

export const TenderCard: React.FC<TenderCardProps> = (props) => {
  //LOGIC FOR UPDATING INTERACTIONS
  const dispatch = useAppDispatch();
  const userUuid = useAppSelector((state) => state.user.uuid);
  const [addUserInteractionToDb, {
    isError: addUserInteractionsError,
    error: addUserInteractionsErrorObject
  }] = useAddUserInteractionMutation();
  const [removeUserInteractionFromDb, {
    isError: removeUserInteractionsError,
    error: removeUserInteractionsErrorObject
  }] = useRemoveUserInteractionMutation();
  useEffect(() => {
    if (addUserInteractionsError && addUserInteractionsErrorObject) {
      dispatch(showErrorPopup(addUserInteractionsErrorObject));
    } else if (removeUserInteractionsError && removeUserInteractionsErrorObject) {
      dispatch(showErrorPopup(removeUserInteractionsErrorObject));
    }
  }, [addUserInteractionsError, addUserInteractionsErrorObject, removeUserInteractionsError, removeUserInteractionsErrorObject]);
  const updateUserInteraction = (tenderUuid: string, isSelected: boolean, interactionType: UserInteractionType) => {
    if (userUuid) {
      let newInteraction: UserInteraction = { uuid: tenderUuid, interactionType: interactionType };
      let interactionInput: UserInteractionInput = { userUuid: userUuid, interaction: newInteraction };
      if (!isSelected) {
        addUserInteractionToDb(interactionInput); //update DB
        dispatch(addUserInteraction(interactionInput.interaction)); //update search state
      }
      if (isSelected) {
        removeUserInteractionFromDb(interactionInput); //update DB
        dispatch(removeUserInteraction(interactionInput.interaction)); //update search state
      }
    }

  };
  //OTHER STUFF
  const titleString = MultilingualFieldUtil.translate(props.tenderSearchHit.tender.title, props.languageIso);
  const descriptionString = MultilingualFieldUtil.translate(props.tenderSearchHit.tender.description, props.languageIso);
  let navigate = useNavigate();
  const navigateToDetailsPage = () => {
    let path = "/tender/" + props.tenderSearchHit.tender.uuid;
    navigate(path);
  };
  function cardClassName() {
    return "card blue-border";
    // return isAward ? "card red-border" : "card blue-border";
  }

  return <>
    <div className={cardClassName()} data-cy={"tender-card"}>
      {props.configuration.showDeleteOpportunityIcon && props.isDeletedOpportunity &&
        <TenderCardOverlay tenderSearchHit={props.tenderSearchHit}
                           updateUserInteraction={updateUserInteraction} />}
      <TenderCardHeader tenderSearchHit={props.tenderSearchHit}
                        languageIso={props.languageIso}
                        configuration={props.configuration}
                        navigateToDetailsPage={navigateToDetailsPage} />
      {props.configuration.showProgress &&
        <TenderCardProgressBar tenderSearchHit={props.tenderSearchHit}
                               configuration={props.configuration}
                               navigateToDetailsPage={navigateToDetailsPage}
                               title={titleString}
                               description={descriptionString} />}

      <TenderCardBody tenderSearchHit={props.tenderSearchHit}
                      languageIso={props.languageIso}
                      configuration={props.configuration}
                      isLead={props.isLead}
                      isDeletedOpportunity={props.isDeletedOpportunity}
                      getLabels={props.getLabels}
                      getLabelCategories={props.getLabelCategories}
                      updateUserInteraction={updateUserInteraction}
                      navigateToDetailsPage={navigateToDetailsPage}
                      title={titleString}
                      description={descriptionString}
      />

    </div>
  </>;
};

function getContractAwardCompanies(tender: Tender, languageIso: string): CompanyNameWithVat[] {
  if (tender.contractAwardNotices == null) return [];
  let names: CompanyNameWithVat[] = [];
  for (const notice of tender.contractAwardNotices) {
    if (notice == null) continue;
    for (const award of notice.contractAwards) {
      if (award.winningCompanies != null) {
        for (const company of award.winningCompanies) {
          let companyTranslation = MultilingualFieldUtil.translate(company.name, languageIso);
          if (companyTranslation != null && !names.some(n => n.name === companyTranslation)) {
            names.push({ name: companyTranslation, vat: company.vatNumber != null ? company.vatNumber : company.nationalIdentifier, showFollowButton:true, government: false });
          }
        }
      }
    }
  }
  return names;
}

function getOpeningReportCompanies(tender: Tender, languageIso: string): CompanyNameWithVat[] {
  if (tender.openingReports == null) return [];
  let names: CompanyNameWithVat[] = [];
  for (const report of tender.openingReports) {
    if (report.bids == null) continue;
    for (const bid of report.bids) {
      let companyTranslation = MultilingualFieldUtil.translate(bid.company.name, languageIso);
      if (companyTranslation != null && !names.some(n => n.name === companyTranslation)) {
        names.push({ name: companyTranslation, vat: bid.company.vatNumber != null ? bid.company.vatNumber : bid.company.nationalIdentifier, showFollowButton:true, government: false });
      }
    }
  }
  return names;
}

interface TenderCardOverlayProps {
  tenderSearchHit: TenderSearchHit;
  updateUserInteraction: (tenderUuid: string, isSelected: boolean, interactionType: UserInteractionType) => void;
}

const TenderCardOverlay: React.FC<TenderCardOverlayProps> = ({ tenderSearchHit, updateUserInteraction }) => {
  const { t } = useTranslation();
  return <>
    <div className="overlay">
      <p>{t("publications.opportunityRemoved")}</p>
      <p className="undo"
         onClick={() => updateUserInteraction(tenderSearchHit.tender.uuid, true, UserInteractionType.DELETE_OPPORTUNITY)}>
        {t("publications.undo")}</p>
    </div>
  </>;
};

interface TenderCardHeaderProps {
  tenderSearchHit: TenderSearchHit;
  languageIso: string;
  configuration: TenderCardConfiguration;
  navigateToDetailsPage: () => void;
}

const TenderCardHeader: React.FC<TenderCardHeaderProps> = (props) => {

  const {t} = useTranslation();
  const contractingAuthorityString =
    props.tenderSearchHit.tender.contractingAuthority != null
      ? MultilingualFieldUtil.translate(props.tenderSearchHit.tender.contractingAuthority.officialName, props.languageIso)
      : "";
  const latestPublicationType
    = props.tenderSearchHit.tender.municipalDecisions != null && props.tenderSearchHit.tender.municipalDecisions.length > 0
         ? t("publications.municipalDecision").toUpperCase()
         : "";
  let contractingAuthorityVatNumber = CompanyUtil.findKboVatNumber(props.tenderSearchHit.tender.contractingAuthority?.vatNumber);
  let ca: CompanyNameWithVat = {name: contractingAuthorityString, vat: contractingAuthorityVatNumber, showFollowButton: false, government: true};
  return (
    <>
      <div className="box-title-alignment">
        <div>
          <div className="flag-section">
            {props.tenderSearchHit.tender && <FlagsForTender tender={props.tenderSearchHit.tender} />}

            {/*{FlagsForTender(tenderSearchHit.tender).map((flag, i) => (*/}
            {/*    <span key={i}>{flag}</span>*/}
            {/*))}*/}
          </div>
          {/*href: "/company/" + c.relation.uuid*/}
          {/*{c.href ? <a className={"hover-highlight"} href={addProtocolToUrl(c.href)}>{c.value}</a> : c.value}*/}
          <div className={'contracting-authority-name-section'} title={t("settings.navigateToCompanyPage")}>
            {contractingAuthorityVatNumber
                ? <CompanyLink c={ca}/>
                : contractingAuthorityString}
          </div>
        </div>
        <div>
          {/*{configuration.showPublicationDateInUpperRightCorner && <>*/}
          {/*    <span>Selection reason: ...</span>*/}
          {/*    <a>AN ORDER ANNOUNCEMENT</a>*/}
          {/*</>}*/}
          {props.configuration.showLatestPublicationTypeInUpperRightCorner && (
            <span>{latestPublicationType}</span>
          )}
          {props.configuration.showScoreInUpperRightCorner &&
            <span>{(props.tenderSearchHit.score * 100).toFixed(2) + "%"}</span>}
        </div>
      </div>
    </>
  );
};

interface TenderCardProgressBarProps {
  configuration: TenderCardConfiguration;
  tenderSearchHit: TenderSearchHit;
  navigateToDetailsPage: () => void;
  title: string;
  description: string;
}

const TenderCardProgressBar: React.FC<TenderCardProgressBarProps> = (props) => {
  return <>
    <div className="box-body" onClick={props.navigateToDetailsPage}>
      <div className="title-text-padded">
        <span><a>{props.title}</a></span>
        {props.configuration.showDescription &&
          <p>
            <a>{props.description}</a>
          </p>
        }
      </div>
      <span onClick={props.navigateToDetailsPage}>
        <ProgressBar tenderSearchHit={props.tenderSearchHit}
                     showDeadline={!props.configuration.showEstimatedRenewalDate}
                     showEstimatedRenewalDate={props.configuration.showEstimatedRenewalDate === true}/></span>
    </div>

  </>;
};


interface TenderCardBodyProps extends TenderCardProps {
  navigateToDetailsPage: () => void;
  title: string;
  description: string;
  updateUserInteraction: (tenderUuid: string, isSelected: boolean, type: UserInteractionType) => void;
}

const TenderCardBody: React.FC<TenderCardBodyProps> = (props) => {
  const emptyOrNull = (array: any[]) => array == null || array.length === 0;
  const hasAwardOrOpeningReport = !emptyOrNull(props.tenderSearchHit.tender.contractAwardNotices) || !emptyOrNull(props.tenderSearchHit.tender.openingReports);
  const showCompanies = props.configuration.showCompanies && hasAwardOrOpeningReport;
  return <>
    <div className="box-body">
      <span>
        {!props.configuration.showProgress && <TenderCardTitleAndDescription {...props} />}
        {props.configuration.showPublicationDetails && <PublicationDetailsWithIcons {...props} />}
        {showCompanies && <TenderCardCompanies {...props} />}
        {props.configuration.showAddendumHighlights && <span><AddendumHighlights {...props} /></span>}
      </span>
      <TenderCardBodyFooter {...props} />
    </div>

  </>;
};

const TenderCardTitleAndDescription: React.FC<TenderCardBodyProps> = (props) => {
  return <>
    <div className="title-text-padded" onClick={props.navigateToDetailsPage}>
      <span data-cy={"tender-card-title"}>{props.title}</span>
      {props.configuration.showDescription &&
        <p data-cy={"tender-card-description"}>{StringUtil.trim(props.description, 1024)}</p>
      }
    </div>
  </>;
};

const TenderCardCompanies: React.FC<TenderCardBodyProps> = (props) => {
  const { t } = useTranslation();
  const winners: CompanyNameWithVat[] = getContractAwardCompanies(props.tenderSearchHit.tender, props.languageIso);
  const participants: CompanyNameWithVat[] = getOpeningReportCompanies(props.tenderSearchHit.tender, props.languageIso);
  return <>
    {winners.length === 0 && participants.length === 0 &&
      <div className="awards-bottom-text" onClick={props.navigateToDetailsPage}>
        <p>{t("publications.noAwardsOrDiscontinuationTitle")}</p><p></p>
      </div>}
    {participants.length > 0 &&
      <div className="awards-bottom-text">
        <p>{participants.length === 1 ? t("publications.participantTitle") : t("publications.participantsTitle")}</p>
        <CompanyLinks companies={participants} />
      </div>
    }
    {winners.length > 0 &&
      <div className="awards-bottom-text">
        <p>{winners.length === 1 ? t("publications.winnerTitle") : t("publications.winnersTitle")}</p>
        <CompanyLinks companies={winners} />
      </div>
    }
  </>;
};


const TenderCardBodyFooter: React.FC<TenderCardBodyProps> = (props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  const savedSearches: SavedSearchesInStore = RaiseSavedSearches();
  const allProfileNames: string[] = SavedSearchUtil.getSavedSearchNames(savedSearches, t, i18n.language);
  const userHasLabelFeature: boolean = FeatureUtil.hasFeature(features, FeatureName.LABELS);
  let showKeywords = props.configuration.showQueryHighlights || props.configuration.showCueHighlights;
  let interestingKeywords: string[] = props.tenderSearchHit.matchedTerms;
  let matchedQueries: string[] = props.tenderSearchHit.matchedQueries;
  let matchedProfiles = matchedQueries.filter((q) => allProfileNames.includes(q));
  let mostRelevantSpecification: S3FileDocument | undefined = S3FileUtil.findMostRelevantSpecification(props.tenderSearchHit.tender.addenda);
  const [
    postGetSummary,
    {
      isSuccess: summarySuccess,
      data: summaryResponse,
      isLoading: summarizing,
      isError: summaryIsError,
      error: summaryError,
    },
  ] = useGetSummaryMutation();
  const [fileSummary, setFileSummary] = useState<string | undefined>(undefined);
  const [showFileSummary, setShowFileSummary] = useState<boolean>(false);
  const getSummary = () => {
    if (mostRelevantSpecification?.fileName && fileSummary === undefined) {
      postGetSummary({ tenderUuid: props.tenderSearchHit.tender.uuid, fileName: mostRelevantSpecification.fileName, languageIso: props.languageIso });
    }
  };
  const toggleSummary = () => {
    if (fileSummary === undefined) getSummary();
    else setShowFileSummary(!showFileSummary);
  }
  useEffect(() => {
    if (summaryIsError && summaryError) dispatch(showErrorPopup(summaryError));
  }, [summaryIsError, summaryError]);
  useEffect(() => {
    if (summarySuccess && summaryResponse) {
      setFileSummary(summaryResponse.response);
      setShowFileSummary(true);
    }
  }, [summarySuccess, summaryResponse]);
  return (
    <>
      {summarizing && <Loader/>}
      <div className="box-footer-alignment">
        <div className={"box"}>
          <div className="keyword-style">
            {showKeywords && interestingKeywords.length > 0 && (
              <p>
                {t("publications.interestingKeywords") + ": "}
                <span>{interestingKeywords.join(", ")}</span>
              </p>
            )}
          </div>
        </div>
        <div className={"box"}></div>
      </div>
      {props.configuration.showProfiles && (
        <div className="box-footer-alignment">
          <div className={"box"}>
            <div className="keyword-style">
              <p>
                {t("settings.profiles") + ": "}
                <MatchedProfileButtons matchedProfiles={matchedProfiles} />
              </p>
            </div>
          </div>
          <div className={"box"}></div>
        </div>
      )}
      <div className="box-footer-alignment">
        <div className={"box"}>
          {props.configuration.showTags && userHasLabelFeature && (
            <div>
              <div className="label-button-alignment">
                <div className={"keyword-style"}>
                  <p>{t("settings.labels")}:</p>
                </div>
                <Labels
                  tenderUuid={props.tenderSearchHit.tender.uuid}
                  getLabels={props.getLabels}
                  getLabelCategories={props.getLabelCategories}
                />
              </div>
            </div>
          )}
        </div>
        <div className={"box"}>
          <div className="card-action">
            {props.configuration.showTenderAISummaryButton && mostRelevantSpecification?.fileName && (
              <SummarizeSpecificationButton
                toggleSummary={toggleSummary}
              />
            )}
            {props.configuration.showLeadsIcon && (
              <ToggleLeadButton
                tenderUuid={props.tenderSearchHit.tender.uuid}
                updateUserInteraction={props.updateUserInteraction}
                isSelected={props.isLead}
              />
            )}
            {props.configuration.showDeleteOpportunityIcon && (
              <RemoveOpportunityButton
                tenderUuid={props.tenderSearchHit.tender.uuid}
                updateUserInteraction={props.updateUserInteraction}
                isSelected={props.isDeletedOpportunity}
              />
            )}
          </div>
        </div>
      </div>
      {showFileSummary && fileSummary &&
          <AISummary summary={fileSummary} showTitle={true} />
      }
    </>
  );
};

const MatchedProfileButtons: React.FC<{ matchedProfiles: string[] }> = ({ matchedProfiles }) => {
  const navigate = useNavigate();
  const navigateToProfile = (profileName: string) => {
    navigate("/account?profile=" + profileName);
  };
  if (matchedProfiles.length === 0) return <span>...</span>;
  else
    return (
      <span>
        {matchedProfiles.map((profile, i) => (
          <span key={i}>
            <span onClick={() => navigateToProfile(profile)} className={"navigate-to-profile-button"}>
              {profile}
            </span>
            {i < matchedProfiles.length - 1 && <span>, </span>}
          </span>
        ))}
      </span>
    );
};

const PublicationDetailsWithIcons: React.FC<TenderCardBodyProps> = (props) => {
  let tender = props.tenderSearchHit.tender;
  let showAwardDate = props.configuration.showAwardDateInsteadOfDeadline && tender.awardDate != null;
  let showDeadline = !showAwardDate && tender.deadline !== null;
  let showEstimatedValueInThirdSlot = !showDeadline && !showAwardDate && getEstimatedTotalValue(tender) != null;
  let showAccreditationsInThirdSlot =
    !showDeadline && !showAwardDate && !showEstimatedValueInThirdSlot && tender.accreditations?.length > 0;
  let showRenewalDateInThirdSlot = props.configuration.showEstimatedRenewalDate && !showDeadline && !showAwardDate && !showEstimatedValueInThirdSlot && !showAccreditationsInThirdSlot && tender.estimatedRenewalDate != null;
  // These divs are rows with two elements each, the third slot is the first cell in the second row
  return (
    <>
      <div className="all-icon-text-alignment" onClick={props.navigateToDetailsPage}>
        <PublicationDateWithIcon tender={tender} />
        <RegionCodesWithIcon tender={tender} />
      </div>
      <div className="all-icon-text-alignment" onClick={props.navigateToDetailsPage}>
        {showDeadline && <DeadlineWithIcon tender={tender} />}
        {showAwardDate && <AwardOrOpeningReportPublicationDateWithIcon tender={tender} />}
        {showEstimatedValueInThirdSlot && <EstimatedValueWithIcon tender={tender} />}
        {showAccreditationsInThirdSlot && <AccreditationCodesWithIcon tender={tender} />}
        {showRenewalDateInThirdSlot && <EstimatedRenewalDateWithIcon tender={tender} />}
        <SubjectCodesWithIcon tender={tender} />
      </div>
      {!showEstimatedValueInThirdSlot ||
        (!showAccreditationsInThirdSlot && (
          <div className="all-icon-text-alignment" onClick={props.navigateToDetailsPage}>
            {!showEstimatedValueInThirdSlot && <EstimatedValueWithIcon tender={tender} />}
            {!showAccreditationsInThirdSlot && <AccreditationCodesWithIcon tender={tender} />}
          </div>
        ))}
      {!showRenewalDateInThirdSlot && props.configuration.showEstimatedRenewalDate && tender.estimatedRenewalDate != null && (
        <div className="all-icon-text-alignment" onClick={props.navigateToDetailsPage}>
          <EstimatedRenewalDateWithIcon tender={tender} />
        </div>
      )}
      <div></div>
    </>
  );
};

interface InfoWithIconProps {
  tender: Tender;
}

const PublicationDateWithIcon: React.FC<InfoWithIconProps> = ({ tender }) => {
  let { t } = useTranslation();
  return (
    <div className="icon-text-design">
      <div>
        <div>
          <PublicationDateBlueIcon />
        </div>
        <div>
          <p>
            {t("publications.publicationDateTitle") + ": "}
            <span>{DateUtil.formatDate(tender.publicationDate)}</span>
          </p>
        </div>
      </div>
    </div>
  );
};

const AwardOrOpeningReportPublicationDateWithIcon: React.FC<InfoWithIconProps> = ({ tender }) => {
  let { t } = useTranslation();
  return <div className="icon-text-design">
    <div>
      <div>
        <PublicationDateBlueIcon />
      </div>
      <div>
        <p>{t("publications.awardPublicationDateTitle") + ": "}<span>{DateUtil.formatDate(tender.awardDate)}</span>
        </p>
      </div>
    </div>
  </div>;
};

const EstimatedRenewalDateWithIcon: React.FC<InfoWithIconProps> = ({ tender }) => {
  let { t } = useTranslation();
  return <div className="icon-text-design">
    <div>
      <div>
        <DeadlineBlueIcon />
      </div>
      <div>
        <p>{t("publications.estimatedRenewalDateTitle") + ": "}<span>{DateUtil.formatDate(tender.estimatedRenewalDate ? tender.estimatedRenewalDate : "")}</span>
        </p>
      </div>
    </div>
  </div>;
};

const DeadlineWithIcon: React.FC<InfoWithIconProps> = ({ tender }) => {
  let { t } = useTranslation();
  if (tender.deadline == null) return <div></div>;
  let label = t("publications.deadlineTitle") + ": ";
  let deadlineDate = DateUtil.convertDateTimeStringToDateString(DateUtil.convertDateTimeStringToDateString(tender.deadline));
  if (deadlineDate && DateUtil.getDateMoment(deadlineDate).isBefore(DateUtil.today())) label = t("publications.openingReportPublicationDateTitle") + ": ";
  return <div className="icon-text-design">
    <div>
      <div>
        <DeadlineBlueIcon />
      </div>
      <div>
        <p>{label}<span>{DateUtil.formatDateTime(tender.deadline)}</span>
        </p>
      </div>
    </div>
  </div>;
};

const EstimatedValueWithIcon: React.FC<InfoWithIconProps> = ({ tender }) => {
  let { t } = useTranslation();
  const estimatedValue = getEstimatedTotalValue(tender);
  if (estimatedValue == null) return <></>;
  else return <div className="icon-text-design">
    <div>
      <div>
        <EuroCircledBlueIcon />
      </div>
      <div>
        <p>{t("publications.estimatedTotalValueTitle") + ": "}<span>{estimatedValue}</span>
        </p>
      </div>
    </div>
  </div>;
};


const SubjectCodesWithIcon: React.FC<InfoWithIconProps> = ({ tender }) => {
  let { t } = useTranslation();
  if (tender.mainSubjectCodes == null || tender.mainSubjectCodes.length === 0) return <div></div>;
  else return <div className="icon-text-design">
    <div>
      <div>
        <SubjectCodeBlueIcon />
      </div>
      <div>
        <p>{t("publications.subjectCodesTitle") + ": "}<span>{getMainSubjectCodes(tender, t)}</span>
        </p>
      </div>
    </div>
  </div>;
};

const AccreditationCodesWithIcon: React.FC<InfoWithIconProps> = ({ tender }) => {
  let { t } = useTranslation();
  if (tender.accreditations == null || tender.accreditations.length === 0) return <div></div>;
  else return <div className="icon-text-design">
    <div>
      <div>
        <CraneBlueIcon />
      </div>
      <div>
        <p>{t("publications.accreditationsTitle") + ": "}<span>{tender.accreditations.join(", ")}</span>
        </p>
      </div>
    </div>
  </div>;
};

const RegionCodesWithIcon: React.FC<InfoWithIconProps> = ({ tender }) => {
  let { t } = useTranslation();
  if (tender.regionCodes == null || tender.regionCodes.length === 0) return <div></div>;
  else return <div className="icon-text-design">
    <div>
      <div>
        <RegionCodeBlueIcon />
      </div>
      <div>
        <p>{t("publications.regionCodesTitle") + ": "}<span>{getMainRegionCodes(tender, t)}</span>
        </p>
      </div>
    </div>
  </div>;
};

interface UserInteractionButtonProps {
  tenderUuid: string;
  updateUserInteraction: (tenderUuid: string, isSelected: boolean, interactionType: UserInteractionType) => void;
  isSelected: boolean;
}

const ToggleLeadButton: React.FC<UserInteractionButtonProps> = (props) => {
  const { t } = useTranslation();
  const toggle = () => {
    props.updateUserInteraction(props.tenderUuid, props.isSelected, UserInteractionType.MAKE_LEAD);
  };
  return <>
    {!props.isSelected &&
      <div onClick={toggle} className={"unSelected"}
           title={props.isSelected ? t("detailsSideBar.removeLead") : t("detailsSideBar.makeLeadTitle")}>
        <StarBlueIcon />
      </div>}
    {props.isSelected &&
      <div onClick={toggle} className={"unSelected jump-up"}
           title={props.isSelected ? t("detailsSideBar.removeLead") : t("detailsSideBar.makeLeadTitle")}>
        <StarBlueFillIcon />
      </div>}
  </>;
};

interface SummarizeSpecificationButtonProps {
  toggleSummary: () => void;
}

const SummarizeSpecificationButton: React.FC<SummarizeSpecificationButtonProps> = ({toggleSummary}) => {
  const { t } = useTranslation();
  return <>
        <div onClick={toggleSummary} className={"orange-border"}
             title={t("publications.createSpecificationSummaryTitle")}>
          <SparklesOrangeIcon/>
        </div>
  </>;
};

const RemoveOpportunityButton: React.FC<UserInteractionButtonProps> = (props) => {
  const { t } = useTranslation();
  const toggle = () => {
    props.updateUserInteraction(props.tenderUuid, props.isSelected, UserInteractionType.DELETE_OPPORTUNITY);
  };
  return <>

    {!props.isSelected &&
      <div onClick={toggle} className={"unSelected"}
           title={props.isSelected ? t("detailsSideBar.undeleteOpportunity") : t("detailsSideBar.deleteOpportunity")}>
        <TrashBlueIcon />
      </div>}
    {props.isSelected &&
      <div onClick={toggle} className={"unSelected jump-up"}
           title={props.isSelected ? t("detailsSideBar.undeleteOpportunity") : t("detailsSideBar.deleteOpportunity")}>
        <TrashBlueFillIcon />
      </div>}
  </>;
};

function getMainRegionCodes(tender: Tender, t: TFunction) {
  let regionCodes = getCodeTranslations(tender.regionCodes, t);
  if (regionCodes !== null && regionCodes.length > 0) return regionCodes.sort()[0];
  else return null;
}

function getMainSubjectCodes(tender: Tender, t: TFunction) {
  let subjectCodes = getCodeTranslations(tender.mainSubjectCodes, t);
  if (subjectCodes !== null && subjectCodes.length > 0) return subjectCodes.sort()[0];
  else return null;
}

function getAccreditations(tender: Tender) {
  let accreditations = tender.accreditations;
  if (accreditations !== null && accreditations.length > 0) return accreditations.sort()[0];
  else return null;
}

const AddendumHighlights: React.FC<TenderCardBodyProps> = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [postDownload, { isLoading, isError, error }] = usePostDownloadFileMutation();
  const downloadFile = (fileName: string) => {
    let addenda: S3FileDocument[] = props.tenderSearchHit.tender.addenda != null ? props.tenderSearchHit.tender.addenda : [];
    let relevantAddendum = addenda.filter(a => a.fileName === fileName);
    if (relevantAddendum.length > 0) {
      postDownload({ tenderUuid: props.tenderSearchHit.tender.uuid, file: relevantAddendum[0] });
    }
  };
  const numberOfAddendumHighlightsToShow = 2;
  const [showAllAddendumHighlights, setShowAllAddendumHighlights] = useState(false);
  let allAddendumHighlights = removeDuplicateHighlightedAddenda(props.tenderSearchHit.highlightedAddenda);
  const selectedAddendumHighlights = showAllAddendumHighlights ? allAddendumHighlights : allAddendumHighlights.slice(0, numberOfAddendumHighlightsToShow);
  useEffect(() => {
    if (isError && error) dispatch(showErrorPopup(error));
  }, [isError, error]);
  return <>
    {isLoading && <Loader />}
    <div className="search-top-space-white"></div>
    {selectedAddendumHighlights.map((highlight, i) =>
      <div key={i} className="addendum-highlights-alignment">
        {!highlight.downloadUrl.endsWith(".zip") &&
          <p className="cursor-pointer" key={highlight.fileName} onClick={() => downloadFile(highlight.fileName)}
             title={t("publications.downloadFileTitle")}>
            {highlight.fileName}
          </p>
        }
        {/*Archive files need to be downloaded via bulk download*/}
        {highlight.downloadUrl.endsWith(".zip") &&
          <p className={"cursor-default"} key={highlight.fileName}>{highlight.fileName}</p>
        }
        {highlight.highlights.map((value, i) => (
          <span dangerouslySetInnerHTML={{ __html: "... " + value.replaceAll("em>", "b>") + " ..." }}
                key={i}></span>
        ))}
      </div>
    )}
    {allAddendumHighlights.length > numberOfAddendumHighlightsToShow &&
      <div className="show-more-result" onClick={() => setShowAllAddendumHighlights(!showAllAddendumHighlights)}>
        {!showAllAddendumHighlights &&
          <a>{t("publications.showMoreResults") + " (" + allAddendumHighlights.length + ")"}</a>}
        {showAllAddendumHighlights && <a>{t("publications.showFewerResults")}</a>}
        <svg width="8" height="6" viewBox="0 0 8 6" fill="none"
             xmlns="http://www.w3.org/2000/svg">
          <path
            d="M6.58665 1.19354L3.99999 3.7802L1.41332 1.19354C1.15332 0.933535 0.73332 0.933535 0.47332 1.19354C0.21332 1.45354 0.21332 1.87354 0.47332 2.13354L3.53332 5.19353C3.79332 5.45354 4.21332 5.45354 4.47332 5.19353L7.53332 2.13354C7.79332 1.87354 7.79332 1.45354 7.53332 1.19354C7.27332 0.940202 6.84665 0.933535 6.58665 1.19354Z"
            fill="#1EC3CB" />
        </svg>
      </div>
    }
  </>;
};

function removeDuplicateHighlightedAddenda(highlightedAddenda: TenderSearchHighlightedAddendum[]) {
  let fileNames: string[] = [];
  let distinctAddenda: TenderSearchHighlightedAddendum[] = [];
  for (const addendum of highlightedAddenda) {
    if (fileNames.includes(addendum.fileName)) continue;
    fileNames.push(addendum.fileName);
    distinctAddenda.push(addendum);
  }
  return distinctAddenda;
}

export const TenderCardSkeleton = () => {
  return <>
    <div className={"card skeleton-wave-background"} style={{ height: "300px", width: "100%" }}></div>
    {/*<Skeleton width={'95%'} height={300} animation={"pulse"} variant={"text"} style={{ backgroundColor: "var(--bg-light)"}}/>*/}
  </>;
};