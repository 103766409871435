import React from 'react';
import styles from './index.module.scss';

import { FlagIcon } from '../../../flags/flags';
import { Country } from '../../../../consts/countries';


export interface CompanyNameProps extends React.HTMLAttributes<HTMLHeadingElement> {
    country?: Country;
}
export const CompanyName: React.FC<CompanyNameProps> = ({
    children, 
    country=null,
    ...props
 }) => {
    return (<div 
        className={styles.title}
        {...props}
    >
        {country && <FlagIcon country={country}/>}
        {children}
    </div>);
};
