import React from 'react';
import styles from './index.module.scss';


export interface InfoProps extends React.HTMLAttributes<HTMLDivElement> {
}
export const Info: React.FC<InfoProps>  = ({
    children,
    ...props
}) => {
    return (<div
        className={styles['info']}
        { ...props }
    >
        { children }
    </div>);
} 
