import React, {useState, useEffect, HTMLAttributes, useRef } from "react";
import {
    CompanySuggestion,
    CompanySuggestionsSource,
    GetCompaniesByNameParams,
    useGetCompaniesByNameQuery
} from "../../hooks/slices/companySlice";
import { useTranslation } from "react-i18next";
import { skipToken } from "@reduxjs/toolkit/query";
import { CloseIcon } from "../icons";
import {MultilingualFieldUtil} from "../../utils/multilingualfield";
import {Language} from "../../consts/languages";
import {UserLanguage} from "../../hooks/raiseUser";
import moment from "moment/moment";
import styles from './index.module.scss';
import { Option } from './option';
import type { OptionProps } from './option';

export interface DropDownProps extends HTMLAttributes<HTMLDivElement> {
    initialSelectedValue: string;
}

export const Dropdown: React.FC<DropDownProps> & {
    Option: typeof Option;
} = ({
    initialSelectedValue,
    children,
}) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [selectedValue, setSelectedValue] = useState<string>(
        initialSelectedValue
    );
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                dropdownRef.current && 
                !dropdownRef.current.contains(event.target as Node)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleSelect = (value: string) => {
        setSelectedValue(value);
        setIsOpen(false);
    };

    return (
        <div 
            className={styles.dropdown} 
            ref={dropdownRef}
        >
            <div 
                className={styles.selected}
                onClick={() => setIsOpen(!isOpen)}
            >
                {   React.Children.toArray(children).find((child) => 
                        React.isValidElement<OptionProps>(child) && 
                        child.props.value === selectedValue
                    ) 
                    ? React.cloneElement(
                        React.Children.toArray(children).find((child) => 
                            React.isValidElement<OptionProps>(child) && 
                            child.props.value === selectedValue
                        ) as React.ReactElement<OptionProps>, 
                        { isHeader: true }
                    )
                    : selectedValue
                }
            </div>
            { isOpen && <div className={styles.options}>
                {React.Children
                    .toArray(children)
                    .filter((child) => 
                        React.isValidElement<OptionProps>(child)
                    )
                    .map((child) => {
                        if (React.isValidElement<OptionProps>(child)) {
                            return React.cloneElement(child, {
                                className: styles.option,
                                onClick: (
                                    event: React.MouseEvent<
                                        HTMLDivElement, 
                                        MouseEvent
                                >) => {
                                    if (child.props.onClick) {
                                        child.props.onClick(event);
                                    }
                                    handleSelect(child.props.value);
                                },
                            });
                        }
                    throw new Error('Dropdown children must be of type Option');
                })}
            </div>}
        </div>
    );
};
Dropdown.Option = Option;


export const SortByDropdown: React.FC<FormDropdownProps> = ({label,  dropdownElements, setSelectedElement, style}) => {

    let dropDownStyle: FormDropdownStyle = style !== undefined ? style : defaultFormDropdownStyles();

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [currentlySelectedElement, setCurrentlySelectedElement] = useState(dropdownElements[0]);
    const [currentDropDownElements, setCurrentDropDownElements] = useState(dropdownElements.slice(1, dropdownElements.length));

    const changeSelectedElement = (newSelectedElement: FormDropdownElement) => {
        setCurrentlySelectedElement(newSelectedElement)
        setSelectedElement(newSelectedElement)
        let newUnselectedElements = dropdownElements.filter(e => e.name !== newSelectedElement.name && e.value !== newSelectedElement.value)
        setCurrentDropDownElements(newUnselectedElements)
        setDropdownOpen(false);
    }

    return (<>
        <div className={dropDownStyle.main}>
            <div className={dropDownStyle.button}>
                {label && <p className={dropDownStyle.buttonLabel}>{label}</p>}
                <div className={dropDownStyle.buttonContent}>
                    <button type={'button'} onClick={() => setDropdownOpen(!dropdownOpen)} onBlur={() => setDropdownOpen(false)}>
                        <span>{currentlySelectedElement.name}</span>
                        <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9.87998 0.292988L5.99998 4.17299L2.11998 0.292988C1.72998 -0.0970117 1.09998 -0.0970117 0.70998 0.292988C0.31998 0.682988 0.31998 1.31299 0.70998 1.70299L5.29998 6.29299C5.68998 6.68299 6.31998 6.68299 6.70998 6.29299L11.3 1.70299C11.69 1.31299 11.69 0.682988 11.3 0.292988C10.91 -0.0870117 10.27 -0.0970117 9.87998 0.292988Z" fill="#51565F"></path></svg>
                    </button>
                </div>
            </div>
            <div className={dropdownOpen ? dropDownStyle.showList : dropDownStyle.hideList}>
                <div className={dropDownStyle.listContent}>
                    {currentDropDownElements.map((dropdownElement, i) =>
                        <span key={i} onMouseDown={() => changeSelectedElement(dropdownElement)}>{dropdownElement.name}</span>
                    )}
                </div>
            </div>
        </div>
    </>)
}

export interface FormDropdownProps {
    label?: string;
    dropdownElements: FormDropdownElement[];
    setSelectedElement: (formDropdownElement: FormDropdownElement) => void;
    // setSelectedElement: React.Dispatch<React.SetStateAction<{value: string, name: string}>>
    style?: FormDropdownStyle;
}

export interface FormDropdownElement{
    value: any;
    name: string;
}

export interface FormDropdownStyle{
    main: string;
    button: string;
    buttonLabel: string;
    buttonContent: string;
    showList: string;
    hideList: string;
    listContent: string;
}

function defaultFormDropdownStyles(): FormDropdownStyle {
    let main = 'relative-div';
    let button = 'dropdown-title';
    let buttonLabel = '';
    let buttonContent = 'relative-div';
    let showList = 'dropdown-alignment dropdown-show';
    let hideList = 'dropdown-alignment dropdown-hidden';
    let listContent = 'dropdown-design';
    return {main, button, buttonLabel, buttonContent, showList, hideList, listContent}
}

export interface CompanyDropdownProps {
    placeholder: string;
    autocomplete: (company: CompanySuggestion) => void;
    onChange: (query: string) => void;
    companySelected: boolean;
    reset: () => void;
    source: CompanySuggestionsSource;
    eraseInputOnAutoComplete?: boolean;
}

export const CompanyDropdown: React.FC<CompanyDropdownProps> = ({placeholder, autocomplete, onChange, companySelected, reset, source, eraseInputOnAutoComplete}) => {
    const { t, i18n } = useTranslation();
    const language: Language = UserLanguage(i18n.language);
    const [showDropdown, setShowdropdown] = useState(false);
    const [companySuggestions, setCompanySuggestions] = useState<CompanySuggestion[]>([]);
    const [query, setQuery] = useState<GetCompaniesByNameParams | undefined>(undefined);
    const {data, isLoading, isSuccess, error} = useGetCompaniesByNameQuery(query ?? skipToken);
    const select = (company: CompanySuggestion) => {
        if(eraseInputOnAutoComplete) setQuery({name: "", source: source});
        else setQuery({name: MultilingualFieldUtil.translate(company.name, language), source: source});
        autocomplete(company);
    }
    const changeQuery = (q: string) => {
        setQuery({name: q, source: source})
        onChange(q);
    }
    const resetAll = () => {
        setShowdropdown(false);
        reset();
        setQuery({name: "", source: source});
    }
    useEffect(() => {
        if(isSuccess && data != null) {
            setCompanySuggestions(data.suggestions);
        }
    }, [data, isSuccess]);
    return (
        <>
        {companySelected &&
            <div className='input' style={{display: 'flex', justifyContent: 'space-between'}}>
                <input type="text" data-testid={"company-dropdown-company-selected"} maxLength={255} placeholder={placeholder} readOnly={true} value={query?.name}/>
                <div className={'reset-input-button'} onClick={resetAll}><CloseIcon/></div>
            </div>
        }
        {!companySelected &&
            <>
                <CompanyDropdownInput query={query ? query.name : ""} toggleDropdown={setShowdropdown} changeQuery={changeQuery} delay={300} />
                {showDropdown && <CompanyDropdownCards companySuggestions={companySuggestions} select={select}/>}
            </>
        }
        </>
    );
};

interface CompanyDropdownInputProps{
    // showDropdown: boolean;
    query: string;
    toggleDropdown: (show: boolean) => void;
    changeQuery: (query: string) => void;
    delay: number;
}
const CompanyDropdownInput: React.FC<CompanyDropdownInputProps> = ({query, toggleDropdown, changeQuery, delay}) => {
    const {t} = useTranslation();
    const [searchQuery, setSearchQuery] = useState(query);
    const changeSearchQuery: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery(e.currentTarget.value);
    };
    useEffect(() => {
        const getData = setTimeout(() => {
            changeQuery(searchQuery);
        }, delay)
        return () => clearTimeout(getData)
    }, [searchQuery]);
    useEffect(() => {
        //so on rerender
        setSearchQuery(query);
    }, [query]);

    return (
        <div
            className="input"
        >
            <input
                onChange={e => changeSearchQuery(e)}
                value={searchQuery}
                type={"text"}
                placeholder={t("intake.company_name")}
                onFocus={() => toggleDropdown(true)}
                onBlur={() => toggleDropdown(false)}
                data-testid={"company-dropdown-company-input"}
            />
        </div>
    );
};
interface CompanyDropdownCardsProps{
    // showDropdown: boolean;
    companySuggestions: CompanySuggestion[];
    select: (company: CompanySuggestion) => void;
}
const CompanyDropdownCards: React.FC<CompanyDropdownCardsProps> = ({companySuggestions, select}) => {
    // todo fix width in case of company page
    return <div style={{maxHeight: '360px', width: '462px', position: 'absolute', zIndex:999}} className={'dropdown-scrollbar'} data-testid={"company-dropdown-company-cards"}>
        {companySuggestions.map((c) => <CompanyDropdownCard key={c.uuid} company={c} select={select} />)}
    </div>
}

interface CompanyDropdownCardProps{
    company: CompanySuggestion
    select: (company: CompanySuggestion) => void;
}
const CompanyDropdownCard: React.FC<CompanyDropdownCardProps> = ({company, select}) => {
    const { t, i18n } = useTranslation();
    const language: Language = UserLanguage(i18n.language);
    return (
        <div
            onMouseDown={e => select(company)} //mouseDown is before onBlur
            // className={"card blue-border"}
            style={{
                margin: "0px",
                marginBottom: "6px",
                padding: "11px 16px",
                border: "1px solid #A3E4DB",
                borderLeftWidth: "3px",
                borderRadius: "8px",
                backgroundColor: "var(--bg-white)",
                cursor: "pointer"
                // boxShadow: '1px 1px 10px rgba(30, 195, 203, 0.25)', add this to hover class
            }}
        >
            {/*<p>test</p>*/}
            <CompanyDropdownCardTitle name={MultilingualFieldUtil.translate(company.name, language)} />
            <CompanyDropdownCardKeyValue k={t("intake.vat_number_default")} v={company.vatNumber} />
            {company.address && <CompanyDropdownCardKeyValue k={t("intake.address")} v={MultilingualFieldUtil.translate(company.address, language)} />}
            {/*{company.website && <CompanyDropdownCardKeyValue k={t("intake.website")} v={company.website} />}*/}
            {/*{company.type && <CompanyDropdownCardKeyValue k={t("intake.Type")} v={company.type} />}*/}
            {/*{company.mainActivity && <CompanyDropdownCardKeyValue k={t("intake.main_activity")} v={company.mainActivity} />}*/}
            {company.startDate && <CompanyDropdownCardKeyValue k={t("intake.starting_date")} v={company.startDate} />}
        </div>
    );
};

const CompanyDropdownCardTitle: React.FC<{ name: string }> = ({ name }) => {
    return (
        <h1
            style={{
                color: "var(--black-3)",
                fontSize: "16px",
                padding: "0 0 12px 0",
                margin: "0 0 0px 0",
                fontFamily: "var(--default-font-family)",
            }}
        >
            {name}
        </h1>
    );
};

const CompanyDropdownCardKeyValue: React.FC<{ k: string; v: string }> = ({ k, v }) => {
    return (
        <>
            <p
                style={{
                    color: "var(--black-3)",
                    fontSize: "12px",
                    padding: "0 0 6px 0",
                    fontFamily: "var(--default-font-family)",
                }}
            >
                {k} : {v}
            </p>
        </>
    );
};

