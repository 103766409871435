import React, {
    useState,
} from 'react';

import { CompanyCard } from '../../../../components/companySummaryCard';

interface Tab {
    index: string[];
    label: string;
}
interface TabDropdown {
    index: string[];
    label: string;
    dropdown: Tab[];
}
export interface CompanyPageTabsProps {
    activeTab: string[],
    setActiveTab: (tab:string[])=> void,
    tabs?: (Tab | TabDropdown)[];
}

export const CompanyPageTabs: React.FC<CompanyPageTabsProps> = ({
    activeTab,
    setActiveTab,
    tabs = [],
}) => {
    return (<CompanyCard.TabList>{tabs.map((tab, i) => {
        if ("dropdown" in tab) {
            return (<CompanyCard.TabDropdown
                isActive={ activeTab[0] === tab.index[0] }
                header={<div
                    onClick={() => setActiveTab([tab.index[0]])}
                >
                    {tab.label}
                </div>}
                options={tab.dropdown.map((dropdownTab, i) => {
                    return (<div
                        onClick={() => setActiveTab(dropdownTab.index)}
                    >
                        {dropdownTab.label}
                    </div>)
                })}  
            />)
        } else {
            return (<CompanyCard.Tab
                key={i}
                isActive={ activeTab[0] === tab.index[0] }
                onClick={() => setActiveTab(tab.index)}
            >
                {tab.label}
          </CompanyCard.Tab>)
        }
    })}</CompanyCard.TabList>)
}