import React from 'react';
import styles from './index.module.scss';


export interface TabListProps extends React.HTMLAttributes<HTMLDivElement> {
}
export const TabList: React.FC<TabListProps> = ({ 
    children, 
    ...props
 }) => {
    return (<div
        className={styles['tab-list']}
        {...props}
    >
        { children }
    </div>);
};