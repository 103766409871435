import {useAppDispatch} from "../../app/hooks";
import {useTranslation} from "react-i18next";
import {showCustomSuccessPopup, showErrorPopup} from "../../hooks/slices/snaccSlice";
import styles from "./index.module.scss";
import {CopyIcon} from "../icons";
import React from "react";

export const IconWithTextAndCopyButton = ({icon, text}: {icon: JSX.Element, text: string}) => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const copyTextToClipboard = (text: string) => {
        navigator.clipboard.writeText(text).then(() => {
            dispatch(showCustomSuccessPopup(t("companyPage.copied_to_clipboard_title_1") + text + t("companyPage.copied_to_clipboard_title_")));
        }).catch(err => {
            dispatch(showErrorPopup(err));
        });
    };
    return <div 
        className={styles["icon-with-text-and-copy-button"]}
        onClick={() => copyTextToClipboard(text)}    
    >
        <div className={styles["icon"]}>{icon}</div>
        <p>{text}</p>
        <div className={styles["copy-icon"]}><CopyIcon/></div>
    </div>
}