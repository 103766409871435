import React from "react";
import styles from "./index.module.scss";
import { 
    getProgressBarPercentage,
} from "./getProgressBarPercentage";
import { FlagIcon } from "../flags/flags";
import { Country } from "../../consts/countries";
import { useTranslation } from "react-i18next";
import { CompanyDto } from "../../hooks/slices/companySlice";
import { CompanyIcon23, LocationIcon, StarFillIcon, StarIcon, WebsiteIcon } from "../icons";
import { useNavigate } from "react-router-dom";
import {
  UserInteraction,
  UserState,
} from "../../hooks/slices/userSlice";
import { UserInteractionType } from "../../consts/UserInteractionType";
import { RaiseUser } from "../../hooks/raiseUser";
import { ProgressCircle } from "../progressCircle";
import { IconWithTextAndCopyButton } from "../iconWithTextAndCopyButton";
import { IconWithLink } from "../iconWithLink";
import { CompanyUtil } from "../../utils/company";
import SearchIcon from "../../assets/icons/search.svg";
import { FollowOrUnfollowCompany } from "../followOrUnfollowCompany";
import { 
    ProjectsContractedProgressCircle,
    SharedProjectsProgressCircle, 
    SharedProjectsWonProgressCircle,
} from "./subcomponents";

export interface CompanyOverviewCardProps {
  company: CompanyDto;
  companyType: "contracting authority" | "company";  
  
  originalCompanyName?: string;
  projectsContracted?: number;
  sharedProjects?: string[];
  sharedProjectsWon?: string[];
}

export const CompanyOverviewCard: React.FC<CompanyOverviewCardProps> = ({
    company, 
    companyType,

    sharedProjects, 
    sharedProjectsWon,
    originalCompanyName, 
    projectsContracted,
}) => {
    let {t} = useTranslation();
    const user: UserState | undefined = RaiseUser().user;
    const userInteractions: UserInteraction[] = user?.userInteractions ?? [];
    const companyIsFollowed = userInteractions.some(i =>
        i.interactionType === UserInteractionType.FOLLOW_COMPANY 
        && i.uuid === company.uuid
    );
    let address = CompanyUtil.getCompanyAddress(company);
    const navigateToCompanyPage = () => {
        //todo using window.location.assign here to force a reload of the page -> find a better way to streamline fetching of data on the company page
        if (companyType==="contracting authority") window.location.assign("/government/" + company.uuid);
        else window.location.assign("/company/" + company.uuid);
    };
    return (
        <div className={styles['container']}>
            <div className={styles['left-container']}>
                <div className={styles['header']}>
                    <h3 onClick={navigateToCompanyPage}
                        title={t("companyPage.show_company_page")}
                    >
                        <FlagIcon country={company.country as Country}/>
                        { company.name }
                    </h3>
                    <FollowOrUnfollowCompany
                        userUuid={user?.uuid}
                        companyIsFollowed={companyIsFollowed}
                        company={company}
                    />
                </div>
                <div className={styles['body']}>
                    <div style={{marginBottom: '12px'}}>
                        <IconWithTextAndCopyButton 
                            icon={<CompanyIcon23/>} 
                            text={company.vatNumber.replace("BE", "")}
                        />
                        {address && <IconWithTextAndCopyButton 
                            icon={<LocationIcon/>} 
                            text={address}
                        />}
                    </div>
                    <div style={{marginBottom: '12px'}}>
                        {CompanyUtil.getCompanyActivities(company.activities).map((activity, index) => 
                            (<p key={index}>{activity}</p>)
                        )}
                    </div>
                    {company.website && <IconWithLink 
                        icon={<WebsiteIcon/>} 
                        text={company.website}/>
                    }
                </div>
            </div>
            { originalCompanyName && companyType === "contracting authority" && sharedProjectsWon && <SharedProjectsWonProgressCircle
                companyName={company.name}
                originalCompanyName={originalCompanyName}
                sharedProjectsWon={sharedProjectsWon}
            />}
            {originalCompanyName &&  companyType==="company" && sharedProjects && <SharedProjectsProgressCircle
                companyName={company.name}
                originalCompanyName={originalCompanyName}
                sharedProjects={sharedProjects}
            />}
            {/* {companyType==="contracting authority" && projectsContracted && 
                <ProjectsContractedProgressCircle
                    companyName={company.name}
                    originalCompanyName={originalCompanyName}
                    projectsContracted={projectsContracted}
                />
            } */}
        </div>
    );
}