import React 
  from 'react';
import { 
    CompanyCard,
} from '../../../../components/companySummaryCard';
import { 
    FollowOrUnfollowCompany, 
} from '../../../../components/followOrUnfollowCompany';
import { 
    Country, 
} from '../../../../consts/countries';
import { 
    RaiseUser, 
} from '../../../../hooks/raiseUser';
import { 
    CompanyDto, 
} from '../../../../hooks/slices/companySlice';
import { 
    UserState, 
} from '../../../../hooks/slices/userSlice';



export interface CompanyPageHeaderTitleProps {
    company: CompanyDto,
    companyIsFollowed: boolean,
}
export const CompanyPageHeaderTitle: React.FC<CompanyPageHeaderTitleProps> = ({
    company,
    companyIsFollowed,
}) => {
    const user: UserState | undefined = RaiseUser().user;
    return (<CompanyCard.CompanyName
        country={company.country as Country}
    >
        { company.name } {company.juridicalForm && `(${company.juridicalForm})`}
        <FollowOrUnfollowCompany
            userUuid={user?.uuid}
            companyIsFollowed={companyIsFollowed}
            company ={company}
        />
    </CompanyCard.CompanyName>);
}