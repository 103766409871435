import React from 'react';
import styles from './index.module.scss';


export interface InfoItemProps extends React.HTMLAttributes<HTMLDivElement> {
    text: string;
    icon?: React.ReactNode;
}
export const InfoItem: React.FC<InfoItemProps> = ({
    text, 
    icon=null,
    ...props
}) => {
    return (<div 
        className={styles['info-item']} 
        {...props}
    >
        <div className={styles['icon']}> {icon} </div>
        <div className={styles['text']}> {text} </div>
    </div>)
}