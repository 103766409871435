import { apiSlice } from "./apiSlice";
import { MultilingualField } from "../../types/tender";
import { CodeBranch } from "../../types/code";
import { FetchArgs } from "@reduxjs/toolkit/query";
import { Language } from "../../consts/languages";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PURGE } from "redux-persist";


/*
  REDUX STORE stuff
*/

export enum CompanySuggestionsSource {
    KBO = "KBO",
    COMPANY_PAGE_ANY = "COMPANY_PAGE_ANY",
    COMPANY_PAGE_BUYERS = "COMPANY_PAGE_BUYERS",
    COMPANY_PAGE_PARTICIPANTS = "COMPANY_PAGE_PARTICIPANTS"
}

export interface MarketIntelligenceSearchState{
    query: string,
    language: Language,
    vatNumbers: string[],
    subjectCodes: CodeBranch[],
    regionCodes: CodeBranch[],
    accreditations: CodeBranch[],
    source: CompanySuggestionsSource;
    page: number,
    pageSize: number
}

const initialState: MarketIntelligenceSearchState = {
    query: "",
    language: Language.EN,
    vatNumbers: [],
    subjectCodes: [],
    regionCodes: [],
    accreditations: [],
    source: CompanySuggestionsSource.COMPANY_PAGE_PARTICIPANTS,
    page: 0,
    pageSize: 20
}

export const marketIntelligenceSearchSlice = createSlice({
    initialState,
    name: "marketIntelligenceSearch",
    reducers: {
        updateMarketIntelligenceSearch(state, action: PayloadAction<MarketIntelligenceSearchState>) {
            state.query = action.payload.query;
            state.language = action.payload.language;
            state.vatNumbers = action.payload.vatNumbers;
            state.subjectCodes = action.payload.subjectCodes;
            state.regionCodes = action.payload.regionCodes;
            state.accreditations = action.payload.accreditations;
            state.source = action.payload.source;
            state.page = action.payload.page;
            state.pageSize = action.payload.pageSize;
        }
    }, extraReducers: (builder) => {
        builder.addCase(PURGE, (state) => {
            state.query = initialState.query;
            state.language = initialState.language;
            state.vatNumbers = initialState.vatNumbers;
            state.subjectCodes = initialState.subjectCodes;
            state.regionCodes = initialState.regionCodes;
            state.accreditations = initialState.accreditations;
            state.source = initialState.source;
            state.page = initialState.page;
            state.pageSize = initialState.pageSize;
        });
    }
});

export const {updateMarketIntelligenceSearch} = marketIntelligenceSearchSlice.actions;

/*
  API connection stuff
*/



export interface GetCompaniesByNameParams {
    name: string;
    source: CompanySuggestionsSource;
    page?: number;
    pageSize?: number;
}

export interface GetCompaniesByVatNumberInParams {
    vatNumbers: string[];
    source: CompanySuggestionsSource;
    page?: number;
    pageSize?: number;
}

export interface CompanySuggestionsDto{
    suggestions: CompanySuggestion[];
    totalHits: number;
}

export interface CompanySuggestion {
    uuid: string;
    vatNumber: string;
    name: MultilingualField;
    address?: MultilingualField;
    startDate?: string;
}

export interface CompanyActivityDto{
    description: string,
    type: string
}

export interface CompanyDto{
    name: string,
    uuid: string,
    vatNumber: string,
    street: string,
    postalCode: string,
    city: string,
    country: string,
    website: string,
    phone: string,
    email: string,
    activities: CompanyActivityDto[]
    startDate?: string,
    juridicalForm?: string,
}

export interface CompanyRelationDto{
    company: CompanyDto,
    relation: CompanyDto,
    sharedProjects: number,
    directRelation: boolean
}

export interface CompanyCodeDto {
    cpvRoot: CodeBranch,
    count: number
}

export interface RecentTenderDto {
    title: MultilingualField,
    type: string,
    uuid: string
    publicationDate: string,
    totalHits: number
    awardedValue?: number
    awardType?: string
}

export interface CompanyPageDto {
    company: CompanyDto,
    competitors: string[],
    buyers: string[],
    suppliers: string[],
    tendersWon: RecentTenderDto[],
    tendersParticipatedIn: RecentTenderDto[],
    tendersContractedOpen: RecentTenderDto[],
    tendersContractedClosed: RecentTenderDto[],
    mainActivities: CompanyCodeDto[],
}

export interface CompetitorDto{
    company: CompanyDto,
    sharedProjects: string[],
    countries: string[],
    tendersParticipatedInDuringTheLastFiveYears: number
}

export interface CompetitorsResponse{
    competitors: CompetitorDto[],
    totalHits: number
}

export interface BuyerDto{
    company: CompanyDto,
    sharedProjects: string[],
    sharedProjectsWon: string[],
    projectsContracted: number
}

export interface BuyersResponse{
    buyers: BuyerDto[],
    totalHits: number
}

export interface SupplierDto{
    company: CompanyDto,
    sharedProjects: string[],
}

export interface SuppliersResponse{
    suppliers: SupplierDto[],
    totalHits: number
}


export interface ScreeningResponse {
    companies: CompanyWithSharedProjects[]
}

export interface CompanyWithSharedProjects{
    company: CompanyDto,
    sharedProjects: number
}

export interface GetCompanyPageParams {
    uuid: string;
    page?: number;
    pageSize?: number;
}

export interface FindCompanyParams {
    kboNumber: string;
}

export interface TenderUuids {
    tenderUuids: string[]
}

export interface CompanyPageUuids {
    companyPageUuids: string[]
}

export interface ScreeningParams {
    tenderUuid: string;
    languageIso: string;
}

export interface CompanyPageSearchInput{
    query: string,
    language: Language,
    vatNumbers: string[],
    subjectCodes: string[],
    regionCodes: string[],
    accreditations: string[],
    source: CompanySuggestionsSource;
    page: number,
    pageSize: number
}

export interface CompanyPageSearchOutput{
    pages: CompanyPageDto[],
    totalHits: number
}

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getCompaniesByName: build.query<CompanySuggestionsDto, GetCompaniesByNameParams>({
            query: (params: GetCompaniesByNameParams) => {
                let page: number = params.page ? params.page : 0;
                let pageSize: number = params.pageSize ? params.pageSize : 20;
                return {
                    url: `/shaggy/companies/suggest_names?prefix=` + params.name
                        + `&source=` + params.source
                        + `&page=` + page
                        + `&pageSize=` + pageSize
                };
            },
        }),
        getCompaniesByVatNumberIn: build.query<CompanySuggestionsDto, GetCompaniesByVatNumberInParams>({
            query: (params: GetCompaniesByVatNumberInParams) => {
                return {
                    url: `/shaggy/companies/find_by_vat_numbers_in?source=` + params.source
                        + `&vat_numbers=` + params.vatNumbers.join(",")
                };
            },
        }), companySearch: build.mutation<CompanyPageSearchOutput, MarketIntelligenceSearchState>({
            query: (body: MarketIntelligenceSearchState) => {
                let searchBody: CompanyPageSearchInput = {
                    query: body.query,
                    language: body.language,
                    vatNumbers: body.vatNumbers,
                    subjectCodes: body.subjectCodes.map(c => c.code),
                    regionCodes: body.regionCodes.map(c => c.code),
                    accreditations: body.accreditations.map(c => c.code),
                    source: body.source,
                    page: body.page,
                    pageSize: body.pageSize,
                }
                let args: FetchArgs = {
                    url: `/shaggy/companies/search`,
                    method: "POST",
                    body: searchBody,
                    // responseHandler: "json",
                };
                return args;
            },
        }),getCompanyPage: build.query<CompanyPageDto, GetCompanyPageParams>({
            query: (params: GetCompanyPageParams) => {
                return {
                    url: `/shaggy/companies/company_page?uuid=` + params.uuid,
                };
            },
        }),
        getCompanyPageCompetitors: build.query<CompetitorsResponse, GetCompanyPageParams>({
            query: (params: GetCompanyPageParams) => {
                let page = params.page ? params.page : 0;
                let pageSize = params.pageSize ? params.pageSize : 20;
                return {
                    url: `/shaggy/companies/company_page_competitors?uuid=` + params.uuid  + `&page=` + page + `&page_size=` + pageSize,
                };
            },
        }),
        getCompanyPageBuyers: build.query<BuyersResponse, GetCompanyPageParams>({
            query: (params: GetCompanyPageParams) => {
                let page = params.page ? params.page : 0;
                let pageSize = params.pageSize ? params.pageSize : 20;
                return {
                    url: `/shaggy/companies/company_page_buyers?uuid=` + params.uuid  + `&page=` + page + `&page_size=` + pageSize,
                };
            },
        }),
        getCompanyPageSuppliers: build.query<SuppliersResponse, GetCompanyPageParams>({
            query: (params: GetCompanyPageParams) => {
                let page = params.page ? params.page : 0;
                let pageSize = params.pageSize ? params.pageSize : 20;
                return {
                    url: `/shaggy/companies/company_page_suppliers?uuid=` + params.uuid + `&page=` + page + `&page_size=` + pageSize,
                };
            },
        }),getCompanyUuid: build.query<string, string>({
            query: (params: string) => {
                return {
                    url: `/shaggy/companies/get_uuid_by_vat?vat=` + params,
                    responseHandler: "text",
                };
            },
        }), getCompanyForSignup: build.query<CompanyDto, GetCompanyPageParams>({
            query: (params: GetCompanyPageParams) => {
                return {
                    url: `/shaggy/signup/first-company/` + params.uuid,
                };
            },
        }), getPotentialCompetitorsOrPartners: build.query<ScreeningResponse, ScreeningParams>({
            query: (params: ScreeningParams) => {
                return {
                    url: `/shaggy/companies/screening?tenderUuid=` + params.tenderUuid + `&languageIso=` + params.languageIso,
                };
            },
        })
    }),
});

export const {useCompanySearchMutation, useGetCompaniesByNameQuery, useGetCompanyPageQuery, useGetCompanyPageCompetitorsQuery, useGetCompanyPageSuppliersQuery, useGetCompanyPageBuyersQuery, useGetCompanyUuidQuery, useGetCompanyForSignupQuery, useGetCompaniesByVatNumberInQuery, useGetPotentialCompetitorsOrPartnersQuery} = extendedApiSlice;
