import React, {useEffect, useRef, useState} from "react";
import "./debounceInput.scss";
import {SearchIcon} from "../icons";

interface DebounceTextInputProps {
    search: (query: string) => void;
    delay: number;
    placeHolder: string;
    maxLength?: number;
}

export const DebounceTextInput: React.FC<DebounceTextInputProps> = ({ search, delay, placeHolder, maxLength }) => {
    const [searchQuery, setSearchQuery] = useState("");
    const changeQuery: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery(e.currentTarget.value);
    };
    useEffect(() => {
        const getData = setTimeout(() => {
            search(searchQuery);
        }, delay)
        return () => clearTimeout(getData)
    }, [searchQuery]);
    return <>
        <div className="search-input">
            <input type="text" maxLength={maxLength ? maxLength : 255} placeholder={placeHolder} onChange={changeQuery}/>
            <div className="search-icon-alignment">
                <SearchIcon/>
            </div>
        </div>
    </>
}

interface DebounceDatalistInputProps {
    search: (query: string) => void;
    select: (value: string) => void;
    delay: number;
    placeHolder: string;
    dataList: DropdownElement[];
    blur?: (value: string) => void;
    autoFocus?: boolean;
    maxLength?:number;
    hideSearchIcon?: boolean;
}

export interface DropdownElement {
    value: string;
    label: string;
}

export const DebounceDatalistInput: React.FC<DebounceDatalistInputProps> = (props) => {
    const [searchQuery, setSearchQuery] = useState("");
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const changeQuery: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        setSearchQuery(e.currentTarget.value);
        if (searchQuery.length > 0) setDropdownOpen(true);
    };
    const selectDropdownOption = (optionValue: string) => {
        setSearchQuery("");
        setDropdownOpen(false);
        props.select(optionValue);
    }
    const onBlur = (optionValue: string) => {
        setDropdownOpen(false);
        if (props.blur) {
            setSearchQuery("");
            props.blur(optionValue);
        }
    }

    const firstUpdate = useRef(true);
    useEffect(() => {
        if (firstUpdate.current) firstUpdate.current = false;//shouldn't run on first render
        else {
            const getData = setTimeout(() => {
                props.search(searchQuery);
                if (searchQuery.length === 0) setDropdownOpen(false);
            }, props.delay)
            return () => clearTimeout(getData);
        }

    }, [searchQuery]);

    return <>
        <div className="search-input">
            <input className={"debounce-input"} type="text" placeholder={props.placeHolder}
                   maxLength={props.maxLength ? props.maxLength : 255}
                   value={searchQuery}
                   onChange={changeQuery}
                   onBlur={() => onBlur(searchQuery)}
                   autoFocus={props.autoFocus}
                   onDoubleClick={() => setDropdownOpen(props.dataList.length !== 0)}
                   onKeyPress={(e) => {
                       if (e.key === "Enter") {
                           selectDropdownOption(searchQuery);
                       }
                   }}
                   data-cy={"debounce-datalist-input"}
            />
            {!props.hideSearchIcon &&
                <div className="search-icon-alignment">
                    <SearchIcon/>
                </div>
            }

        </div>
        {dropdownOpen &&
            <div className={"datalist"} >
                {props.dataList.map((e) => <span className={'datalist-item'} data-cy={"debounce-datalist-item"} key={e.value} onMouseDown={() => selectDropdownOption(e.value)} >{e.label}</span>)}
            </div>}

    </>
}

