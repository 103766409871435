import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "../components/footer";
import Navbar from "../components/navBar";
import { LoginOrSignupHeader } from "../components/loginHeader";
import { AccountSetting } from "./accountSetting";
import { ForgotPassword } from "./forgotpassword";
import { Login } from "./login";
import { ResetPassword } from "./resetpassword";
import { SearchPage } from "./search/views/generic";
import { SignupDetails } from "./signup/details";
import { SignupCountries } from "./signup/countries";
import { SignupKeywords } from "./signup/keywords";
import { OpportunitiesSearchPage } from "./search/views/opportunities";
import { ShortlistSearchPage } from "./search/views/shortlist";
import { AwardsSearchPage } from "./search/views/awards";
import { PageOrLogin } from "../components/pageOrLogin/PageOrLogin";
import { Snacc } from "../components/footer/Snacc";
import { CheckVersion } from "../hooks/version";
import { TenderDetailsView } from "./tenderDetails/main/TenderDetailsView";
import { Navigate } from "../components/navigate/Navigate";
import { ForwardToTender } from "../components/forwards/ForwardToTender";
import { Logout } from "../components/logout";
import { GeneralTermsAndConditionsPage } from "./policies/generalTermsAndConditions";
import { SignupStartDefault, SignupStartGovernment } from "./signup/start";
import { SignupAccreditations } from "./signup/accreditations";
import { CompanyPage } from "./company";
import { SignupSurvey } from "./signup/survey";
import { SignupCuratedProfiles } from "./signup/curatedprofiles";
import { UnsubscribePage } from "./unsubscribe";
import { SignupAccelerated } from "./signup/accelerated";
import { PrivacyPolicyPage } from "./policies/privacyPolicy";
import { RenewTrial } from "./accountSetting/plan/RenewTrial";
import { FrameworksSearchPage } from "./search/views/frameworks";
import {MarketIntelligence} from "./marketIntelligence";

export const LoginOrSignupLayout = ({
  children,
  showLanguagesDropdown = true,
  showWhatIsTenderwolfButton = false,
  showFollowYourCompetitorsButton = false,
}) => (
  <>
    <LoginOrSignupHeader showLanguagesDropdown={showLanguagesDropdown} />
    {children}
  </>
);

const EmptyLayout = ({ children }) => (
  <>
    <LoginOrSignupHeader showLanguagesDropdown={false}/>
    {children}
  </>
);

const LeftSidebarLayout = ({ children }) => (
  <>
    <Navbar/>
    <div className='container'>
     <div className='common-grid'>
        {children}
     </div>
    </div>
    <Footer/>
  </>
);

const NoSidebarLayout = ({ children }) => (
    <>
        <Navbar/>
        {children}
        <Footer/>
    </>
);

export default function Pages() {
    //this checks the application version and resets the storage if it has changed
    CheckVersion();
    return <div>
        {/*Snaccbar is used to display messages on any page*/}
        <Snacc/>
        <BrowserRouter>
            <Routes>
                <Route exact={true} path="/" element={<Navigate location={"/opportunities"}/>}/>
                <Route exact={true} path="/login" element={<LoginOrSignupLayout showLanguagesDropdown={true}><Login/></LoginOrSignupLayout>}/>
                <Route exact={true} path="/forgot-password" element={<LoginOrSignupLayout showLanguagesDropdown={true}><ForgotPassword/></LoginOrSignupLayout>}/>
                <Route exact={true} path="/reset-password" element={<LoginOrSignupLayout showLanguagesDropdown={true}><ResetPassword/></LoginOrSignupLayout>}/>
                <Route exact={true} path="/signup-start" element={<LoginOrSignupLayout showLanguagesDropdown={true}><SignupStartDefault/></LoginOrSignupLayout>}/>
                <Route exact={true} path="/signup-accelerated" element={<LoginOrSignupLayout showLanguagesDropdown={true}><SignupAccelerated/></LoginOrSignupLayout>}/>
                <Route exact={true} path="/signup-start-government" element={<LoginOrSignupLayout showLanguagesDropdown={true}><SignupStartGovernment/></LoginOrSignupLayout>}/>
                <Route exact={true} path="/signup-details" element={<PageOrLogin><LoginOrSignupLayout showLanguagesDropdown={false}><SignupDetails/></LoginOrSignupLayout></PageOrLogin>}/>
                <Route exact={true} path="/signup-countries" element={<PageOrLogin><LoginOrSignupLayout showLanguagesDropdown={false}><SignupCountries/></LoginOrSignupLayout></PageOrLogin>}/>
                <Route exact={true} path="/signup-survey/:uuid" element={<PageOrLogin><LoginOrSignupLayout showLanguagesDropdown={false}><SignupSurvey/></LoginOrSignupLayout></PageOrLogin>}/>
                <Route exact={true} path="/signup-curated-profiles" element={<PageOrLogin><LoginOrSignupLayout showLanguagesDropdown={false}><SignupCuratedProfiles/></LoginOrSignupLayout></PageOrLogin>}/>
                <Route exact={true} path="/signup-accreditations" element={<PageOrLogin><LoginOrSignupLayout showLanguagesDropdown={false}><SignupAccreditations/></LoginOrSignupLayout></PageOrLogin>}/>
                <Route exact={true} path="/signup-keywords" element={<PageOrLogin><LoginOrSignupLayout showLanguagesDropdown={false}><SignupKeywords/></LoginOrSignupLayout></PageOrLogin>}/>
                <Route exact={true} path="/tender/:uuid" element={<PageOrLogin><NoSidebarLayout><TenderDetailsView/></NoSidebarLayout></PageOrLogin>}/>
                <Route exact={true} path="/publications/:snid" element={<PageOrLogin><ForwardToTender/></PageOrLogin>}/>
                <Route exact={true} path="/account" element={<PageOrLogin><LeftSidebarLayout><AccountSetting/></LeftSidebarLayout></PageOrLogin>}/>
                <Route exact={true} path="/renew-trial" element={<PageOrLogin><RenewTrial/></PageOrLogin>}/>
                {/*<Route exact={true} path="/help-center" element={<PageOrLogin><LeftSidebarLayout><HelpCenter/></LeftSidebarLayout></PageOrLogin>}/>*/}
                {/*<Route exact={true} path="/help-center-details" element={<PageOrLogin><LeftSidebarLayout><HelpCenterDetails/></LeftSidebarLayout></PageOrLogin>}/>*/}
                {/*<Route exact={true} path="/pricing-card" element={<PageOrLogin><EmptyLayout><PricingCard/></EmptyLayout></PageOrLogin>}/>*/}
                <Route exact={true} path="/search" element={<PageOrLogin><LeftSidebarLayout><SearchPage/></LeftSidebarLayout></PageOrLogin>}/>
                <Route exact={true} path="/opportunities" element={<PageOrLogin><LeftSidebarLayout><OpportunitiesSearchPage/></LeftSidebarLayout></PageOrLogin>}/>
                <Route exact={true} path="/shortlist" element={<PageOrLogin><LeftSidebarLayout><ShortlistSearchPage/></LeftSidebarLayout></PageOrLogin>}/>
                <Route exact={true} path="/frameworks" element={<PageOrLogin><LeftSidebarLayout><FrameworksSearchPage/></LeftSidebarLayout></PageOrLogin>}/>
                <Route exact={true} path="/market-intelligence" element={<PageOrLogin><NoSidebarLayout><MarketIntelligence/></NoSidebarLayout></PageOrLogin>}/>
                <Route exact={true} path="/awards" element={<PageOrLogin><LeftSidebarLayout><AwardsSearchPage/></LeftSidebarLayout></PageOrLogin>}/>
                <Route exact={true} path="/company/:uuid" element={<PageOrLogin><NoSidebarLayout><CompanyPage government={false}/></NoSidebarLayout></PageOrLogin>}/>
                <Route exact={true} path="/government/:uuid" element={<PageOrLogin><NoSidebarLayout><CompanyPage government={true}/></NoSidebarLayout></PageOrLogin>}/>
                <Route exact={true} path="/logout" element={<Logout />}/>
                <Route exact={true} path="/terms-and-conditions" element={<LoginOrSignupLayout showLanguagesDropdown={true}><GeneralTermsAndConditionsPage/></LoginOrSignupLayout>}/>
                <Route exact={true} path="/privacy-policy" element={<LoginOrSignupLayout showLanguagesDropdown={true}><PrivacyPolicyPage/></LoginOrSignupLayout>}/>
                <Route exact={true} path="/unsubscribe/:uuid" element={<UnsubscribePage />}/>
            </Routes>
        </BrowserRouter>
    </div>

}
