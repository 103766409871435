import {
    useTranslation,
} from "react-i18next";
import {
    useNavigate,
} from "react-router-dom";
import { 
    getProgressBarPercentage, 
} from "../../getProgressBarPercentage";


export const useSharedProjectsProgressCircle = (
    companyName: string,
    originalCompanyName: string,
    sharedProjects: string[],
) => {
    const {t} = useTranslation();
    const navigate = useNavigate();


    const navigateToSharedTenders = () => {
        let customFilter = originalCompanyName + " " + t("publications.and") + " " + companyName;
        navigate("/search?custom_filter="+customFilter+"&tender_uuid_filters=" + sharedProjects?.join(","));
    }

    const topText = t("companyPage.direct_competitor_title_1");
    const middleText = sharedProjects.length + "";
    const bottomText = t("companyPage.direct_competitor_title_2");

    const progressPercentage = getProgressBarPercentage(sharedProjects.length)

    return {
        navigateToSharedTenders,
        topText,
        middleText,
        bottomText,
        progressPercentage,
    }
}

