import { FetchArgs } from "@reduxjs/toolkit/dist/query";
import { S3FileDocument, Tender } from "../../types/tender";
import { createSlice } from "@reduxjs/toolkit";
import { apiSlice } from "./apiSlice";
import {SearchResponse, TenderSearchHit, TenderSearchInput} from "./tenderSearchSlice";
import {CompanyDto, CompetitorDto, RecentTenderDto} from "./companySlice";

/*
  REDUX STORE stuff
*/

export interface tenderViewState {
    tenderLanguage: string;
    localeLanguage: string;
}

const initialState: tenderViewState = {
    tenderLanguage: "NL",
    localeLanguage: "NL",
};

export const tenderDetailsSlice = createSlice({
    initialState,
    name: "details",
    reducers: {},
});

/*
  API connection stuff
*/
export type GetDetailsParams = {
    uuid: string;
    tenderLanguage?: string;
};

export type GetMatchesParams = {
    uuid: string;
};

type MatchOutput = {
    tenderUuid: string;
    subjectCodes: string[];
    regionCodes: string[];
    accreditations: string[];
    highlights: string[];
}

export type PostDownloadFileParams = {
    tenderUuid: string;
    file: S3FileDocument;
};
export type PostDownloadArchiveParams = {
    tenderUuid: string;
    files: S3FileDocument[];
    archiveFileName: string;
};

export type CreateBesixProjectInput = {
    tenderUuid: string;
    userUuid: string;
}

export type BesixProjectStatusOutput = {
    addNewProjectButton: boolean;
    url: string;
}

export type AcceleratedSignupInput = {
    tenderUuid: string;
    companyUuid?: string;
    languageIso: string;
    competitorsToFetch?: number;
};

export interface AcceleratedSignupCompetitorDto{
//OrganizationDto company, int sharedProjects, List<TenderDocumentDto> recentTenders
    company: CompanyDto;
    sharedProjects: number;
    recentTenders: Tender[];
}

export type AcceleratedSignupOutput = {
    tender: Tender,
    similarTenders: SearchResponse,
    competitors: AcceleratedSignupCompetitorDto[],
}

const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (build) => ({
        getTenderDetails: build.query<Tender, GetDetailsParams>({
            query: (params: GetDetailsParams) => {
                let args: FetchArgs = {
                    url: `/shaggy/tenders/${params.uuid}`,
                };
                return args;
            },
            providesTags: ["Details"],
        }),
        getTenderPreview: build.query<AcceleratedSignupOutput, AcceleratedSignupInput>({
            query: (params: AcceleratedSignupInput) => {
                let args: FetchArgs = {
                    url: `/shaggy/signup/accelerated-signup?tenderUuid=${params.tenderUuid}&companyUuid=${params.companyUuid ? params.companyUuid :  ''}&languageIso=${params.languageIso}`,
                };
                return args;
            }
        }),
        getMatches: build.query<MatchOutput, GetMatchesParams>({
            query: (params: GetDetailsParams) => {
                let args: FetchArgs = {
                    url: `/shaggy/tenders/${params.uuid}/matches`,
                };
                return args;
            },
        }),
        postDownloadFile: build.mutation<any, PostDownloadFileParams>({
            query: (params: PostDownloadFileParams) => {
                return {
                    url: `/shaggy/tenders/${params.tenderUuid}/files`,
                    method: "POST",
                    body: params.file,
                    responseHandler: response => response.blob().then(response => {
                        let link = document.createElement("a");
                        link.target = "_blank";
                        //the s3 key is used because sometimes the file is inside a zip
                        link.download = params.file.s3Key != null ? params.file.s3Key.split("/")[params.file.s3Key.split("/").length-1] : "tw_file"
                        link.href = URL.createObjectURL(response);
                        link.click();
                    })
                };
            },
        }),
        postDownloadArchive: build.mutation<any, PostDownloadArchiveParams>({
            query: (params: PostDownloadArchiveParams) => {
                return {
                    url: `/shaggy/tenders/${params.tenderUuid}/archive`,
                    method: "POST",
                    body: {files: params.files},
                    responseHandler: response => response.blob().then(response => {
                        let link = document.createElement("a");
                        link.target = "_blank";
                        link.download = params.archiveFileName;
                        link.href = URL.createObjectURL(response);
                        link.click();
                    })
                };
            },
        }),
        addBesixProject: build.mutation<BesixProjectStatusOutput, CreateBesixProjectInput>({
            query: (params: CreateBesixProjectInput) => {
                let args: FetchArgs =  {
                    url: `/shaggy/besix_force/new_project`,
                    method: "POST",
                    body: params,
                };
                return args;
            },
        }),
        getBesixProjectStatus: build.query<BesixProjectStatusOutput, CreateBesixProjectInput>({
            query: (params: CreateBesixProjectInput) => {
                let args: FetchArgs = {
                    url: `/shaggy/besix_force/projects/${params.tenderUuid}`,
                };
                return args;
            },
        }),
    })
});

export const {endpoints, useGetTenderDetailsQuery, useGetTenderPreviewQuery, useGetMatchesQuery, usePostDownloadArchiveMutation,
    usePostDownloadFileMutation,
    useAddBesixProjectMutation, useGetBesixProjectStatusQuery} = extendedApiSlice;
