import styles from "./index.module.scss";
import {CloseIcon} from "../icons";
import React from "react";

export interface FilterButtonProps {
    text: string;
    close?: () => void;
}

export const FilterButton: React.FC<FilterButtonProps> = ({text, close}) => {
    return <div className={styles["filter-button"]}>
        <div>{text}</div>
        {close != null && <div onClick={close}><CloseIcon/></div>}
    </div>
}