import React, {useEffect, useState} from "react";
import {Form} from "../form/Form";
import {SearchIcon} from "../icons";
import styles from "./index.module.scss";

export interface QueryInputProps{
    query: string;
    queryPlaceholder: string;
    isLoading: boolean;
    updateQueryAndSearch: (query: string) => void;
}

export const QueryInput: React.FC<QueryInputProps> = (props) => {
    const [localQuery, setLocalQuery] = useState(props.query);
    const submit = () => props.updateQueryAndSearch(localQuery);
    useEffect(() => {
        setLocalQuery(props.query);
    }, [props.query]);
    return <Form onSubmit={submit}>
            <div className={styles['search-input']}>
                {props.isLoading && <input type="text" maxLength={255} defaultValue={"..."}/>}
                {!props.isLoading && <input type="text"
                                            maxLength={255}
                                            placeholder={props.queryPlaceholder}
                                            onChange={(e) => setLocalQuery(e.currentTarget.value)}
                                            value={localQuery}
                                            data-testid={"sidebar-search-input"}/>}
                <div className={styles['search-icon-alignment']} onClick={submit}>
                    <SearchIcon/>
                </div>
            </div>
        </Form>

}