import React 
  from "react";
import styles 
  from "./index.module.scss";
import { 
    useSharedProjectsWonProgressCircle, 
} from "./useSharedProjectsWonProgressCircle";
import { 
    ProgressCircle, 
} from "../../../progressCircle";
import SearchIcon 
  from "../../../../assets/icons/search.svg";

export interface SharedProjectsWonProgressCircleProps {
    companyName: string;
    originalCompanyName: string;
    sharedProjectsWon: string[];
}
export const SharedProjectsWonProgressCircle: React.FC<SharedProjectsWonProgressCircleProps> = ({
    companyName,
    originalCompanyName,
    sharedProjectsWon,
}) => {
    const {
        navigateToSharedTenders,
        topText,
        middleText,
        bottomText,
        progressPercentage,
    }=useSharedProjectsWonProgressCircle(
        companyName,
        originalCompanyName,
        sharedProjectsWon,
    );

    return (<div 
        className={`
            ${styles['progress-circle-container']}
            ${styles['pointer-on-hover']}
        `} 
        onClick={navigateToSharedTenders}
    >
        <p className={styles['top-text']}>{topText}</p>
        <ProgressCircle 
            progressPercentage={progressPercentage} 
            size={80} 
            stroke={6} 
            text={<>
                <p className={styles['middle-text']}>{middleText}</p>
                <img 
                    src={SearchIcon} 
                    alt="search icon" 
                    className={styles['search-icon-on-hover']    
                }/>
            </>}
        />
        <p className={styles['bottom-text']}>{bottomText}</p>
    </div>);
}

