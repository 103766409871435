import React from 'react';
import styles from './index.module.scss';


export interface SubTabProps{
    isActive?: boolean;
    onClick?: ()=> void;
}
export const SubTab: React.FC<SubTabProps> = ({
    children,

    isActive = false,
    onClick = ()=>{},
}) => {
    return (<div 
        className={`
            ${styles['container']}
            ${isActive ? styles['active'] : ''}
        `}
        onClick={onClick}
    >
        {children}
    </div>);
}