import React, {useEffect, useState} from "react";
import "./contractingAuthorities.scss";
import { useTranslation } from "react-i18next";
import { SaveButton } from "../../../components/saveButton/SaveButton";
import { SavedSearchDetailsTabProps } from "../index";
import { CompanyDropdown } from "../../../components/dropdown";
import {
  CompanySuggestion,
  CompanySuggestionsSource, GetCompaniesByVatNumberInParams,
  useGetCompaniesByVatNumberInQuery
} from "../../../hooks/slices/companySlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {MultilingualFieldUtil} from "../../../utils/multilingualfield";
import {
  CompanyIcon1,
  CompanyIcon2, CompanyIcon21,
  CompanyIcon3,
  GovernmentIcon, HospitalIcon,
  NavigateIcon, SchoolIcon,
  TrashIcon
} from "../../../components/icons";
import {CompanyUtil} from "../../../utils/company";
import {useNavigate} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";
import {FADE_IN_BOTTOM} from "../../../consts/FramerMotionVariants";
import {SkeletonSection} from "../../tenderDetails/main/TenderDetailsView";
import {NameBasedCompanyIcon} from "../../../components/icons/NameBasedCompanyIcon";

export const ContractingAuthoritiesPage: React.FC<SavedSearchDetailsTabProps> = ({
  userUuid,
  savedSearch,
  updateSavedSearch,
  setUnsavedChanges,
  isLoading,
}) => {
  const { t } = useTranslation();
  const [contractingAuthorities, setContractingAuthorities] = useState<CompanySuggestion[]>([]);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  let query: GetCompaniesByVatNumberInParams | undefined = {
    vatNumbers: savedSearch.contractingAuthorityVatNumbers ?? [],
    source: CompanySuggestionsSource.COMPANY_PAGE_BUYERS, page: 0, pageSize: savedSearch.contractingAuthorityVatNumbers?.length ?? 0
  }; if (savedSearch.contractingAuthorityVatNumbers == null || savedSearch.contractingAuthorityVatNumbers.length === 0){
    query = undefined; //this makes sure the endpoint isn't called unnecessarily
  }
  const {data, isLoading: fetchingCompanies, isSuccess, error} = useGetCompaniesByVatNumberInQuery(query ?? skipToken);

  useEffect(() => {
    if (isSuccess && data) {
      setContractingAuthorities(CompanyUtil.sortCompanySuggestionsByName(data.suggestions));
    }
  }, [data, fetchingCompanies, isSuccess, error]);

  const updateContractingAuthorities = (): boolean => {
    if (savedSearch.uuid) {
      updateSavedSearch({
        userUuid: userUuid,
        name: savedSearch.name,
        contractingAuthorityVatNumbers: contractingAuthorities.map(c => c.vatNumber)
      });
      setUnsavedChanges(false);
      setDisableSaveButton(true);
      return true;
    } else return false;
  };
  const autocomplete = (company: CompanySuggestion) => {
    if (contractingAuthorities.filter(c => c.vatNumber === company.vatNumber).length === 0){
      setContractingAuthorities(CompanyUtil.sortCompanySuggestionsByName([...contractingAuthorities, company]));
      setUnsavedChanges(true);
      setDisableSaveButton(false);
    }
  };
  const removeCard = (vatNumber: string) => {
    setContractingAuthorities(contractingAuthorities.filter(ca => ca.vatNumber !== vatNumber));
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  }
  return (
    <>
      <div>
        <div className="general-settings-section">
          <div className="company-dropdown">
            <h1>{t("savedSearchSettings.contractingAuthorities")}</h1>
            <CompanyDropdown
              placeholder={""}
              autocomplete={autocomplete}
              onChange={() => {}}
              companySelected={false}
              reset={() => {}}
              source={CompanySuggestionsSource.COMPANY_PAGE_BUYERS}
              eraseInputOnAutoComplete={true}
            />
          </div>
          {isLoading && <ListSkeleton amount={contractingAuthorities.length}></ListSkeleton>}
          {!isLoading &&<div className={"selected-companies-list"}>
              <ul>
                {contractingAuthorities.map((c) => (
                  <AnimatePresence>
                    <ContractingAuthorityCard contractingAuthority={c} language={"nl"} removeCard={removeCard} />
                  </AnimatePresence>
                ))}
              </ul>
          </div>}
        </div>
      </div>
      <div className={"fill-button"} style={{ marginTop: "24px" }}>
        <SaveButton saving={isLoading} save={updateContractingAuthorities} disabled={disableSaveButton} />
      </div>
    </>
  );
};

interface ContractingAuthorityCardProps {
    contractingAuthority: CompanySuggestion;
    language: string;
    removeCard: (vatNumber: string) => void;
}

const ContractingAuthorityCard: React.FC<ContractingAuthorityCardProps> = ({contractingAuthority, language, removeCard}) => {
  const navigate = useNavigate();
  const [showButtons, setShowButtons] = useState(false);
  let {t} = useTranslation();
  return <motion.li key={contractingAuthority.vatNumber}
                    onMouseEnter={() => setShowButtons(true)}
                    onMouseLeave={() => setShowButtons(false)}
                    whileHover={{ scale: 1.01 }}
                    variants={FADE_IN_BOTTOM} initial="hidden" animate="visible" exit="hidden"
  >
    <span className={"image-icon-span"}>
      <NameBasedCompanyIcon companyName={MultilingualFieldUtil.translate(contractingAuthority.name, "nl")}/>
      <p>{MultilingualFieldUtil.translate(contractingAuthority.name, language)}</p>
    </span>
    <motion.span className={"image-buttons-span"}>
      {showButtons && <>
        {/*todo navigate uuids are broken?*/}
        <span onClick={() => navigate("/company/" + contractingAuthority.uuid)} title={t("settings.navigateToCompanyPage")}><NavigateIcon/></span>
        <span onClick={() =>removeCard(contractingAuthority.vatNumber)} title={t("settings.delete")}><TrashIcon/></span>
      </>}
    </motion.span>
  </motion.li>
}

const ListSkeleton: React.FC<({amount: number})> = ({amount}) => {
  return <div className={'list-skeleton'}>
    <ul>
      {Array.from({ length: amount }).map((_, i) => (
          <li key={i}>
            <SkeletonSection width={'90%'} height={50} />
          </li>
      ))}
    </ul>
  </div>
}


