import React, { useEffect, useState } from "react";
import "./keywords.scss";
import { useTranslation } from "react-i18next";
import { Language } from "../../../consts/languages";
import {AddListIcon, DownloadIcon, PlusAltIcon, PlusIcon, ResetIcon, TrashIcon} from "../../../components/icons";
import { showCustomErrorPopup } from "../../../hooks/slices/snaccSlice";
import { useAppDispatch } from "../../../app/hooks";
import { FeatureUtil } from "../../../utils/features";
import { RaiseCurrentPlan } from "../../../hooks/raiseCurrentPlan";
import { SaveButton } from "../../../components/saveButton/SaveButton";
import { SavedSearchDetailsTabProps } from "../index";
import {SavedSearchUtil} from "../../../utils/savedsearches";
import {TFunction} from "i18next";

export const KeyWordsPage: React.FC<SavedSearchDetailsTabProps> = ({
  savedSearch,
  updateSavedSearch,
  setUnsavedChanges,
  userUuid,
  isLoading,
}) => {
  const dispatch = useAppDispatch();
  let { t } = useTranslation();
  const featuresInStore = RaiseCurrentPlan().currentPlan?.features;
  const relevantLanguages: Language[] = FeatureUtil.getRelevantLanguagesFromFeatures(featuresInStore).sort((a, b) =>
    t("languages." + a).localeCompare(t("languages." + b))
  );
  const [originalCues, setOriginalCues] = useState<string[]>([...SavedSearchUtil.getCues([savedSearch]), ""]); //the "" is the new cue input
  const [updatedCues, setUpdatedCues] = useState<string[]>(originalCues);
  const [selectedLanguages, setSelectedLanguages] = useState<string[]>(
    savedSearch.languageIsos ? savedSearch.languageIsos : []
  );
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [showBatchInsert, setShowBatchInsert] = useState(false);
  const languageIsSelected = (languageIso: string) => {
    return selectedLanguages.includes(languageIso);
  };
  const allRelevantLanguagesAreSelected = () => {
    //sort() both returns and sorts the original list otherwise ..
    return [...relevantLanguages].sort().join(",") === [...selectedLanguages].sort().join(",");
  };
  const removeCue = (index: number) => {
    let newCues = [...updatedCues];
    newCues.splice(index, 1);
    setOriginalCues(newCues);
    setUpdatedCues(newCues);
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  const updateCue = (index: number, value: string) => {
    let newCues: string[] = Array.from(updatedCues);
    newCues[index] = value;
    setUpdatedCues(newCues);
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  const batchInsert = (values: string[]): boolean => {
    let newCues: string[] = [...Array.from(updatedCues).filter((c) => c !== "")];
    values.forEach((v) => {
      if (!newCues.includes(v)) newCues.push(v);
    }); //remove duplicates
    newCues.push("");
    let exception = validate(newCues, t);
    if (exception){
      dispatch(showCustomErrorPopup(exception));
      return false;
    } else {
      setOriginalCues(newCues);
      setUpdatedCues(newCues);
      setShowBatchInsert(false);
      return persistCues(newCues);
    }
  };

  const persistCues = (cues: string[]): boolean => {
    let exception = validate(cues, t);
    if (exception) {
      dispatch(showCustomErrorPopup(exception));
      return false;
    } else if (savedSearch.uuid) {
      updateSavedSearch({
        userUuid: userUuid,
        uuid: savedSearch.uuid,
        name: savedSearch.name,
        query: SavedSearchUtil.getCuesAsQuery(cues.filter((c) => c !== "")),
        languageIsos: selectedLanguages,
      });
      //update state
      setOriginalCues(cues);
      //communicate changes to save button
      setUnsavedChanges(false);
      setDisableSaveButton(true);
      return true;
    } else return false;
  };

  const selectAllLanguages = () => {
    setSelectedLanguages(relevantLanguages);
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  const deselectAllLanguages = () => {
    setSelectedLanguages([]);
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  const toggleAllLanguages = () => {
    if (allRelevantLanguagesAreSelected()) deselectAllLanguages();
    else selectAllLanguages();
  };
  const selectLanguage = (languageIso: string) => {
    setSelectedLanguages([...selectedLanguages, languageIso]);
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  const deselectLanguage = (languageIso: string) => {
    setSelectedLanguages(selectedLanguages.filter((l) => l !== languageIso));
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  const toggleLanguage = (language: Language) => {
    if (languageIsSelected(language)) deselectLanguage(language);
    else selectLanguage(language);
  };
  const addEmptyInput = () => {
    if (!updatedCues.includes("")){
      setOriginalCues([...updatedCues, ""]);
      setUpdatedCues([...updatedCues, ""]);
    }
  };
  const downloadKeywords = () => {
    if (originalCues.length > 0) {
      const blob = new Blob([originalCues.join("\n")], { type: "text/plain" });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = t("savedSearchSettings.keyWords").toLowerCase() + ".txt";
      a.click();
    }
  };
  const resetKeywords = () => {
    setOriginalCues([""]);
    setUpdatedCues([""]);
    setUnsavedChanges(true);
    setDisableSaveButton(false);
  };
  return (
    <>
      <div>
        <div className="subtitle">
          <label>{t("detailsSideBar.languages")}</label>
        </div>
        <div className={"language-checkboxes"}>
          <div className={"checkbox-button-relative"}>
            <div key={"ALL"}>
              <input
                type="checkbox"
                id={"ALL"}
                name="checkBox"
                onChange={toggleAllLanguages}
                checked={allRelevantLanguagesAreSelected()}
              />
              <label htmlFor={"ALL"}>{t("languages.ALL")}</label>
            </div>
            {relevantLanguages.map((l) => (
              <>
                <div key={l.toUpperCase()}>
                  <input
                    type="checkbox"
                    id={l}
                    name="checkBox"
                    onChange={() => toggleLanguage(l)}
                    checked={languageIsSelected(l)}
                  />
                  <label htmlFor={l}>{t("languages." + l)}</label>
                </div>
              </>
            ))}
          </div>
        </div>
        <div className="subtitle-with-buttons">
          <label>{t("savedSearchSettings.keyWords")}</label>
          <div style={{ display: "flex", justifyContent: "end", flexDirection: "row", padding: "4px" }}>
            <div className={"large-button"}
                onClick={() => setShowBatchInsert(!showBatchInsert)}
                title={t("savedSearchSettings.batchInsertKeyWords")}>
              <AddListIcon />
            </div>
            <div
              onClick={resetKeywords}
              title={t("savedSearchSettings.resetKeyWords")}
              className={originalCues.length > 1 ? "" : "disabled"}
            >
              <ResetIcon />
            </div>
            <div
              onClick={downloadKeywords}
              title={t("savedSearchSettings.copyKeyWords")}
              className={originalCues.length > 1 ? "" : "disabled"}
            >
              <DownloadIcon />
            </div>
          </div>
        </div>
        {showBatchInsert && (
          <div className="keyword-section-alignment">
            <CueBatchInput batchInsert={batchInsert} isLoading={isLoading} />{" "}
          </div>
        )}
        {!showBatchInsert && (
          <div className="keyword-section-alignment">
            {originalCues.map((c, i) => (
              <div className="grid" key={i + "-" + c}>
                <div className="grid-items">
                  <CueInput
                    cue={c}
                    replaceCue={(v) => updateCue(i, v)}
                    removeCue={() => removeCue(i)}
                    isValid={(c) => cueIsValid(c, updatedCues)}
                    addEmptyInput={() => addEmptyInput()}
                  />
                </div>
                {i === updatedCues.length - 1 && (
                  <div className={"add-input-button"} onClick={() => addEmptyInput()}>
                    <PlusIcon />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
      {!showBatchInsert && (
        <div className={"fill-button"}>
          <SaveButton saving={isLoading} save={() => persistCues(updatedCues)} disabled={disableSaveButton} />
        </div>
      )}
    </>
  );
};

interface CueProps {
  cue: string;
  replaceCue: (value: string) => void;
  removeCue: () => void;
  isValid: (cue: string) => boolean;
  addEmptyInput: () => void;
}

export const CueInput: React.FC<CueProps> = ({ cue, replaceCue, removeCue, isValid, addEmptyInput }) => {
  let { t } = useTranslation();
  const [oldCue, setOldCue] = useState<string>(cue);
  const [localCue, setLocalCue] = useState<string>(cue);
  const [hasBeenPersisted, setHasBeenPersisted] = useState(cue !== "");
  const focus = localCue === "";
  const toggleLocalCueValue = (v: string) => {
    if (v !== localCue) {
      setLocalCue(v);
    }
  };
  const changeCue = () => {
    if (oldCue !== localCue) {
      replaceCue(localCue);
    }
  };
  const changeCueAndAddEmptyInput = (cue: string) => {
    toggleLocalCueValue(cue);
    replaceCue(cue);
    addEmptyInput();
  }
  useEffect(() => {
    const getData = setTimeout(() => {
      changeCue();
    }, 100);
    return () => clearTimeout(getData);
  }, [localCue]);

  return (
    <>
      <div className={isValid(localCue) ? "key-input valid" : "key-input invalid"}>
        <input type="text"
          maxLength={255}
          autoFocus={focus}
          placeholder={t("savedSearchSettings.newKeyword")}
          defaultValue={localCue}
          onKeyDown={(e) => {if(e.key === "Enter") changeCueAndAddEmptyInput(e.currentTarget.value)}}
          onChange={(e) => {
            toggleLocalCueValue(e.currentTarget.value);
          }}
          data-cy={"user-profile-details-keyword-input"}
        />
        {hasBeenPersisted && (
          <div className="button-right-alignment" onClick={() => removeCue()}>
            <TrashIcon />
          </div>
        )}
      </div>
    </>
  );
};

export const CueBatchInput: React.FC<{ batchInsert: (values: string[]) => boolean; isLoading: boolean }> = ({batchInsert, isLoading}) => {

  const [localCue, setLocalCue] = useState<string>("");
  let {t} = useTranslation();
  const toggleLocalCueValue = (v: string) => {
    if (v !== localCue) {
      setLocalCue(v);
    }
  }
  const insertCues = (): boolean => {
    let cues: string[] = localCue.split(/\n/).map(c => c.trim()).filter((c) => c !== "")
    return batchInsert(cues);
  }

  return <>
    <div className={'batch-input'}>
            <textarea autoFocus={true}
                      placeholder={t("savedSearchSettings.pasteKeywords")}
                      defaultValue={localCue}
                      onChange={(e) => {
                        toggleLocalCueValue(e.currentTarget.value);
                      }} data-cy={"user-profile-details-keyword-input"}
            />
      <div className={"fill-button"}>
        <SaveButton saving={isLoading} save={insertCues} disabled={localCue === ""}/>
      </div>

    </div>
  </>
}

function countCue (cue: string, cues: string[])  {
  let count = 0;
  let cueSlug = cue.trim().toLowerCase();
  for (const slug of cues.map((c) => c.trim().toLowerCase())) {
    if (cueSlug === slug) count = count + 1;
  }
  return count;
}

function cueIsUnique (cue: string, cues: string[]): boolean  {
  if (cue.trim() !== "" && countCue(cue, cues) > 1) return false; //ignores empty cues
  return true;
}

function cueContainsIllegalQuotes (cue: string): boolean  {
  return /[‘’‛′“”„«»]/.test(cue);
}

function cueIsValid (cue: string, cues: string[]): boolean  {
  return cueIsUnique(cue, cues) && !cueContainsIllegalQuotes(cue);
}


function validate(cues: string[], t: TFunction): string | undefined {
  let trimmedCues = cues.map(c => c.trim().toLowerCase());
  let distinctTrimmedCues = Array.from(new Set(trimmedCues));
  if (cues.length > 500) {
    return t("savedSearchSettings.tooManyKeywords");
  }
  if (trimmedCues.length !== distinctTrimmedCues.length) {
        return t("savedSearchSettings.duplicateKeywords");
  }
  for (const cue of cues) {
        if (cueContainsIllegalQuotes(cue)) {
            return t("savedSearchSettings.illegalQuotesInKeywords");
        } else if(cue.length > 255) {
            return t("savedSearchSettings.keywordTooLong");
        }
    }
}

