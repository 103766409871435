import React 
  from 'react';
import { 
    useTranslation,
} from 'react-i18next';
import styles 
  from './index.module.scss';
import {
    useFollowOrUnfollowCompany,
} from './useFollowOrUnfollowCompany';
import { 
    StarFillIcon, 
    StarIcon,
} from "../icons";
import { 
    CompanyDto,
} from '../../hooks/slices/companySlice';


export interface FollowOrUnfollowCompanyProps {
    userUuid: string | undefined;
    companyIsFollowed: boolean;
    company: CompanyDto;
}
export const FollowOrUnfollowCompany: React.FC<FollowOrUnfollowCompanyProps> = ({
    userUuid,
    companyIsFollowed,
    company,
}) => {
    let {t} = useTranslation();

    return (<div className={styles['follow-button']}
        title={t("companyPage.follow_company")}
        onClick={useFollowOrUnfollowCompany(
            userUuid, 
            companyIsFollowed, 
            company,
        )}
    >
        {companyIsFollowed && <StarFillIcon/>}
        {!companyIsFollowed && <StarIcon/>}
    </div>);
}