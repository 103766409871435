
import React from 'react';
import styles from './index.module.scss';

export interface ButtonGroupProps extends React.HTMLAttributes<HTMLDivElement> {
}
export const ButtonGroup: React.FC<ButtonGroupProps> = ({ 
    children, 
    ...props
 }) => {
    return (<div
        className={styles['button-group']}
        {...props}
    >
        { children } 
    </div>);
};
