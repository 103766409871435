import React from "react";
import {Moment} from "moment";
import {TenderSearchHit} from "../../../../hooks/slices/tenderSearchSlice";
import {useTranslation} from "react-i18next";
import {PublicationInformation, Tender} from "../../../../types/tender";
import {TFunction} from "i18next";
import {DateUtil} from "../../../../utils/date";
import {TenderUtil} from "../../../../utils/tenders";
import {motion} from "framer-motion";
import {useMediaQuery} from "react-responsive";


interface ProgressBarProps {
    tenderSearchHit: TenderSearchHit;
    showDeadline: boolean;
    showEstimatedRenewalDate: boolean;
}

const maxProgressBarElements: number = 8;

enum ProgressBarCircleType {
    TEAL, WHITE, RED, ORANGE_DASHED
}

enum EventType{
    PUBLICATION, DEADLINE, TODAY, RENEWAL_DATE, RENEWAL_PUBLICATION_DATE
}

interface SortedPublicationEvent{
    date: Moment;
    priority?: number;
    info?: PublicationInformation;
    eventType: EventType;
}

export const ProgressBar: React.FC<ProgressBarProps> = ({
  tenderSearchHit,
  showDeadline,
  showEstimatedRenewalDate,
}) => {
  let { t } = useTranslation();
  let progressBarElements: ProgressBarElement[] = getProgressBarElements(
    tenderSearchHit.tender,
    showDeadline,
    showEstimatedRenewalDate,
    t
  );
  const isMobile = useMediaQuery({ maxWidth: 600 });
  if (isMobile && progressBarElements.length > 3) {
    //grab first and last two elements, if present
    let limitedProgressBarElements = [progressBarElements[0]];
    limitedProgressBarElements.push(progressBarElements[progressBarElements.length - 2]);
    limitedProgressBarElements.push(progressBarElements[progressBarElements.length - 1]);
    progressBarElements = limitedProgressBarElements;
  }
  return (
    <>
      <div className="main-timeline-content-alignment">
        <div className={"line-background"}></div>
        <div className="events-box">
          {progressBarElements.map((p, i) => (
            <>
              <ProgressBarTransparentLine
                widthPercentage={p.lineProps.widthPercentage}
                keyString={tenderSearchHit.tender.uuid + "-line-" + i}
              />
              <ProgressBarCircle
                width={p.circleProps.width}
                type={p.circleProps.type}
                topText={p.circleProps.topText}
                bottomText={p.circleProps.bottomText}
                keyString={tenderSearchHit.tender.uuid + "-circle-" + i}
                title={p.circleProps.title}
              />
            </>
          ))}
          <ProgressBarTransparentLine widthPercentage={10} keyString={tenderSearchHit.tender.uuid + "-line-end"}/>
        </div>
      </div>
    </>
  );
};

interface ProgressBarElement {
  circleProps: ProgressBarCircleProps;
  lineProps: ProgressBarLineProps;
}

function getEstimatedRenewalDate(tender: Tender): Moment | undefined {
    if (tender.estimatedRenewalDate) {
        return DateUtil.getDateMoment(tender.estimatedRenewalDate);
    } return undefined;
}

function getProgressBarElements(tender: Tender,
                                addDeadline: boolean,
                                addEstimatedRenewalDate: boolean, t: TFunction): ProgressBarElement[] {
    let elements: ProgressBarElement[] = [];
    let sortedPublications: TenderUtil.SortedPublicationCardProps[] = TenderUtil.getPublicationInformationsSorted(tender);
    sortedPublications = sortedPublications.slice(sortedPublications.length- maxProgressBarElements, sortedPublications.length);
    let today = DateUtil.today();

    let deadlineDateString = DateUtil.convertDateTimeStringToDateString(tender.deadline);
    let deadlineDate: Moment | undefined = deadlineDateString ? DateUtil.getDateMoment(deadlineDateString) : undefined;

    let tenderPublicationDate: Moment = DateUtil.getDateMoment(tender.publicationDate);
    let totalDays = getTotalDays(tender, deadlineDate, tenderPublicationDate, sortedPublications);

    let estimatedRenewalDate: Moment | undefined = getEstimatedRenewalDate(tender);

    let sortedPublicationEvents: SortedPublicationEvent[] = sortedPublications
        .map(p => ({
            date: DateUtil.getDateMoment(p.originalVersionDate ?? p.publicationInformation.publicationDate),
            info: p.publicationInformation,
            priority: p.priority,
            eventType: EventType.PUBLICATION}));
    sortedPublicationEvents.push({date: today, eventType: EventType.TODAY});
    if(addDeadline && deadlineDate) sortedPublicationEvents.push({date: deadlineDate, eventType: EventType.DEADLINE, priority: 1}); //priority makes sure deadline is before openingreport
    if(addEstimatedRenewalDate && estimatedRenewalDate){
        let estimatedRenewalPublicationDate: Moment = estimatedRenewalDate.clone().subtract(3, 'months');
        sortedPublicationEvents.push({date: estimatedRenewalPublicationDate, eventType: EventType.RENEWAL_PUBLICATION_DATE, priority: 1});
        sortedPublicationEvents.push({date: estimatedRenewalDate, eventType: EventType.RENEWAL_DATE, priority: 1});
    }
    sortedPublicationEvents.sort(function (a, b) {
        if (a.date.isSame(b.date, 'day') && a.priority !== undefined && b.priority !== undefined) {
            // Priority is only important when dates are the same
            return a.priority > b.priority ? -1 : 1;
        } return a.date > b.date ? -1 : 1;
    }).reverse();

    let dateCounter: Moment | undefined = undefined;
    let eventPriorityCounter: number | undefined = undefined;
    // let tenderLastDay: Moment = getTenderLastDay(deadlineDate, tenderPublicationDate, sortedPublications);
    for (const event of sortedPublicationEvents) {
        let eventDate = event.date;
        let daysBetween = getDaysBetween(dateCounter, eventDate);
        let percentageOfTotalDays = getPercentageOfTotalDaysBetween(dateCounter, eventDate, totalDays);
        //ignore two events on same day if they have the same priority (esp rectifications)
        if (daysBetween === 0 && event.priority === eventPriorityCounter) continue;
        let newElement: ProgressBarElement | undefined = getProgressBarElement(event, percentageOfTotalDays, t);
        if (newElement) elements.push(newElement)
        dateCounter = eventDate;
        eventPriorityCounter = event.priority;
    }

    return elements;
}

function getTotalDays(tender: Tender, deadlineDate: Moment | undefined, tenderPublicationDate: Moment, sortedPublications: TenderUtil.SortedPublicationCardProps[]): number {
    let tenderLastDay = getTenderLastDay(tender, deadlineDate, tenderPublicationDate, sortedPublications);
    if (tenderLastDay.isAfter(DateUtil.today())) return tenderLastDay.diff(tenderPublicationDate, 'days');
    else return DateUtil.today().diff(tenderPublicationDate, 'days');
}

function getTenderLastDay(tender: Tender, deadlineDate: Moment | undefined, tenderPublicationDate: Moment, sortedPublications: TenderUtil.SortedPublicationCardProps[]): Moment {
    let renewalDate = getEstimatedRenewalDate(tender);
    if (renewalDate) return renewalDate;
    if (deadlineDate) return deadlineDate;
    else if (sortedPublications.length > 0) DateUtil.getDateMoment(sortedPublications[sortedPublications.length - 1].publicationInformation.publicationDate);
    return tenderPublicationDate;
}

function getDaysBetween(a: Moment | undefined, b: Moment | undefined): number | undefined {
    if (a && b) return b.diff(a, 'days');
    else return undefined;
}

function getPercentageOfTotalDaysBetween(a: Moment | undefined, b: Moment | undefined, totalDays: number | undefined): number {
    let daysBetween = getDaysBetween(a, b);
    if (daysBetween && totalDays) return (100 / totalDays * daysBetween);
    else return 5; //5% as default width
}

function getProgressBarElement(event: SortedPublicationEvent, percentageOfTotalDays: number, t: TFunction): ProgressBarElement | undefined{

    if (event.eventType ===  EventType.TODAY) return todayProgressBarElement(percentageOfTotalDays, t);
    else if (event.eventType ===  EventType.DEADLINE) return deadlineProgressBarElement(t, event.date, percentageOfTotalDays);
    else if (event.eventType ===  EventType.RENEWAL_PUBLICATION_DATE) return renewalPublicationDateProgressBarElement(t, event.date, percentageOfTotalDays);
    else if (event.eventType ===  EventType.RENEWAL_DATE) return renewalDateProgressBarElement(t, event.date, percentageOfTotalDays);
    else if(event.info && (event.priority || event.priority === 0)) return publicationProgressBarElement(event.date, event.priority, t, percentageOfTotalDays);
}

function getDateString(date: Moment){
    return DateUtil.getDateStringWithFormat(date, "DD-MM-YYYY");
}
function publicationProgressBarElement(date: Moment, priority: number, t: TFunction, percentageOfTotalDays: number): ProgressBarElement {
    return {
        circleProps: {
            width: 28,
            topText: getDateString(date),
            bottomText: TenderUtil.translateSortedPublicationPriority(priority, t),
            type: ProgressBarCircleType.TEAL
        },
        lineProps: {widthPercentage: percentageOfTotalDays}
    }
}

function todayProgressBarElement(percentageOfTotalDays: number, t: TFunction): ProgressBarElement {
    return {
        circleProps: {
            width: 20,
            type: ProgressBarCircleType.ORANGE_DASHED,
            topText: getDateString(DateUtil.today()),
            bottomText: t("publications.today"),
        },
        lineProps: {widthPercentage: percentageOfTotalDays}
    }
}

function deadlineProgressBarElement(t: TFunction, deadlineDate: Moment, percentageOfTotalDays: number): ProgressBarElement {
    return {
        circleProps: {
            width: 28,
            type: deadlineDate.isAfter(DateUtil.today()) ? ProgressBarCircleType.WHITE : ProgressBarCircleType.TEAL,
            topText: getDateString(deadlineDate),
            bottomText: t("publications.deadlineTitle")
        },
        lineProps: {widthPercentage: percentageOfTotalDays}
    }
}

function renewalDateProgressBarElement(t: TFunction, date: Moment, percentageOfTotalDays: number): ProgressBarElement {
    return {
        circleProps: {
            width: 20,
            type: ProgressBarCircleType.TEAL,
            topText: getDateString(date),
            bottomText: t("publications.estimatedRenewalDateShortTitle")
        },
        lineProps: {widthPercentage: percentageOfTotalDays}
    }
}

function renewalPublicationDateProgressBarElement(t: TFunction, date: Moment, percentageOfTotalDays: number): ProgressBarElement {
    return {
        circleProps: {
            width: 28,
            type: ProgressBarCircleType.RED,
            topText: getDateString(date) + "*",
            bottomText: t("publications.estimatedRenewalPublicationDateShortTitle"),
            title: t("publications.estimatedDateClarification")
        },
        lineProps: {widthPercentage: percentageOfTotalDays}
    }
}

interface ProgressBarLineProps {
    widthPercentage: number;
}

interface ProgressBarTransparentLineProps extends ProgressBarLineProps {
    keyString: string;
}

const ProgressBarTransparentLine: React.FC<ProgressBarTransparentLineProps> = (props) => {
    return <>
        <div key={props.keyString} className="line-box" style={{width: props.widthPercentage + "%", height: '50px'}}></div>
    </>
}

interface ProgressBarCircleProps {
    width: number;
    type: ProgressBarCircleType
    topText: string;
    bottomText: string;
    title?: string;
}

interface ProgressBarCircleElementProps extends ProgressBarCircleProps {
    keyString: string;
}

const ProgressBarCircle: React.FC<ProgressBarCircleElementProps> = (props) => {
    return <>
        <div className={"progress-bar-circle-box"} key={props.keyString} title={props.title ? props.title : ""}>
            <p>{props.topText}</p>
            <ProgressCircle {...props}/>
            <p>{props.bottomText}</p>
        </div>
    </>
}

const ProgressCircle: React.FC<ProgressBarCircleProps> = (props) => {
    let innerCircleRadius = props.width - 6;
    return <div className={outerClassName(props.type)} style={{width: props.width + "px", height: props.width + "px"}}>
        <motion.div
            whileHover={{ scale: 0.9, transition: { type: 'spring', bounce:1.0, duration: 0.2 } }}
            className={innerClassName(props.type)} style={{width: innerCircleRadius + "px", height: innerCircleRadius + "px"}}>
        </motion.div>
    </div>
}

function innerClassName(type: ProgressBarCircleType){
    if (type === ProgressBarCircleType.RED) return "progress-bar-inner-circle background-red";
    else if (type === ProgressBarCircleType.WHITE) return "progress-bar-inner-circle background-white";
    else if (type === ProgressBarCircleType.ORANGE_DASHED) return "progress-bar-inner-circle background-orange";
    else return "progress-bar-inner-circle background-teal";
}

function outerClassName(type: ProgressBarCircleType){
    if (type === ProgressBarCircleType.RED) return "progress-bar-outer-circle border-red";
    else if (type === ProgressBarCircleType.WHITE) return "progress-bar-outer-circle border-black";
    else if (type === ProgressBarCircleType.ORANGE_DASHED) return "progress-bar-outer-circle border-orange-dashed";
    else return "progress-bar-outer-circle border-teal";
}