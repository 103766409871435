import React from 'react';
import { CompanyCard } from '../../../../components/companySummaryCard';

interface SubTab {
    label: string;
    indexEnd: string;
}
export interface CompanyPageSubTabListProps {
    activeTab: string[];
    setActiveTab: (tab: string[]) => void;
    setLastSelectedOption: (tab: string[]) => void;
    indexStart: string;
    subTabs: SubTab[];
}
export const CompanyPageSubTabList: React.FC<CompanyPageSubTabListProps> = ({
    activeTab,
    setActiveTab,
    setLastSelectedOption,
    indexStart,
    subTabs,
}) => {
    return (<CompanyCard.SubTabList
        isActivated={ activeTab[0] === indexStart }
    >{subTabs.map((subTab) => { return (<CompanyCard.SubTab
        isActive={ activeTab[1] === subTab.indexEnd }
        onClick={() => {
            setActiveTab([indexStart, subTab.indexEnd])
            setLastSelectedOption([indexStart, subTab.indexEnd])
        }}
    >
        {subTab.label}
    </CompanyCard.SubTab>)})}</CompanyCard.SubTabList>);
}
