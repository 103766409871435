import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SubjectCodes } from "../../../../consts/subjectCodes";
import { Accreditations } from "../../../../consts/accreditations";
import {t, TFunction} from "i18next";
import { DebounceDatalistInput, DropdownElement } from "../../../../components/debounceInput/DebounceTextInput";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { SearchFilterState, updateSearchFilters } from "../../../../hooks/slices/tenderSearchSlice";
import { RaiseCurrentPlan } from "../../../../hooks/raiseCurrentPlan";
import {CodeTreeUtil, CodeUtil} from "../../../../utils/codes";
import { FeatureUtil } from "../../../../utils/features";
import { Feature } from "../../../../consts/features";
import {Code, CodeBranch, CodeType, CodeWithClass} from "../../../../types/code";
import {RaiseUser} from "../../../../hooks/raiseUser";

interface CodesAutocompleteDropdownProps {
  codeType: CodeType;
  updateCodeSearchFilters: (selectedElements: CodeDropdownElement[]) => void;
  selectedElements: CodeDropdownElement[];
}

export const CodesAutocompleteDropdownWithDispatch: React.FC<{codeType: CodeType}> = ({codeType}) => {
    const dispatch = useAppDispatch();
    let userInteractions = RaiseUser().user?.userInteractions ?? [];
    const searchFilters: SearchFilterState = useAppSelector((state) => state.tenderSearch.searchFilters);
    let selectedElements: CodeDropdownElement[] = getCodeDropdownElementsFromSearchFilters(searchFilters, codeType, t);
    const updateCodeSearchFilters = (selectedElements: CodeDropdownElement[]) => {
        if (codeType === CodeType.CPV) {
            let selectedCodes: CodeBranch[] = selectedElements.map(c => c.code);
            dispatch(updateSearchFilters({subjectCodes: selectedCodes, userInteractions: userInteractions}));
        }
        else if (codeType === CodeType.NUTS){
            let selectedCodes: CodeBranch[] = selectedElements.map(c => c.code);
            dispatch(updateSearchFilters({regionCodes: selectedCodes, userInteractions: userInteractions}));
        }
        else if (codeType === CodeType.ACCREDITATION) {
            let selectedCodes: CodeWithClass[] = CodeUtil.convertAccreditationStringsToCodeWithClass(selectedElements.map(c => c.code.code));
            dispatch(updateSearchFilters({accreditations: selectedCodes, userInteractions: userInteractions}));
        }
    }
    return <CodesAutocompleteDropdown codeType={codeType} selectedElements={selectedElements} updateCodeSearchFilters={updateCodeSearchFilters}/>
}

export const CodesAutocompleteDropdown: React.FC<CodesAutocompleteDropdownProps> = ({codeType, selectedElements, updateCodeSearchFilters}) => {
    let {t} = useTranslation();
    const features = RaiseCurrentPlan().currentPlan?.features;
    const [availableElements, setAvailableElements] = useState<CodeDropdownElement[]>([]);
    const removeCode = (e: CodeDropdownElement) => {
        if (selectedElements.includes(e)) {
            updateCodeSearchFilters(selectedElements.filter(c => c !== e));
        }
    }
    const changeQuery = (queryValue: string) => {
        let allRelevantCodes = getAllRelevantCodes(codeType, features);
        let codesFound: CodeBranch[] = CodeUtil.searchCodes(queryValue, allRelevantCodes, codeType,100, t)
            .map(c => {return {code: c, inclusive: false, type: codeType}});
        setAvailableElements(CodeUtil.getCodeDropdownElements(codesFound, t));
    }

    const codeIsSelected = (code: Code) => {
        return selectedElements.filter(c => c.code.code === code.code).length !== 0;
    }

    const checkAndAddCode = (code: CodeDropdownElement[]) => {
        if (code.length === 1 && !codeIsSelected(code[0].code)) {updateCodeSearchFilters([...selectedElements, code[0]]);}
    }
    const addCodeToSearchFilters = (codeWithTranslation: string) => {
        let codeWithoutTranslation = codeWithTranslation.split(" - ")[0];
        if (codeWithoutTranslation.endsWith("*"))  { //user manually entered a wild card
            let allRelevantCodes = getAllRelevantCodes(codeType, features);
            let codes: string[] = CodeUtil.searchCodes(codeWithoutTranslation.slice(0, -1), allRelevantCodes, codeType,1, t);
            let inclusiveCodes: CodeBranch[] = codes.map(c => {return {code: c, inclusive: true, type: codeType}});
            checkAndAddCode(CodeUtil.getCodeDropdownElements(inclusiveCodes, t));
        }
        else { //user clicks on an element in the list
            let code: CodeBranch[] = availableElements
                .filter(e => e.value === codeWithoutTranslation)
                .map(c => c.code);
            let exclusiveCode = makeCodesInclusive(code, false); //codes are exclusive by default
            checkAndAddCode(CodeUtil.getCodeDropdownElements(exclusiveCode, t));
        }
    }
    useEffect(() => {
        //this initializes the datalist with first 100 codes
        changeQuery("");
    }, []);
    return <>
        <div className='checkbox-button-relative'>
            <div className='checkbox-label-alignment'>
                <p>{inputLabel(codeType, t)}</p>
            </div>
            <div className='input'>
                    <DebounceDatalistInput search={changeQuery} select={addCodeToSearchFilters} delay={300} placeHolder={t("settings.search")} dataList={availableElements}/>
            </div>
            <div className='labels'>
                <div className="label-button-alignment">
                    {selectedElements.map((c, i) =>
                        <button key={codeLabel(c)} onClick={() => removeCode(c)}>
                            <div>{codeLabel(c)}</div>
                        </button>
                    )}
                </div>
            </div>
        </div>
    </>
}

function inputLabel(codeType: CodeType, t: TFunction): string {
    if (codeType === CodeType.CPV) return t("publications.subjectCodesTitle");
    else if (codeType === CodeType.NUTS) return t("publications.regionCodesTitle");
    else if (codeType === CodeType.ACCREDITATION) return t("publications.accreditationsTitle");
    return "No label";
}

function codeLabel(code: CodeDropdownElement): string {
    // return code.label;
    return code.label + (code.code.inclusive ? " (incl.)" : "");
}

function getAllRelevantCodes(codeType: CodeType, features: Feature[] | undefined): string[] {
    if (codeType === CodeType.CPV) return SubjectCodes;
    else if (codeType === CodeType.NUTS) return FeatureUtil.getRelevantRegionCodesFromFeatures(features);
    else if (codeType === CodeType.ACCREDITATION) return Accreditations;
    return [];
}

function getCodeDropdownElementsFromSearchFilters(searchFilters: SearchFilterState, codeType: CodeType, t: TFunction): CodeDropdownElement[]{
    if (codeType === CodeType.CPV && searchFilters.subjectCodes) {
        return CodeUtil.getCodeDropdownElements(searchFilters.subjectCodes, t)
    } else if (codeType === CodeType.NUTS && searchFilters.regionCodes) {
        return CodeUtil.getCodeDropdownElements(searchFilters.regionCodes, t)
    } else if (codeType === CodeType.ACCREDITATION && searchFilters.accreditations) {
        return CodeUtil.getCodeDropdownElements(convertAccreditationsToCodeBranches(searchFilters.accreditations), t)
    } return [];
}

function convertAccreditationsToCodeBranches(accreditations: CodeWithClass[]): CodeBranch[] {
    return accreditations.map(a => {return {code: CodeUtil.convertCodeWithClassToString(a), type: CodeType.ACCREDITATION, inclusive: false}});

}

function makeCodesInclusive(codes: CodeBranch[], inclusive: boolean): CodeBranch[] {
    return codes.map(c => makeCodeInclusive(c, inclusive));
}
function makeCodeInclusive(code: CodeBranch, inclusive: boolean): CodeBranch {
    return {
        code: code.code,
        type: code.type,
        inclusive: inclusive,
    }
}
export interface CodeDropdownElement extends DropdownElement {
    code: CodeBranch;
}