import React from 'react';
import styles from './index.module.scss';


export interface OverviewContainerProps extends React.HTMLAttributes<HTMLDivElement> {
    children ?: React.ReactNode;
}
export const OverviewContainer: React.FC<OverviewContainerProps> = ({
    children, 
    ...props
}) => {
    return (<div 
        className={`${styles['overview-container']}`} 
        {...props}
    >
            {children}
    </div>);
}
