import React, {  } from "react";
import styles from "./index.module.scss";

export interface ProgressCircleProps {
    progressPercentage: number;
    size: number;
    stroke: number;
    text: string | JSX.Element;
    color?: string;
    invert?: boolean;
}

//https://www.30secondsofcode.org/css/s/circular-progress-bar/
export const ProgressCircle: React.FC<ProgressCircleProps> = ({progressPercentage, size, stroke, text, color, invert}) => {
    let barColor = color ? color : "#ff8520";
    const style = { "--progress": progressPercentage, "--size": size + 'px', "--stroke-width": stroke + 'px', "--bar-color" : barColor } as React.CSSProperties;
    return <div className={styles['container']} style={{height: size + "px", width: size + 'px'}}>
        <div className={styles['text']}>
            <p>{text}</p>
        </div>
        <div className={styles['image']}>
            <svg width={size} height={size} viewBox={"0 0 " + size + " " + size}
                 className={styles["circular-progress"]} style={style} transform={invert ? "scale(-1, 1)" : ""}>
                <circle className={styles["bg"]}></circle>
                <circle className={styles["fg"]}></circle>
            </svg>
        </div>
    </div>
};