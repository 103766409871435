import React from 'react';
import styles from './index.module.scss';

import { TabPanel } from '../';


export interface TabPanelCompetitorsProps extends React.HTMLAttributes<HTMLDivElement> {
    isActive?: boolean;
}
export const TabPanelCompetitors: React.FC<TabPanelCompetitorsProps> = ({ 
    children, 
    isActive=false,
    ...props 
}) => {
    return (<TabPanel 
        className={styles['tab-panel-competitors']}
        isActive={isActive}
        {...props}
    >
        Competitors Tab Panel
    </TabPanel>);
}
