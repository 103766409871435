import {TFunction} from "react-i18next";
import {Code, CodeBranch, CodeType, CodeWithClass} from "../types/code";

import cpv_03 from "../assets/icons/cpv_03_agriculture.svg";
import cpv_09 from "../assets/icons/cpv_09_energy-source.svg";
import cpv_14 from "../assets/icons/cpv_14_mining-metal.svg";
import cpv_15 from "../assets/icons/cpv_15_food-beverage.svg";
import cpv_16 from "../assets/icons/cpv_16_farming-machines.svg";
import cpv_18 from "../assets/icons/cpv_18_clothes.svg";
import cpv_19 from "../assets/icons/cpv_19_textile.svg";
import cpv_22 from "../assets/icons/cpv_22_press.svg";
import cpv_24 from "../assets/icons/cpv_24_chemical.svg";
import cpv_30 from "../assets/icons/cpv_30_office-machine.svg";
import cpv_31 from "../assets/icons/cpv_31_electrical-machines.svg";
import cpv_32 from "../assets/icons/cpv_32_telecom.svg";
import cpv_33 from "../assets/icons/cpv_33_medical.svg";
import cpv_34 from "../assets/icons/cpv_34_transport.svg";
import cpv_35 from "../assets/icons/cpv_35_safety-fire-army-police.svg";
import cpv_37 from "../assets/icons/cpv_37_music-sport-games-handwork-art.svg";
import cpv_38 from "../assets/icons/cpv_38_lab-instruments.svg";
import cpv_39 from "../assets/icons/cpv_39_furniture.svg";
import cpv_41 from "../assets/icons/cpv_41_water.svg";
import cpv_42 from "../assets/icons/cpv_42_company-machines.svg";
import cpv_43 from "../assets/icons/cpv_43_machines-mines-quarry-building.svg";
import cpv_44 from "../assets/icons/cpv_44_materials-industry-sector.svg";
import cpv_45 from "../assets/icons/cpv_45_building-activity.svg";
import cpv_48 from "../assets/icons/cpv_48_software.svg";
import cpv_50 from "../assets/icons/cpv_50_repair.svg";
import cpv_51 from "../assets/icons/cpv_51_installation.svg";
import cpv_55 from "../assets/icons/cpv_55_horeca.svg";
import cpv_60 from "../assets/icons/cpv_60_transport.svg";
import cpv_63 from "../assets/icons/cpv_63_more-transport.svg";
import cpv_64 from "../assets/icons/cpv_64_post-telecom.svg";
import cpv_65 from "../assets/icons/cpv_65_public-facilities.svg";
import cpv_66 from "../assets/icons/cpv_66_finance.svg";
import cpv_70 from "../assets/icons/cpv_70_makelaar.svg";
import cpv_71 from "../assets/icons/cpv_71_service-architecture-civil-techniques.svg";
import cpv_72 from "../assets/icons/cpv_72_IT-service.svg";
import cpv_73 from "../assets/icons/cpv_73_research-development.svg";
import cpv_75 from "../assets/icons/cpv_75_governance.svg";
import cpv_76 from "../assets/icons/cpv_76_oil-and-gass.svg";
import cpv_77 from "../assets/icons/cpv_77_forestry.svg";
import cpv_79 from "../assets/icons/cpv_79_business-services.svg";
import cpv_80 from "../assets/icons/cpv_80_education.svg";
import cpv_85 from "../assets/icons/cpv_85_education.svg";
import cpv_90 from "../assets/icons/cpv_90_education.svg";
import cpv_92 from "../assets/icons/cpv_92_culture-sport-recreation.svg";
import cpv_98 from "../assets/icons/cpv_98_other.svg";
import {CodeDropdownElement} from "../pages/search/sidebar/inputs/CodesAutoCompleteDropdown";

export namespace CodeUtil {

  export function getCodeDropdownElements(codes: CodeBranch[], t: TFunction): CodeDropdownElement[] {
    return codes.map((c) => ({
      code: c,
      value: CodeUtil.getFullCodeString(c),
      label: CodeUtil.getCodeWithTranslation(c, t),
    }));
  }

  //NEW METHODS
  export function getLevel(code: string, type: CodeType) {
    if (type === CodeType.NUTS) return code.length - 2;
    //so BE = 0 and BE1 = 1
    else if (type === CodeType.CPV) return CpvUtil.getCpvLevel(code);
    else if (type === CodeType.ACCREDITATION) return code.split("-")[0].length - 1;
    //so c-1 = 0 and c1-1 = 1
    else return 0;
  }

  export function getRoot(code: string, type: CodeType): string {
    if (type === CodeType.CPV) return CpvUtil.getRoot(code);
    else return code;
  }

  export function getFullCode(code: string, type: CodeType): string {
    if (type === CodeType.CPV) return CpvUtil.getCpvFullCode(code);
    else return code;
  }

  export function findChildrenFromBranch(code: string, availableCodes: string[], type: CodeType): string[] {
    return availableCodes.filter((c) => child(c, code, type));
  }

  export function hasChildren(code: string, availableCodes: string[], type: CodeType): boolean {
    return availableCodes.some((c) => child(c, code, type));
  }

  export function findParents(code: string, availableCodes: string[], type: CodeType): string[] {
    return availableCodes.filter((c) => parent(c, code, type));
  }

  export function findDescendants(code: string, availableCodes: string[], type: CodeType): string[] {
    return availableCodes.filter((c) => descendant(c, code, type));
  }

  export function findAncestors(code: string, availableCodes: string[], type: CodeType): string[] {
    return availableCodes.filter((c) => ancestor(c, code, type));
  }

  export function sameBranch(a: string, b: string, type: CodeType): boolean {
    if (type === CodeType.ACCREDITATION){
      return a.slice(0, 1) === b.slice(0, 1);
    } return a.slice(0, 2) === b.slice(0, 2);
  }

  export function ancestor(a: string, b: string, type: CodeType): boolean {
    if (!sameBranch(a, b, type)) return false;
    let levelA = getLevel(a, type);
    let levelB = getLevel(b, type);
    return levelA < levelB && shareRoot(a, b, levelA, levelB);
  }

  export function parent(a: string, b: string, type: CodeType): boolean {
    if (!sameBranch(a, b, type)) return false;
    let levelA = getLevel(a, type);
    let levelB = getLevel(b, type);
    return levelA === levelB - 1 && shareRoot(a, b, levelA, levelB);
  }

  export function descendant(a: string, b: string, type: CodeType): boolean {
    if (!sameBranch(a, b, type)) return false;
    let levelA = getLevel(a, type);
    let levelB = getLevel(b, type);
    return levelA > levelB && shareRoot(a, b, levelA, levelB);
  }

  export function child(a: string, b: string, type: CodeType): boolean {
    if (!sameBranch(a, b, type)) return false;
    let levelA = getLevel(a, type);
    let levelB = getLevel(b, type);
    return levelA === levelB + 1 && shareRoot(a, b, levelA, levelB);
  }

  function shareRoot(a: string, b: string, levelA: number, levelB: number): boolean {
    let smallestLevel = Math.min(levelA, levelB);
    return a.slice(0, smallestLevel + 2) === b.slice(0, smallestLevel + 2);
  }

  export function removeCodeAndDescendants(code: string, selectedCodes: CodeBranch[], type: CodeType) {
    let root = getRoot(code, type); //eg 73300000 -> 733
    return [...selectedCodes].filter((c) => !c.code.startsWith(root));
  }

  export function convertToInclusiveCodeBranches(codes: string[], type: CodeType): CodeBranch[] {
    return codes.map((c) => ({ code: c, inclusive: true, type: type }));
  }

  //OLD METHODS

  export function getCodeWithTranslation(code: Code, t: TFunction): string {
    let fullCodeString = getFullCodeString(code);
    if (code.type === CodeType.ACCREDITATION)
      return fullCodeString + " - " + getCodeTranslation(fullCodeString, code.type, t);
    return fullCodeString + " - " + getCodeTranslation(fullCodeString, code.type, t);
  }

  export function getCodeTranslation(fullCodeString: string, codeType: CodeType, t: TFunction): string {
    if (codeType === CodeType.ACCREDITATION) return t("codes.accreditation_" + fullCodeString.split("-")[0]);
    return t("codes.code_" + fullCodeString);
  }

  export function getFullCodeString(code: Code): string {
    if (code.type === CodeType.CPV) return CpvUtil.getCpvFullCode(code.code);
    return code.code;
  }

  export function convertCodeWithClassToString(code: CodeWithClass): string {
    return code.code + "-" + code.budgetClass;
  }

  export function convertStringToCodeWithClass(s: string): CodeWithClass {
    let code = s.split("-")[0];
    let budgetClass = 1;
    if (s.includes("-")) budgetClass = parseInt(s.split("-")[1]);
    return { code: code, budgetClass: budgetClass, type: CodeType.ACCREDITATION };
  }

  export function getCodeWithClassLowerClasses(c: CodeWithClass): CodeWithClass[] {
    let codes: CodeWithClass[] = [];
    for (let i = 1; i < c.budgetClass; i++) {
      codes.push({ code: c.code, budgetClass: i, type: CodeType.ACCREDITATION });
    }
    return codes;
  }

  export function findCpvCodesByLevel(level: number, availableCodes: string[]): string[] {
    return availableCodes.filter((c) => CpvUtil.getCpvLevel(c) === level);
  }

  export function createAncestors(code: string, codeType: CodeType): string[] {
    let parentCodes: string[] = [];
    let levelCounter = getLevel(code, codeType);
    while (levelCounter > 0) {
      levelCounter--;
      parentCodes.push(getFullCode(code.slice(0, levelCounter + 2), codeType)); //eg BE22 is level 2 so we want to slice 0,3 and 0,2
    }
    return parentCodes;
  }

  export function convertAccreditationStringsToCodeWithClass(
    accreditations: string[] | undefined
  ): CodeWithClass[] {
    let codes: CodeWithClass[] = [];
    if (accreditations !== undefined) {
      for (const a of accreditations) {
        if (a.includes("-"))
          codes.push({ code: a.split("-")[0], budgetClass: +a.split("-")[1], type: CodeType.ACCREDITATION }); // '+' converts string to number here
      }
    }
    return codes;
  }

  export function searchCodesAndReturnWithAncestors(
    query: string,
    subjectCodes: string[],
    codeType: CodeType,
    limit: number,
    t: TFunction
  ): string[] {
    let codesFound: string[] = searchCodes(query, subjectCodes, codeType, limit, t);
    let ancestorsToAdd: string[] = [];
    let ancestorsAdded: string[] = [...codesFound];
    for (const codeFound of codesFound) {
      let ancestors = createAncestors(codeFound, codeType);
      for (const ancestor of ancestors) {
        if (!ancestorsAdded.includes(ancestor)) {
          ancestorsToAdd.push(ancestor);
          ancestorsAdded.push(ancestor);
        }
      }
    }
    ancestorsToAdd.forEach((a) => codesFound.push(a));
    return codesFound;
  }

  export function searchCodes(
    query: string,
    codes: string[],
    codeType: CodeType,
    limit: number,
    t: TFunction
  ): string[] {
    let codesFound: string[] = [];
    if (query.trim() === "") return emptySearch(codesFound, limit);
    else if (codeType === CodeType.CPV && !isNaN(Number(query))) return numericalCpvSearch(query, codes, limit);
    else return textSearch(query, codes, codeType, limit, t);
  }

  function emptySearch(codes: string[], limit: number): string[] {
    let codesFound: string[] = [];
    for (const code of codes) {
      codesFound.push(code); //empty query just returns first 'limit' codes
      if (codesFound.length === limit) break;
    }
    return codesFound;
  }

  export function numericalCpvSearch(query: string, codes: string[], limit: number): string[] {
    let codesFound: string[] = [];
    let codeRoot = CpvUtil.getRoot(query);
    for (const code of codes) {
      if (code.startsWith(query) || code === codeRoot) codesFound.push(code); //because of the level 45000000 -> '45'
      if (codesFound.length === limit) break;
    }
    return codesFound;
  }

  export function textSearch(query: string, codes: string[], codeType: CodeType, limit: number, t: TFunction) {
    let codesFound: string[] = [];
    let lowerCasedQuery = query.toLowerCase();
    let hitRegex = new RegExp("(^|\\s)" + lowerCasedQuery);
    for (const code of codes) {
      let codeWithTranslation = getCodeWithTranslation({ code: code, type: codeType }, t).toLowerCase();
      if (codeWithTranslation === lowerCasedQuery) {
        codesFound.push(code);
        break; //this triggers the submit
      } else if (hitRegex.test(codeWithTranslation)) {
        codesFound.push(code);
      }
      if (codesFound.length === limit) break;
    }
    return codesFound;
  }
}

export namespace CpvUtil {

  export function getCpvIcon(cpv: string) {
    if (cpv.startsWith("03")) return cpv_03;
    if (cpv.startsWith("09")) return cpv_09;
    if (cpv.startsWith("14")) return cpv_14;
    if (cpv.startsWith("15")) return cpv_15;
    if (cpv.startsWith("16")) return cpv_16;
    if (cpv.startsWith("18")) return cpv_18;
    if (cpv.startsWith("19")) return cpv_19;
    if (cpv.startsWith("22")) return cpv_22;
    if (cpv.startsWith("24")) return cpv_24;
    if (cpv.startsWith("30")) return cpv_30;
    if (cpv.startsWith("31")) return cpv_31;
    if (cpv.startsWith("32")) return cpv_32;
    if (cpv.startsWith("33")) return cpv_33;
    if (cpv.startsWith("34")) return cpv_34;
    if (cpv.startsWith("35")) return cpv_35;
    if (cpv.startsWith("37")) return cpv_37;
    if (cpv.startsWith("38")) return cpv_38;
    if (cpv.startsWith("39")) return cpv_39;
    if (cpv.startsWith("41")) return cpv_41;
    if (cpv.startsWith("42")) return cpv_42;
    if (cpv.startsWith("43")) return cpv_43;
    if (cpv.startsWith("44")) return cpv_44;
    if (cpv.startsWith("45")) return cpv_45;
    if (cpv.startsWith("48")) return cpv_48;
    if (cpv.startsWith("50")) return cpv_50;
    if (cpv.startsWith("51")) return cpv_51;
    if (cpv.startsWith("55")) return cpv_55;
    if (cpv.startsWith("60")) return cpv_60;
    if (cpv.startsWith("63")) return cpv_63;
    if (cpv.startsWith("64")) return cpv_64;
    if (cpv.startsWith("65")) return cpv_65;
    if (cpv.startsWith("66")) return cpv_66;
    if (cpv.startsWith("70")) return cpv_70;
    if (cpv.startsWith("71")) return cpv_71;
    if (cpv.startsWith("72")) return cpv_72;
    if (cpv.startsWith("73")) return cpv_73;
    if (cpv.startsWith("75")) return cpv_75;
    if (cpv.startsWith("76")) return cpv_76;
    if (cpv.startsWith("77")) return cpv_77;
    if (cpv.startsWith("79")) return cpv_79;
    if (cpv.startsWith("80")) return cpv_80;
    if (cpv.startsWith("85")) return cpv_85;
    if (cpv.startsWith("90")) return cpv_90;
    if (cpv.startsWith("92")) return cpv_92;
    if (cpv.startsWith("98")) return cpv_98;
  }

  export function getCpvLevel(code: string){
    return getRoot(code).length - 2;
  }

  export function getRoot(cpv: string): string {
    let codeRoot = cpv.replace(/0+$/, "");
    if (codeRoot.length === 1) return codeRoot + "0";
    else return codeRoot;
  }

  export function getCpvFullCode(cpv: string): string {
    while (cpv.length < 8) cpv = cpv + "0";
    return cpv;
  }
}

export namespace CodeTreeUtil {

  //this checks if the code itself or any of its ancestors are selected (and inclusive in the latter case)
  export function isSelected(cpv: string, selectedCodes: CodeBranch[], codeType: CodeType): boolean{
    if (selectedCodes.find(c => c.code === cpv)) return true;
    let ancestors = CodeUtil.findAncestors(cpv, selectedCodes.filter(c => c.inclusive).map(c => c.code), codeType);
    return ancestors.length > 0;
  }

  //this just removes all descendants and makes the code inclusive
  export function addCodeWithDescendants(cpv: string, selectedCodes: CodeBranch[], codeType: CodeType): CodeBranch[]{
    let newSelectedCodes = CodeUtil.removeCodeAndDescendants(cpv, selectedCodes, codeType)
    newSelectedCodes.push({code: cpv, type: codeType, inclusive: true});
    return newSelectedCodes;
  }

  //if any ancestor code is inclusive -> it becomes exclusive and its other children become inclusive (since before they were just included)
  export function removeCodeWithDescendants(cpv: string, selectedCodes: CodeBranch[], allCodes: string[], codeType: CodeType){
    let newSelectedCodes = CodeUtil.removeCodeAndDescendants(cpv, selectedCodes, codeType)
    let selectedAncestors = CodeUtil.findAncestors(cpv, selectedCodes.map(c => c.code), codeType);
    for(const ancestor of selectedAncestors){
      let selectedAncestor = newSelectedCodes.find(c => c.code === ancestor);
      if(selectedAncestor && selectedAncestor.inclusive) {
        newSelectedCodes = newSelectedCodes.filter(c => c.code !== ancestor)
        newSelectedCodes.push({...selectedAncestor, inclusive: false});
        let otherChildren = CodeUtil.findChildrenFromBranch(ancestor, allCodes, codeType)
            .filter(c => c !== cpv);
        for(const child of otherChildren){
          newSelectedCodes.push({code: child, type: codeType, inclusive: true});
        }
      }
    } return newSelectedCodes;
  }

  export function hasSelectedDescendant (cpv: string, selectedCodes: CodeBranch[], codeType: CodeType): boolean {
    return CodeUtil.findDescendants(cpv, selectedCodes.map(c => c.code), codeType).length > 0;
  }

  export function isInclusive (cpv: string, selectedCodes: CodeBranch[], codeType: CodeType): boolean {
    return selectedCodes.filter(c => c.code === cpv && c.inclusive).length > 0
        || selectedCodes.filter(c => CodeUtil.ancestor(c.code, cpv, codeType)).filter(c => c.inclusive).length > 0;
  }

}