import React from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { useTranslation } from "react-i18next";
import { SearchFilter } from "../../../../consts/SearchFilter";
import {
    DateFilterType, resetDateFilters, resetSearchToInitialState,
    SearchFilterState,
    updateSearchFilters,
} from "../../../../hooks/slices/tenderSearchSlice";
import { SearchFilterUtil } from "../../../../utils/searchFilters";
import { QueryFilterButton } from "./QueryFilterButton";
import { DateFilterButton } from "./DateFilterButton";
import { DateUtil } from "../../../../utils/date";
import { SearchFilterButton, SearchFilterButtonSkeleton } from "./SearchFilterButton";
import { CodeBranchFilterButtons, CodeWithClassFilterButtons } from "./CodeFilterButtons";
import { LabelFilterButtons } from "./LabelFilterButtons";
import { ResetAllFiltersButton } from "./ResetAllFiltersButton";
import { CompanyFilterButtons } from "./CompanyFilterButtons";
import { UserInteraction } from "../../../../hooks/slices/userSlice";
import { RaiseUser } from "../../../../hooks/raiseUser";
import { SimilarTenderFilterButton } from "./SimilarTenderFilterButton";
import { motion } from "framer-motion";
import {GenericSearchPageProps, SearchConfiguration, SearchResultsConfiguration} from "../../GenericSearchPage";
import { SearchType } from "../../../../consts/searchType";
import { SavedSearchFilterButtons } from "./SavedSearchFilterButton";
import { RaiseSavedSearches, SavedSearchesInStore } from "../../../../hooks/raiseSavedSearches";
import { SavedSearchUtil } from "../../../../utils/savedsearches";
import {FilterButton} from "../../../../components/filterButton";

export const SearchFilterButtons: React.FC<GenericSearchPageProps> = (props) => {
  const dispatch = useAppDispatch();
  let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
  const { t, i18n } = useTranslation();
  const savedSearches: SavedSearchesInStore = RaiseSavedSearches();
  const allProfileNames: string[] = SavedSearchUtil.getSavedSearchNames(savedSearches, t, i18n.language);
  const searchFilters: SearchFilterState = useAppSelector((state) => state.tenderSearch.searchFilters);
  const currentlySearchingOpportunities: boolean =
    props.searchConfiguration.sidebar.queryType === SearchType.OPPORTUNITIES;
  const removeSearchFilter = (searchFilter: SearchFilter) => {
    dispatch(
      updateSearchFilters({
        selectedFilters: searchFilters.selectedFilters.filter((s) => s !== searchFilter),
        userInteractions: userInteractions,
      })
    );
  };
  let hiddenFilters: SearchFilter[] = props.searchConfiguration.info.hiddenFilters
    ? props.searchConfiguration.info.hiddenFilters
    : [];
  let lockedFilters: SearchFilter[] = props.searchConfiguration.info.lockedFilters
    ? props.searchConfiguration.info.lockedFilters
    : [];
  let hiddenOrLockedFilters: SearchFilter[] = hiddenFilters.concat(lockedFilters);
  let shownGenericSearchFilters: SearchFilter[] = Array.from(
    new Set(searchFilters.selectedFilters.filter((f) => !hiddenOrLockedFilters.includes(f)))
  );
  let numberOfFiltersShown: number = countShownFilters(
    searchFilters,
    shownGenericSearchFilters,
    props.searchConfiguration.info
  );
  const resetQuery = () => {
    dispatch(updateSearchFilters({ query: "", userInteractions: userInteractions }));
  };
  const savedSearchIsActive = (name: string): boolean => {
    return searchFilters.savedSearches ? searchFilters.savedSearches?.includes(name) : false;
  };
  const removeSavedSearch = (name: string) => {
    let filteredSavedSearches = searchFilters.savedSearches?.filter((s) => s !== name);
    if (filteredSavedSearches && filteredSavedSearches.length > 0) {
      dispatch(updateSearchFilters({ savedSearches: filteredSavedSearches, userInteractions: userInteractions }));
      return true;
    }
    return false;
  };
  const addSavedSearch = (name: string) => {
    let newSavedSearches = [...(searchFilters.savedSearches ? searchFilters.savedSearches : []), name];
    dispatch(updateSearchFilters({ savedSearches: newSavedSearches, userInteractions: userInteractions }));
    return true;
  };
  let showSavedSearchFilterButtons = searchFilters.savedSearches && searchFilters.savedSearches.length > 0;
  let companyVatNumbers = searchFilters.participantVatNumbers ? searchFilters.participantVatNumbers : [];
  if (searchFilters.contractingAuthorityVatNumbers)
    companyVatNumbers = companyVatNumbers.concat(searchFilters.contractingAuthorityVatNumbers);
  if (numberOfFiltersShown === 0 && !currentlySearchingOpportunities) return <></>;
  return (
    <>
      {" "}
      {props.searchConfiguration.info.showFilterButtons && showSavedSearchFilterButtons && (
        <SavedSearchFilterButtons
          allProfileNames={allProfileNames}
          savedSearchIsActive={savedSearchIsActive}
          addSavedSearch={addSavedSearch}
          removeSavedSearch={removeSavedSearch}
        />
      )}
      {props.searchConfiguration.info.showFilterButtons && (
        <motion.div
          className="search-filter-button-alignment"
          variants={{ hidden: { opacity: 0 }, visible: { opacity: 1, transition: { staggerChildren: 0.2 } } }}
          initial="hidden"
          animate="visible"
        >
          {searchFilters.similarTender && <SimilarTenderFilterButton similarTender={searchFilters.similarTender} />}
          <QueryFilterButton query={searchFilters.query} resetQuery={resetQuery} />
            {searchFilters.customFilter && <FilterButton text={searchFilters.customFilter}
                                                         close={() => dispatch(resetSearchToInitialState({ reset: true }))}/>}

            <DateFilterButton
              label={t("publications.publicationDateTitle")}
              selectedDateFilters={searchFilters.selectedDateFilters}
              type={DateFilterType.PUBLICATION_DATE}
              canRemove={!props.searchConfiguration.info.blockDateFilterButtons}
              removeDateFilters={() => dispatch(resetDateFilters({type: DateFilterType.PUBLICATION_DATE}))}
            />

          <DateFilterButton
            label={t("publications.deadlineTitle")}
            selectedDateFilters={searchFilters.selectedDateFilters}
            type={DateFilterType.DEADLINE}
            canRemove={!props.searchConfiguration.info.blockDateFilterButtons}
            removeDateFilters={() => dispatch(resetDateFilters({type: DateFilterType.DEADLINE}))}
          />
            <DateFilterButton
                label={t("publications.awardPublicationDateTitle")}
                selectedDateFilters={searchFilters.selectedDateFilters}
                type={DateFilterType.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE}
                canRemove={!props.searchConfiguration.info.blockDateFilterButtons}
                removeDateFilters={() => dispatch(resetDateFilters({type: DateFilterType.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE}))}
            />
            <DateFilterButton
                label={t("publications.estimatedRenewalDateTitle")}
                selectedDateFilters={searchFilters.selectedDateFilters}
                type={DateFilterType.ESTIMATED_RENEWAL_PUBLICATION_DATE}
                canRemove={!props.searchConfiguration.info.blockDateFilterButtons}
                removeDateFilters={() => dispatch(resetDateFilters({type: DateFilterType.ESTIMATED_RENEWAL_PUBLICATION_DATE}))}
            />
          {shownGenericSearchFilters.sort().map((filter, i) => (
            <SearchFilterButton
              key={i}
              searchFilter={filter}
              removeSearchFilter={removeSearchFilter}
              canBeRemoved={true}
            />
          ))}
          {searchFilters.subjectCodes && <CodeBranchFilterButtons codes={searchFilters.subjectCodes} />}
          {searchFilters.regionCodes && <CodeBranchFilterButtons codes={searchFilters.regionCodes} />}
          {searchFilters.accreditations && <CodeWithClassFilterButtons codes={searchFilters.accreditations} />}
          {searchFilters.labelFilters && <LabelFilterButtons labelFilters={searchFilters.labelFilters} />}
          {companyVatNumbers && <CompanyFilterButtons vatNumberFilters={companyVatNumbers} />}
          {numberOfFiltersShown > 1 && <ResetAllFiltersButton />}
        </motion.div>
      )}
    </>
  );
};

function countShownFilters(f: SearchFilterState, genericFilters: SearchFilter[], configuration: SearchResultsConfiguration) {
    let count = genericFilters.length;
    if (f.query !== "") count++;
    if (f.subjectCodes != null && f.subjectCodes.length > 0) count+=f.subjectCodes.length;
    if (f.regionCodes != null && f.regionCodes.length > 0) count+=f.regionCodes.length;
    if (f.accreditations != null && f.accreditations.length > 0) count+=f.accreditations.length;
    if (f.labelFilters != null && f.labelFilters.length > 0) count += f.labelFilters.length;
    if (f.similarTender != null ) count+=1;
    if(!configuration.blockDateFilterButtons){
        count += f.selectedDateFilters ? f.selectedDateFilters.length : 0;
    }
    if (f.participantVatNumbers != null && f.participantVatNumbers.length > 0) count+=f.participantVatNumbers.length;
    if (f.contractingAuthorityVatNumbers != null && f.contractingAuthorityVatNumbers.length > 0) count+=f.contractingAuthorityVatNumbers.length;
    return count;
}

export const SearchFilterButtonsSkeleton = () => {
    return <div className="search-filter-button-alignment"><SearchFilterButtonSkeleton/></div>;
};