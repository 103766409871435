import { ResourceKey } from "i18next";

export const SavedSearchSettings: ResourceKey = {
	generalSettings: "Algemeen",
	settings: "Instellingen",
	keyWords: "Zoektermen",
	copyKeyWords: "Download",
	resetKeyWords: "Verwijder alles",
	batchInsertKeyWords: "Voeg lijst toe",
	subjectCodes: "CPV",
	regionCodes: "NUTS",
	accreditations: "Erkenningen",
	formTypes: "Formuliertypes",
	contractingAuthorities: "Overheden",
	save: "Opslaan",
	accountSettings: "Account",
	newKeyword: "Nieuwe zoekterm",
	pasteKeywords: "Plak hier je trefwoorden",
  duplicateKeywords: "Trefwoorden bevatten dubbels",
  illegalQuotesInKeywords: "Trefwoorden bevatten gekrulde aanhalingstekens, vervang ze door rechte aanhalingstekens (', \")",
	tooManyKeywords: "Te veel trefwoorden (max. 500)",
	keywordTooLong: "Een van je trefwoorden is te lang",
	addProfile: "Profiel toevoegen",
	name: "Naam van het profiel",
  invalidProfileName: "Ongeldige profielnaam",
	defaultName: "Selectieprofiel",
	SavedSearchFilterButtonTitle: "Profiel",
	confirmRemove: "Ben je zeker dat je dit selectieprofiel wil verwijderen?",
	expertManaged: "Dit profiel wordt door TenderWolf onderhouden. Contacteer onze Tender Search Expert voor meer flexibiliteit.",
	noResults: "Geen resultaten"
};
