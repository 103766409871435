import React, { 
    useEffect,
    useState, 
} from 'react';

import styles from './index.module.scss';


export interface TabDropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    header: React.ReactNode;
    options: React.ReactNode[];
    isActive?: boolean;
}


export const TabDropdown: React.FC<TabDropdownProps> = ({ 
    header,
    options, 
    isActive = false,
    ...props
 }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState<React.ReactNode | null>(null);

    const handleSelect = (option: React.ReactNode) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    useEffect(() => {
        if (!isActive) {
            setSelectedOption(null);
            if (isOpen) {
                setIsOpen(false);
            }
        }
    }, [isActive, isOpen, setSelectedOption, setIsOpen]);

    return (<div 
        className={`
            ${styles['dropdown-container']}
            ${isActive ? styles['is-active'] : styles['not-active']}
            ${isOpen ? styles['is-open'] : styles['is-closed']}
        `} 
        {...props}
    >
        <div 
            className={styles['dropdown-header']}
            onClick={() => setIsOpen(!isOpen)}
        >
            {(!isOpen && selectedOption) ? selectedOption : header}
        </div>
        {isOpen && (<div
            className={styles['dropdown-option-list']} 
        >
            {options.map((option, index) => (<div
                className={styles['dropdown-option']}
                key={index}
                onClick={() => handleSelect(option)}
            >
                {option}
            </div>))}
        </div>)}
    </div>);
}