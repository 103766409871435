import React from 'react';
import styles from './index.module.scss';

import { TabPanel } from '..';


export interface TabPanelMainActivitiesProps extends React.HTMLAttributes<HTMLDivElement> {
    isActive?: boolean;
}
export const TabPanelMainActivities: React.FC<TabPanelMainActivitiesProps> = ({ 
    children, 
    isActive=false,
    ...props 
}) => {
    return (<TabPanel 
        className={styles['tab-panel-main-activities']}
        isActive={isActive}
        {...props}
    >
        Main Activities Tab Panel
    </TabPanel>);
}
