import { ResourceKey } from "i18next";

export const Navigation: ResourceKey = {
	page: "page",
	previous: "Précédent",
	next: "Suivant",
	showMore: "Montre plus",
	showLess: "Montre moins",
	pleaseLogIn: "Veuillez vous connecter pour voir cette page",
	logIn: "Connectez-vous",
	opportunities: "Opportunités",
	leads: "Présélection",
	awards: "Attributions",
	frameworks: "Contrats expirants",
	marketIntelligence: "Analyse de marché",
	search: "Recherche",
	account: "Account",
	whatIsTenderwolf: "Qu'est-ce que Tenderwolf?",
	followYourCompetitors: "Suivez vos concurrents",
	showTenderInformation: "Retour à l'opportunité",
};
