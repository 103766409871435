import {resetSearch, TenderSearch} from "../../../hooks/slices/tenderSearchSlice";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {useAppDispatch} from "../../../app/hooks";
import {updateIndividualTenderSearchState, usePutSavedSearchMutation} from "../../../hooks/slices/savedSearchesSlice";
import {useSearchParams} from "react-router-dom";
import {SavedSearchUtil} from "../../../utils/savedsearches";
import {showErrorPopup} from "../../../hooks/slices/snaccSlice";
import {AnimatePresence, motion} from "framer-motion";
import {ConfirmPopup} from "../../../components/popup/Popup";
import {HOVER} from "../../../consts/FramerMotionVariants";
import {GearIcon, TrashIcon} from "../../../components/icons";
import {SavedSearchDetails} from "../../savedSearchSettings";
import {
    CompanySuggestion,
    CompanySuggestionsSource,
    GetCompaniesByVatNumberInParams, useGetCompaniesByVatNumberInQuery
} from "../../../hooks/slices/companySlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {CompanyUtil} from "../../../utils/company";
import {CodeUtil, CpvUtil} from "../../../utils/codes";
import {SearchFilterUtil} from "../../../utils/searchFilters";
import {MultilingualFieldUtil} from "../../../utils/multilingualfield";
import {CodeBranch} from "../../../types/code";

export interface SavedSearchCardProps {
    userUuid: string;
    savedSearch: TenderSearch;
    curatedProfile: boolean;
    deleteSavedSearch: (savedSearch: TenderSearch) => void;
}

export const SavedSearchCard: React.FC<SavedSearchCardProps> = ({
                                                             userUuid,
                                                             savedSearch,
                                                             deleteSavedSearch,
                                                             curatedProfile,
                                                         }) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    // Create a reference to the SavedSearchCard component
    const cardRef = useRef<HTMLDivElement>(null);
    const [updateSavedSearchBackend, { data: updatedSavedSearch, isLoading, isSuccess, isError, error }] =
        usePutSavedSearchMutation();
    const [searchParams] = useSearchParams();
    const [current, setCurrent] = useState(savedSearch);
    const canBeEdited = !curatedProfile;
    let savedSearchName: string = SavedSearchUtil.getOrCreateSavedSearchName(current, t);
    const [editModeOn, setEditModeOn] = useState<boolean>(canBeEdited && searchParams.get("profile") === savedSearchName);
    const undefinedIfFalse = (b: boolean): boolean | undefined => {
        if (b) return b;
        else return undefined;
    }
    useEffect(() => {
        if (editModeOn && (cardRef.current != null)) {
            cardRef.current.scrollIntoView({ behavior: 'smooth', block: 'center'});
        }
    }, [editModeOn, cardRef.current]);
    const updateSavedSearch = (nuw: TenderSearch) => {
        let updated: TenderSearch = {
            uuid: current.uuid,
            userUuid: userUuid,
            isActive: current.isActive,
            languageIsos: nuw.languageIsos ? nuw.languageIsos : current.languageIsos,
            createdAt: current.createdAt,
            updatedAt: current.updatedAt,
            name: nuw.name !== undefined ? nuw.name : current.name,
            fieldGroups:
                nuw.fieldGroups !== undefined
                    ? nuw.fieldGroups
                    : current.fieldGroups,
            containsFields: nuw.containsFields !== undefined ? nuw.containsFields : current.containsFields,
            subjectCodes:
                nuw.subjectCodes !== undefined ? nuw.subjectCodes : current.subjectCodes,
            regionCodes: nuw.regionCodes !== undefined ? nuw.regionCodes : current.regionCodes,
            accreditations:
                nuw.accreditations !== undefined ? nuw.accreditations : current.accreditations,
            contractTypes: current.contractTypes,
            // countries: localSavedSearch.countries, //saves searches should not include countries, these are set on the main query
            query: nuw.query !== undefined ? nuw.query : current.query,
            isFrameworkAgreement: nuw.isFrameworkAgreement !== undefined ? undefinedIfFalse(nuw.isFrameworkAgreement) : current.isFrameworkAgreement,
            isShelteredWorkshop: nuw.isShelteredWorkshop !== undefined ? undefinedIfFalse(nuw.isShelteredWorkshop) : current.isShelteredWorkshop,
            isShelteredProgram: nuw.isShelteredProgram !== undefined ? undefinedIfFalse(nuw.isShelteredProgram) : current.isShelteredProgram,
            contractingAuthorityVatNumbers: nuw.contractingAuthorityVatNumbers !== undefined ? nuw.contractingAuthorityVatNumbers : current.contractingAuthorityVatNumbers,
            participantVatNumbers: nuw.participantVatNumbers !== undefined ? nuw.participantVatNumbers : current.participantVatNumbers,
        };
        updateSavedSearchBackend(updated);
        setCurrent(updated);
    };
    const [showConfirmPopup, setShowConfirmPopup] = useState(false);

    const deleteProfileAndUpdateOpportunities = () => {
        deleteSavedSearch(current);
        dispatch(resetSearch());
        setShowConfirmPopup(false);
    };

    const toggleEditMode = () => {
        if (canBeEdited) setEditModeOn(!editModeOn);
    };
    useEffect(() => {
        if (isError && error) dispatch(showErrorPopup(error));
        if (isSuccess && updatedSavedSearch) {
            setCurrent(updatedSavedSearch);
            //update store so opportunities are refreshed
            dispatch(updateIndividualTenderSearchState(updatedSavedSearch));
            dispatch(resetSearch());
        }
    }, [updatedSavedSearch, isError, error]);
    return (
        <>
            <AnimatePresence>
                {showConfirmPopup && (
                    <ConfirmPopup
                        title={t("settings.deleteProfileTitle")}
                        message={t("settings.deleteProfileMessage")}
                        confirm={deleteProfileAndUpdateOpportunities}
                        close={() => setShowConfirmPopup(false)}
                    />
                )}
            </AnimatePresence>
            <div className="filter-grid-border-style" data-cy={"user-profile-card"} ref={cardRef}>
                <div className="icon-text-filter-alignment" >
                    <h2
                        style={current.name ? {} : { color: "var(--bg-light-grey)" }}
                        title={
                            current.name
                                ? t("settings.profile_name_placeholder")
                                : t("settings.name_this_profile_for_example") + savedSearchName
                        }
                        data-cy={"user-profile-card-title"}
                    >
                        {savedSearchName}
                    </h2>
                    <div className="setting-icon-end-side">
                        {canBeEdited && (
                            <motion.a variants={HOVER} initial={"hidden"} whileHover={"visible"} onClick={toggleEditMode} title={t("settings.editProfileTitle")}>
                                <GearIcon />
                            </motion.a>
                        )}
                        <motion.a variants={HOVER} initial={"hidden"} whileHover={"visible"}
                                  data-cy={"remove-user-profile-button"} title={t("settings.deleteProfileTitle")} onClick={() => setShowConfirmPopup(true)}>
                            <TrashIcon />
                        </motion.a>
                    </div>
                </div>
                {!editModeOn && <SavedSearchSummary savedSearch={current} curatedProfile={curatedProfile} />}
                <AnimatePresence>
                    {editModeOn && (
                        <SavedSearchDetails
                            userUuid={userUuid}
                            savedSearch={current}
                            updateSavedSearch={updateSavedSearch}
                            isLoading={isLoading}
                        />
                    )}
                </AnimatePresence>
            </div>
        </>
    );
};

const SavedSearchSummary: React.FC<{ savedSearch: TenderSearch; curatedProfile: boolean }> = ({
                                                                                                  savedSearch,
                                                                                                  curatedProfile,
                                                                                              }) => {
    const { t } = useTranslation();
    const [contractingAuthorities, setContractingAuthorities] = useState<CompanySuggestion[]>([]);
    let companiesQuery: GetCompaniesByVatNumberInParams | undefined = {
        vatNumbers: savedSearch.contractingAuthorityVatNumbers ?? [],
        source: CompanySuggestionsSource.COMPANY_PAGE_BUYERS
    }; if (savedSearch.contractingAuthorityVatNumbers == null || savedSearch.contractingAuthorityVatNumbers.length === 0){
        companiesQuery = undefined; //this makes sure the endpoint isn't called unnecessarily
    }
    const {data, isLoading: fetchingCompanies, isSuccess, error} = useGetCompaniesByVatNumberInQuery(companiesQuery ?? skipToken);
    useEffect(() => {
        if (isSuccess && data) {
            setContractingAuthorities(CompanyUtil.sortCompanySuggestionsByName(data.suggestions));
        }
    }, [data, fetchingCompanies, isSuccess, error]);
    let messages: string[] = [];
    let languages = savedSearch.languageIsos ? savedSearch.languageIsos : [];
    let cuesFragment = SavedSearchUtil.getCuesFragment(savedSearch, 3);
    let cues = SavedSearchUtil.getCues([savedSearch]);
    if (curatedProfile) messages.push(t("savedSearchSettings.expertManaged"));
    else {
        if (cues.length > 0) {
            messages.push(
                cues.length +
                " " +
                t("savedSearchSettings.keyWords") +
                " (" +
                languages.join(", ") +
                "): " +
                cuesFragment);
        }
        if (savedSearch.subjectCodes !== undefined) {
            let subjectCodeRoots: string[] = getSubjectCodeBranchRoots(savedSearch.subjectCodes);
            if (subjectCodeRoots.length > 0)
                messages.push(t("savedSearchSettings.subjectCodes") + ": " + subjectCodeRoots.join(", "));
        }
        if (savedSearch.regionCodes !== undefined) {
            let regionCodeRoots: string[] = getRegionCodeBranchRoots(savedSearch.regionCodes);
            if (regionCodeRoots.length > 0)
                messages.push(t("savedSearchSettings.regionCodes") + ": " + regionCodeRoots.join(", "));
        }
        if (savedSearch.accreditations !== undefined) {
            let accreditations: string[] = savedSearch.accreditations.map((a) => CodeUtil.convertCodeWithClassToString(a));
            if (accreditations.length > 0)
                messages.push(t("savedSearchSettings.accreditations") + ": " + (accreditations.length > 3 ? accreditations.slice(0,3).join(", ") + "..." : accreditations.join(", ")));
        }
        if (savedSearch.containsFields !== undefined) {
            let publicationTypes: string[] = SearchFilterUtil.translateContainsFieldsFiltersBack(savedSearch.containsFields).map((a) => t("searchFilters." + a));
            if (publicationTypes.length > 0)
                messages.push(t("savedSearchSettings.formTypes") + ": " + publicationTypes.sort().join(", "));
        }
        if (contractingAuthorities !== undefined && contractingAuthorities.length > 0) {
            let names: string[] = contractingAuthorities.map((c) => MultilingualFieldUtil.translate(c.name, "nl"));
            if (names.length > 3) names = [...names.slice(0, 3), "..."];
            messages.push(t("savedSearchSettings.contractingAuthorities") + ": " + names.join(", "));
        }
    }
    return (
        <>
            <div className="filter-grid">
                <div className="filter-grid-items">
                    {messages.map((m, i) => (
                        <a key={i}>{m}</a>
                    ))}
                </div>
            </div>
        </>
    );
};

function getRegionCodeBranchRoots(codes: CodeBranch[]) {
    let regionCodeRoots: string[] = [];
    let sortedCodes: string[] = codes.map((c) => c.code).sort(); //BE,BE1,FR25
    for (const sc of sortedCodes) {
        let rootAlreadyPresent = regionCodeRoots.filter((c) => sc.startsWith(c)).length > 0;
        if (!rootAlreadyPresent) regionCodeRoots.push(sc);
    }
    return regionCodeRoots;
}

function getSubjectCodeBranchRoots(codes: CodeBranch[]): string[] {
    let subjectCodeRoots: string[] = [];
    let sortedCodes: string[] = codes.map((c) => c.code).sort(); //15000000,45100000
    for (const sc of sortedCodes) {
        let root = CpvUtil.getRoot(sc);
        let rootAlreadyPresent = subjectCodeRoots.filter((c) => root.startsWith(c)).length > 0;
        if (!rootAlreadyPresent) subjectCodeRoots.push(root);
    }
    return subjectCodeRoots;
}