import React, { HTMLAttributes } from 'react';
import styles from './index.module.scss';

import arrowDown from '../../../assets/icons/arrow-down.svg';


export interface OptionProps extends HTMLAttributes<HTMLDivElement> {
    value: string;
    isHeader?: boolean;
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
}

export const Option: React.FC<OptionProps> = ({ 
    value, 
    onClick, 
    isHeader=false,
    children,
    ...props
}) => (
    <div 
        className={styles['container']}
        onClick={onClick}
        {...props}
    >
        {children}
        {isHeader && <img src={arrowDown} alt="arrow down"/>}
    </div>
);