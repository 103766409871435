import styles from "./index.module.scss";
import React from "react";

export interface CheckboxProps {
    label: string;
    selected: boolean;
    onChange: () => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({label, onChange, selected}) => {
    return <>
        <div className={styles['checkbox-singleton-alignment']}>
            <div><span>{label}</span></div>
            <div>
                <label className={styles['switch']}>
                    <input id="checkboxinp" type="checkbox"
                           checked={selected}
                           onChange={onChange}/>
                    <div className={styles['slider']}></div>
                </label>
            </div>
        </div>
    </>
}