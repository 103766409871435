import React from 'react';
import styles from './index.module.scss';


export interface TabPanelProps extends React.HTMLAttributes<HTMLDivElement> {
    isActive?: boolean;
}


export const TabPanel: React.FC<TabPanelProps> = ({ 
    children, 
    isActive=true,
    ...props 
}) => {
    return (<div 
        {...props}
        className={`
            ${styles['tab-panel']} 
            ${isActive ? styles['active'] : ''}
        `}
    >
        {children}
    </div>);
}