import styles from "./index.module.scss";
import React from "react";

export const IconWithLink = ({icon, text}: {icon: JSX.Element, text: string}) => {
    const openInNewTab = (url: string) => {
        if (url.startsWith("www")) url = "https://" + url;
        if (url.startsWith('http')) window.open(url, '_blank', 'noopener, noreferrer');
    };
    return <div className={styles["icon-with-text"]}>
        <div className={styles["icon"]}>{icon}</div>
        <p style={{cursor: 'pointer'}} onClick={() => openInNewTab(text)}>{text}</p>
    </div>
}