import React from 'react';
import styles from './index.module.scss';

import { TabPanel } from '..';


export interface TabPanelContractedClosedProps extends React.HTMLAttributes<HTMLDivElement> {
    isActive?: boolean;
}
export const TabPanelContractedClosed: React.FC<TabPanelContractedClosedProps> = ({ 
    children, 
    isActive=false,
    ...props 
}) => {
    return (<TabPanel 
        className={styles['tab-panel-contracted-closed']}
        isActive={isActive}
        {...props}
    >
        Contracted Closed Tab Panel
    </TabPanel>);
}
