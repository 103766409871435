import React from 'react';
import styles from './index.module.scss';

import { TabPanel } from '..';


export interface TabPanelContractedOpenProps extends React.HTMLAttributes<HTMLDivElement> {
    isActive?: boolean;
}
export const TabPanelContractedOpen: React.FC<TabPanelContractedOpenProps> = ({ 
    children, 
    isActive=false,
    ...props 
}) => {
    return (<TabPanel 
        className={styles['tab-panel-contracted-open']}
        isActive={isActive}
        {...props}
    >
        Contracted Open Tab Panel
    </TabPanel>);
}
