import { FlagIcon } from "../../../flags/flags";
import { getCountry } from "./getCountry";
import styles from "./index.module.scss";
import globe from "../../../../assets/icons/globe.svg";

interface LanguageItemProps extends React.HTMLAttributes<HTMLDivElement> {
    language: string
}

export const LanguageItem: React.FC<LanguageItemProps> = ({
    language,
    className=null,
    ...props
}) => {
    return <div
        className={className ? `${className}` : styles["language-item"]}
        {...props}
    >
        <img src={globe} alt="globe"/>
        {language.toUpperCase()}
    </div>
}
