import React from 'react';
import styles from './index.module.scss';


export interface TagListProps extends React.HTMLAttributes<HTMLDivElement> {
}
export const TagList : React.FC<TagListProps> = ({
    children, 
    ...props}
) => {
    return (<div 
        className={styles['tag-list']}
        {...props}
    >
        {children}
    </div>);
}