import React from 'react';
import styles from './index.module.scss';

import { TabPanel } from '..';


export interface TabPanelTendersRecentlyParticipatedInProps extends React.HTMLAttributes<HTMLDivElement> {
    isActive?: boolean;
}
export const TabPanelTendersRecentlyParticipatedIn: React.FC<TabPanelTendersRecentlyParticipatedInProps> = ({ 
    children, 
    isActive=false,
    ...props 
}) => {
    return (<TabPanel 
        className={styles['tab-panel-tenders-recently-participated-in']}
        isActive={isActive}
        {...props}
    >
        {children}
    </TabPanel>);
}
