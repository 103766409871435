import { ResourceKey } from "i18next";

export const CompanyPage: ResourceKey = {
    accreditations_title: "Accréditations",
    add_crm: "+ CRM",
    add_to_list: "Ajouter à la liste",
    award: "attribution",
    bid: "offre",
    company_experience_expert: "Expert",
    company_experience_experienced: "Expérimenté",
    company_experience_average: "Moyen",
	company_experience_basic: "Basique",
    company_experience_beginner: "Débutant",
    company_experience_title: "Expérience",
    company_experience_last_5_years: "marchés / 5 dernières années",
    company_information_section_title: "Informations sur l'entreprise",
    company_name: "Organisation",
    competitors: "Concurrents",
    copied_to_clipboard_title_1: 'Texte "',
    copied_to_clipboard_title_: '" copié dans le presse-papiers',

	win_ratio_title_1: "Ratio de gain",
	win_ratio_title_2: "dans les marchés publics",

    disclaimer: "La page d'entreprise est une fonctionnalité bêta et un premier pas vers une véritable analyse de marché. Envoyez vos commentaires à l'adresse suivante : info@tenderwolf.com",

    financials: "Finances",
    focus: "Focus",
    follow_company: "Suivre cette entreprise: tenez-moi informé lorsque cette entreprise participe à un marché public et fournissez un aperçu dans l'onglet 'Attribution'",
    follow_short: "Suivre",
    followed_company: "Entreprise suivie: ",
    lookalikes: "Similaires",
    main_activities_section_title: "Types de marchés publics",
    main_buyers_section_title: "Principaux acheteurs",
    main_competitors_section_title: "Principaux concurrents",
    main_suppliers_section_title: "Principaux fournisseurs",
    projects_contracted_title_1: "Pouvoir adjudicateur dans",
    projects_contracted_title_2: "marchés publics",
    recent_activity_section_title: "Marchés publics récents",
    recently_participated_in_section_title: "Participé",
    contracted_open_section_title: "Marchés publics ouverts",
    contracted_closed_section_title: "Marchés publics fermés",
    recently_won_section_title: "Gagné",
    shared_projects_title: "Projets partagés",
    show_company_page: "Afficher la page d'entreprise",
    tendering: "Appels d'offres",
    tenders_contracted_closed_title: "Appels d'offres récemment lancés",
    tenders_contracted_open_title: "Appels d'offres ouverts",
    total_results: "Nombre total de résultats",
    unknown_company: "Entreprise inconnue",
    direct_competitor_title_1: "Concurrent direct",
    direct_competitor_title_2: "marchés publics",
    shared_projects_title_1: "Attribution",
    shared_projects_title_2: "à l'entreprise ci-dessus",


    company_info_details_title: "Détails",
    company_info_contact_title: "Contact",
    company_info_activities_title: "Activités",
    company_info_accreditations_title: "Accréditations",
    company_info_average_lot_value_title: "Valeur moyenne du lot",
    company_info_start_date_title: "Date de début",

    price: "Prix",
    quality: "Qualité",
    balanced: "Hybride",
    orientation: "Focus",
    priceOriented: "Prix",
    qualityOriented: "Qualité",
    averageLotValue: "Taille",
    verySmallScale: "Très petit",
    smallScale: "Petit",
    mediumScale: "Moyen",
    largeScale: "Grand",
    veryLargeScale: "Très grand",

    government_category_title: "Gouvernements",
    company_category_title: "Entreprises",
    has_tender_experience: "Actif dans les marchés publics",

};
