import React from "react";
import { CompanyCard } from "../../../../components/companySummaryCard";
import { IconWithTextAndCopyButton } from "../../../../components/iconWithTextAndCopyButton";
import { CompanyIcon23, LocationIcon, WebsiteIcon } from "../../../../components/icons";
import { CompanyDto } from "../../../../hooks/slices/companySlice";
import { IconWithLink } from "../../../../components/iconWithLink";


export interface CompanyPageHeaderInfoElementsProps {
    vatNumber: string;

    city?: string;
    postalCode?: string;
    street?: string;
    website?: string;
}

export const CompanyPageHeaderInfoElements: React.FC<CompanyPageHeaderInfoElementsProps> = ({
    vatNumber,

    city = null,
    postalCode = null,
    street = null,
    website = null,
}) => {
    let address = "";
    if (street) address += street;
    if (postalCode) address += ", " + postalCode;
    if (city) address += " " + city;

    return (<div>
        <IconWithTextAndCopyButton icon={<CompanyIcon23/>} text={vatNumber.replace("BE", "")}/>
        {(address !== "") && <IconWithTextAndCopyButton icon={<LocationIcon/>} text={address}/>}
        {website && <IconWithLink icon={<WebsiteIcon/>} text={website}/>}
    </div>)
}
