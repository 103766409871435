import { ResourceKey } from "i18next";

export const SavedSearchSettings: ResourceKey = {
	generalSettings: "Général",
	settings: "Paramètres",
	keyWords: "Mots-clés",
	copyKeyWords: "Télécharger",
	resetKeyWords: "Supprimer tout",
	batchInsertKeyWords: "Insérer une liste",
	subjectCodes: "CPV",
	regionCodes: "NUTS",
	accreditations: "Agréations",
	formTypes: "Types de formulaires",
	contractingAuthorities: "Gouvernements",
	save: "Sauvegarder",
	accountSettings: "Compte",
	newKeyword: "Nouveau mot-clé",
	pasteKeywords: "Collez vos mots-clés ici",
  duplicateKeywords : "Les mots-clés contiennent des doublons",
  illegalQuotesInKeywords : "Les mots-clés contiennent des guillemets, veuillez les remplacer par des guillemets droits (', \")",
	tooManyKeywords: "Trop de mots-clés (max. 500)",
	keywordTooLong: "Un de vos mots-clés est trop long",
	addProfile: "Ajouter profil",
	name: "Nom du profil",
  invalidProfileName: "Nom du profil invalide",
	defaultName: "Profil de sélection",
	SavedSearchFilterButtonTitle: "Profil",
	confirmRemove: "Êtes-vous sûr de vouloir supprimer ce profil ?",
	expertManaged: "Ce profil est géré pour vous par TenderWolf. Si vous avez besoin de plus de flexibilité, contactez notre expert en recherche d'appels d'offres.",
	noResults: "Aucun résultat"

};
