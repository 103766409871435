import React from 'react';
import styles from './index.module.scss';

export interface TabsContainerProps extends React.HTMLAttributes<HTMLDivElement> {
}
export const TabsContainer: React.FC<TabsContainerProps>  = ({
    children,
    ...props
}) => {
    return (<div
        className={styles['tabs-container']}
        { ...props }
    >
        { children }
    </div>);
}