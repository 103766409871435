import React, { useEffect } from "react";
import "./results/searchResults.scss";
import { SearchSidebar } from "./sidebar/SearchSidebar";
import { SearchResults, SearchResultsSkeleton } from "./results/SearchResults";
import { SearchFilter } from "../../consts/SearchFilter";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
    changeSearchType, DateFilter, DateFilterName,
    resetSearchToInitialState,
    SearchFilterState,
    TenderSearchInput, updateSearchFilters
} from "../../hooks/slices/tenderSearchSlice";
import { UserState, UserInteraction } from "../../hooks/slices/userSlice";
import { ErrorPage } from "../error";
import { SearchType } from "../../consts/searchType";
import { TenderCardConfiguration } from "./results/body/TenderCard";
import { RaiseUser, UserInStore } from "../../hooks/raiseUser";
import { SavedSearchUtil } from "../../utils/savedsearches";
import {SearchUrlUtil} from "../../utils/searchUrlUtil";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { Feature } from "../../consts/features";
import { CurrentPlanInStore, RaiseCurrentPlan } from "../../hooks/raiseCurrentPlan";
import { useTranslation } from "react-i18next";
import { RaiseSavedSearches } from "../../hooks/raiseSavedSearches";
import { TenderSearchesState } from "../../hooks/slices/savedSearchesSlice";
import {DateFilterUtil} from "../../utils/dateFilters";

export interface GenericSearchPageProps{
    initialSearchFilterState: SearchFilterState;
    searchConfiguration: SearchConfiguration;
}

export interface SearchConfiguration{
    sidebar: SearchSidebarConfiguration;
    info: SearchResultsConfiguration;
    tenderCard: TenderCardConfiguration;
}

export interface SearchResultsConfiguration {
    showFilterButtons?: boolean;
    hiddenFilters?: SearchFilter[];
    lockedFilters?: SearchFilter[];
    showResponseTime?: boolean;
    showTenderUuidFilterCategories?: boolean;
    showExportButton?: boolean;
    showLabelAggregations?: boolean;
    showCueAggregations?: boolean;
    maxPages?: number;
    blockDateFilterButtons?: boolean;
}

export interface SearchSidebarConfiguration {
    queryType: SearchType;
    queryPlaceHolder: string;
    showLabels?: boolean;
    showAccreditations?: boolean;
    showSearchSectorFilter?: boolean;
    hiddenFilters?: SearchFilter[];
    lockedFilters?: SearchFilter[];
    showPublicationDateFilters?: boolean;
    showDeadlineDateFilters?: boolean;
    showAwardOrOpeningReportDateFilters?: boolean;
    showEstimatedRenewalDateFilters?: boolean;
}

export const GenericSearchPage: React.FC<GenericSearchPageProps> = ({
                                                                        initialSearchFilterState,
                                                                        searchConfiguration}) => {
    //DATA REQUIRED FOR SEARCH
    const userInStore: UserInStore = RaiseUser();
    // const profilesInStore: UserProfilesInStore = RaiseUserProfiles();
    const savedSearchesState: TenderSearchesState = RaiseSavedSearches().savedSearches;
    const currentPlan: CurrentPlanInStore = RaiseCurrentPlan();
    const searchInput: TenderSearchInput | undefined = useAppSelector((state) => state.tenderSearch.searchInput);
    searchConfiguration.tenderCard.showScoreInUpperRightCorner = searchInput?.mainInput.query !== undefined && searchInput?.mainInput.query.endsWith("knn");
    if(userInStore.user === undefined
        || savedSearchesState.tenderSearches === undefined
        || userInStore.user?.userInteractions === undefined
        || currentPlan.currentPlan?.features === undefined
    ) {
        return <GenericSearchPageSkeleton/>
    }
    else if(userInStore.error) return <ErrorPage error={userInStore.error}/>;
    else if(currentPlan.error) return <ErrorPage error={currentPlan.error}/>;
    return <><GenericSearchPageWithData
        searchConfiguration={searchConfiguration}
        initialSearchFilterState={initialSearchFilterState}
        user={userInStore.user}
        cues={SavedSearchUtil.getAllCues(savedSearchesState)} //todo maybe get rid of these
        interactions={userInStore.user?.userInteractions}
        features={currentPlan.currentPlan?.features}
    /></>

}

export interface GenericSearchPageWithDataProps {
    searchConfiguration: SearchConfiguration;
    initialSearchFilterState: SearchFilterState;
    user: UserState;
    cues: string[];
    interactions: UserInteraction[];
    features: Feature[];
}

//user, opportunities and interactions are needed down the road, but they are fetched directly from the store
export const GenericSearchPageWithData: React.FC<GenericSearchPageWithDataProps> = (props) => {
    const dispatch = useAppDispatch();
    const history = useNavigate();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const searchTypeInStore = useAppSelector((state) => state.tenderSearch.searchType);
    const reset: boolean | undefined = useAppSelector((state) => state.tenderSearch.resetSearchToInitialState);
    let userInteractions: UserInteraction[] = RaiseUser().user?.userInteractions ?? [];
    let searchTypeChanged: boolean = searchTypeInStore === undefined || props.searchConfiguration.sidebar.queryType !== searchTypeInStore;
    const hasSearchParams = searchParams.entries().next().done === false;
    useEffect(() => {
        if(searchTypeChanged || reset) {
            dispatch(changeSearchType({
                queryType: props.searchConfiguration.sidebar.queryType,
                newInitialSearchFilterState: props.initialSearchFilterState,
                userInteractions: userInteractions
            }));
        } if(reset) {
            dispatch(resetSearchToInitialState({reset: false}));
        }
    }, [props.searchConfiguration.sidebar.queryType, reset]);

    useEffect(() => {
        if(hasSearchParams) {
            adjustDateFiltersBasedOnPathParameters(props, searchParams);
            let updateProps = SearchUrlUtil.getUrlSearchParamsFilters(props.initialSearchFilterState, searchParams, userInteractions);
            dispatch(updateSearchFilters(updateProps));
            //this removes the parameters from the url after initializing them
            history(location.pathname, { replace: true })
        }
    }, [searchParams]);
    if(searchTypeChanged) return <GenericSearchPageSkeleton/>; //otherwise the change is too slow and an extra query is run
    return <>
        <SearchSidebar {...props.searchConfiguration}/>
        <SearchResults {...props}/>
    </>
}

export function adjustDateFiltersBasedOnPathParameters(props: GenericSearchPageWithDataProps, searchParams: URLSearchParams) {
    let selectedFilters: DateFilter[] = props.initialSearchFilterState.selectedDateFilters ? props.initialSearchFilterState.selectedDateFilters : [];
    let publicationDateParam = searchParams.get("publication_date");
    if (publicationDateParam ){
        if(publicationDateParam === "most_recent"){DateFilterUtil.select(DateFilterUtil.findByName(DateFilterName.PUBLICATION_DATE_YESTERDAY), selectedFilters);}
        if(publicationDateParam === "last_week"){DateFilterUtil.select(DateFilterUtil.findByName(DateFilterName.PUBLICATION_DATE_LAST_WEEK), selectedFilters);}
        if(publicationDateParam === "last_month"){DateFilterUtil.select(DateFilterUtil.findByName(DateFilterName.PUBLICATION_DATE_LAST_MONTH), selectedFilters);}
        if(publicationDateParam === "last_two_months"){DateFilterUtil.select(DateFilterUtil.findByName(DateFilterName.PUBLICATION_DATE_LAST_TWO_MONTHS), selectedFilters);}
        if(publicationDateParam === "last_year"){DateFilterUtil.select(DateFilterUtil.findByName(DateFilterName.PUBLICATION_DATE_LAST_YEAR), selectedFilters);}
    }
    let deadlinePathParam = searchParams.get("deadline_date");
    if(deadlinePathParam){
        if(deadlinePathParam === "everything"){DateFilterUtil.select(DateFilterUtil.findByName(DateFilterName.DEADLINE_EVERYTHING), selectedFilters);}
        if(deadlinePathParam === "not_yet_expired"){DateFilterUtil.select(DateFilterUtil.findByName(DateFilterName.DEADLINE_NOT_YET_EXPIRED), selectedFilters);}
    }
}
const GenericSearchPageSkeleton: React.FC = () => {

    const {t} = useTranslation();
    // return <SearchPageSkeleton/>;
    const dummyCardConfiguration: TenderCardConfiguration = {}

    const dummySearchViewConfiguration: SearchResultsConfiguration = {
        // showResponseTime:true,
        // showFilters: true
    }

    const dummySearchSidebarConfiguration: SearchSidebarConfiguration = {
        queryType: SearchType.DUMMY,
        queryPlaceHolder: t('searchFilterTags.search'),
    }

    const dummySearchConfiguration: SearchConfiguration = {
        sidebar: dummySearchSidebarConfiguration,
        info: dummySearchViewConfiguration,
        tenderCard: dummyCardConfiguration,
    }

    return <>
        <SearchSidebar {...dummySearchConfiguration}/>
        <SearchResultsSkeleton {...dummySearchConfiguration}/>
    </>
}





