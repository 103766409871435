import { 
    useEffect,
} from 'react';
import { 
    useTranslation, 
} from 'react-i18next';
import { 
    useAppDispatch, 
} from '../../app/hooks';
import { 
    UserInteractionType, 
} from '../../consts/UserInteractionType';
import { 
    CompanyDto, 
} from '../../hooks/slices/companySlice';
import { 
    addCompanyPageInteraction,
    addUserInteraction,
    removeCompanyPageInteraction,
    removeUserInteraction,
    useAddUserInteractionMutation, 
    useRemoveUserInteractionMutation,
    UserInteraction,
    UserInteractionInput, 
} from '../../hooks/slices/userSlice';
import { 
    showCustomSuccessPopup,
    showErrorPopup, 
} from '../../hooks/slices/snaccSlice';


export const useFollowOrUnfollowCompany = (
    userUuid: string | undefined, 
    companyIsFollowed: boolean, 
    company: CompanyDto,
)=> {
    let {t} = useTranslation();
    const [follow, {isSuccess, isError, error}] = useAddUserInteractionMutation();
    const [unfollow, {isError: unfollowError, error: unfollowErrorObject}] = useRemoveUserInteractionMutation();
    const dispatch = useAppDispatch();
    const followOrUnfollowCompany = () => {
        if (userUuid){
            if (!companyIsFollowed){
                let newInteraction: UserInteraction = { 
                    uuid: company.uuid, 
                    interactionType: UserInteractionType.FOLLOW_COMPANY,
                };
                let interactionInput: UserInteractionInput = { 
                    userUuid: userUuid, 
                    interaction: newInteraction, 
                };
                //update DB
                follow(interactionInput);
                // update search state
                dispatch(addUserInteraction(interactionInput.interaction)); 
                dispatch(addCompanyPageInteraction({
                    companyPageUuid: company.uuid, 
                    companyPageVatNumber: company.vatNumber, 
                    companyPageName: company.name,
                })); 
            } else {
                let interaction: UserInteraction = { 
                    uuid: company.uuid, 
                    interactionType: UserInteractionType.FOLLOW_COMPANY, 
                };
                let interactionInput: UserInteractionInput = { 
                    userUuid: userUuid, 
                    interaction: interaction, 
                };
                //update DB
                unfollow(interactionInput);
                //update search state
                dispatch(removeUserInteraction(interactionInput.interaction)); 
                dispatch(removeCompanyPageInteraction({companyPageUuid: company.uuid})); 
            }
        }
    }
    useEffect(() => {
        if (isSuccess) 
            dispatch(showCustomSuccessPopup(t("companyPage.followed_company") + " " + company.name));
        else if (isError && error) 
            dispatch(showErrorPopup(error));
        else if (unfollowError && unfollowErrorObject) 
            dispatch(showErrorPopup(unfollowErrorObject));
    }, [isSuccess, error, isError, unfollowError, unfollowErrorObject]);
    return followOrUnfollowCompany;
}