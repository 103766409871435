import React from 'react';

import styles from './index.module.scss';

import { 
    FlagIcon,
    FlagsForTender,
} from '../../../flags/flags';

import { Country } from '../../../../consts/countries';
import { Tender } from '../../../../types/tender';


export interface RecentTenderPreviewProps extends React.HTMLAttributes<HTMLDivElement> {
    contractingAuthorityCountryIso: string;
    constractingAuthorityName: string;
    description: string;
    publicationDate: string;
    tender: Tender;
    title: string;
}


export const RecentTenderPreview: React.FC<RecentTenderPreviewProps> = ({
    contractingAuthorityCountryIso,
    constractingAuthorityName,
    description,
    publicationDate,
    tender,
    title,
    ...props
}) => {
    return (<div 
        className={styles['recent-tender-preview']} 
        {...props}
    >
        <div className={styles['contracting-authority']}>
            <FlagIcon country={contractingAuthorityCountryIso as Country} />
            {constractingAuthorityName}
        </div>
        <div className={styles['title']}>
            <FlagsForTender tender={tender}/>  {title}
        </div>
        <div className={styles['description']}>{description}</div>
        <div className={styles['publication-date']}>{publicationDate}</div>
    </div>);
}