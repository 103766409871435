import { ResourceKey } from "i18next";

export const CompanyPage: ResourceKey = {
    accreditations_title: "Erkenningen",
    add_crm: "+ CRM",
    add_to_list: "Toevoegen aan lijst",
    award: "gunning",
    bid: "offerte",
    company_experience_expert: "Expert",
    company_experience_experienced: "Ervaren",
    company_experience_average: "Gemiddeld",
	company_experience_basic: "Basis",
    company_experience_beginner: "Beginner",
    company_experience_title: "Ervaring",
    company_experience_last_5_years: "opdrachten / laatste 5 jaar",
    company_information_section_title: "Bedrijfsinformatie",
    company_name: "Organisatie",
    competitors: "Concurrenten",
    copied_to_clipboard_title_1: 'Tekst "',
    copied_to_clipboard_title_: '" gekopieerd naar het klembord',
    disclaimer: "De bedrijfspagina is een beta-feature, en een eerste stap naar volwaardige marktanalyse. Geef feedback via info@tenderwolf.com",
    direct_competitor_title_1: "Directe concurrent in",
    direct_competitor_title_2: "aanbestedingen",
    shared_projects_title_1:"Gunningen",
    shared_projects_title_2:"aan bovenstaand bedrijf",

	win_ratio_title_1: "Winst ratio",
	win_ratio_title_2: "in overheidsopdrachten",

    financials: "Financiën",
    focus: "Focus",
    follow_company: "Volg dit bedrijf: hou me op de hoogte wanneer dit bedrijf deelneemt aan een overheidsopdracht en geef een overzicht in het gunningstabblad",
    follow_short: "Volgen",
    followed_company: "Gevolgd bedrijf: ",
    lookalikes: "Gelijkaardige bedrijven",
    main_activities_section_title: "Soorten overheidsopdrachten",
    main_buyers_section_title: "Belangrijkste kopers",
    main_competitors_section_title: "Belangrijkste concurrenten",
    main_suppliers_section_title: "Belangrijkste leveranciers",
    projects_contracted_title_1: "Aanbestedende overheid in",
    projects_contracted_title_2: "aanbestedingen",
    recent_activity_section_title: "Recente overheidsopdrachten",
    recently_participated_in_section_title: "Deelgenomen",
    contracted_open_section_title: "Openstaande opdrachten",
    contracted_closed_section_title: "Afgesloten opdrachten",
    recently_won_section_title: "Gewonnen",
    shared_projects_title: "Gedeelde projecten",
    show_company_page: "Toon bedrijfspagina",
    tendering: "Tendering",
    tenders_contracted_closed_title: "Recent uitgeschreven tenders",
    tenders_contracted_open_title: "Openstaande tenders",
    total_results: "Totaal aantal resultaten",
    unknown_company: "Ongekend bedrijf",

    company_info_details_title: "Details",
    company_info_contact_title: "Contact",
    company_info_activities_title: "Activiteiten",
    company_info_accreditations_title: "Erkenningen",
    company_info_average_lot_value_title: "Gemiddelde lotwaarde",
    company_info_start_date_title: "Startdatum",

    price: "Prijs",
    quality: "Kwaliteit",
    balanced: "Hybride",
    orientation: "Focus",
    priceOriented: "Prijsgericht",
    qualityOriented: "Kwaliteitsgericht",
    averageLotValue: "Omvang",
    verySmallScale: "Zeer klein",
    smallScale: "Klein",
    mediumScale: "Middel groot",
    largeScale: "Groot",
    veryLargeScale: "Zeer groot",

    government_category_title: "Overheden",
    company_category_title: "Bedrijven",
    has_tender_experience: "Actief in overheidsopdrachten",
};
