import styles from './index.module.scss';
import React from 'react';

import { TabPanel } from '..';


export interface TabPanelTendersRecentlyWonProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    isActive?: boolean;
}


export const TabPanelTendersRecentlyWon: React.FC<TabPanelTendersRecentlyWonProps> = ({ 
    children=null,
    isActive=false,
    ...props 
}) => {
    return (<TabPanel 
        className={`
            ${styles['tab-panel-tenders-recently-won']}
            search-filter-section-alignment
        `}
        isActive={isActive}
        {...props}
    >
        { children }
        {/* {recentTenders.map((recentTender, i) => (
            <RecentTenderPreview
                key={i}
                title={ MultilingualFieldUtil.translate(
                    recentTender.title,
                    languageIso,
                )}
                type={recentTender.type}
                publicationDate={recentTender.publicationDate}
                totalHits={recentTender.totalHits}
            />
        ))} */}
    </TabPanel>);
}
