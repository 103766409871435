import React from 'react';
import styles from './index.module.scss';


export interface HeaderProps extends React.HTMLAttributes<HTMLDivElement> {
}
export const Header: React.FC<HeaderProps> = ({ 
    children, 
    ...props
 }) => {
    return (<div
        className={styles.header}
        {...props}
    >
        { children }
    </div>);
};