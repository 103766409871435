import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {loginSlice} from "../hooks/slices/loginSlice";
import {FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE} from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import {userSlice} from "../hooks/slices/userSlice";
import {tenderSearchSlice} from "../hooks/slices/tenderSearchSlice";
import {clientSlice} from "../hooks/slices/clientSlice";
import {currentPlanSlice} from "../hooks/slices/currentPlanSlice";
import {apiSlice} from "../hooks/slices/apiSlice";
import {snaccSlice} from "../hooks/slices/snaccSlice";
import {versionSlice} from "../hooks/slices/versionSlice";
import {userEmailPageSlice} from "../hooks/slices/userEmailPageSlice";
import {savedSearchesSlice} from "../hooks/slices/savedSearchesSlice";
import {marketIntelligenceSearchSlice} from "../hooks/slices/companySlice";

const combinedReducer = combineReducers({
    version: versionSlice.reducer,
    tenderSearch: tenderSearchSlice.reducer,
    savedSearches: savedSearchesSlice.reducer,
    login: loginSlice.reducer,
    snacc: snaccSlice.reducer,
    user: userSlice.reducer,
    company: clientSlice.reducer,
    emailAddresses: userEmailPageSlice.reducer,
    currentPlan: currentPlanSlice.reducer,
    marketIntelligenceSearch: marketIntelligenceSearchSlice.reducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
});

// we use redux-persist to persist the redux store across a session
// so that the user doesn't need to log in every time they refresh the page
// see: https://github.com/rt2zz/redux-persist
const persistedReducer = persistReducer(
    {
        key: "root",
        storage: storageSession,
        blacklist: [apiSlice.reducerPath], // don't persist api call storage
    },
    combinedReducer
);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                // Redux persist actions aren't serializable, so we need to add an exception
                // for them, otherwise the redux store will log errors. See:
                // https://redux-toolkit.js.org/usage/usage-guide#use-with-redux-persist
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(apiSlice.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
