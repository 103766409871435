import styles from "./index.module.scss";
import {ProgressCircle} from "../../../progressCircle";
import React from "react";
import {useTranslation} from "react-i18next";
import {RecentTenderDto} from "../../../../hooks/slices/companySlice";
import {DateUtil} from "../../../../utils/date";
import {TFunction} from "i18next";
import {CompanyUtil} from "../../../../utils/company";

export interface CompanyLotValueProgressCircleProps {
    tenders: RecentTenderDto[];
}

export const CompanyLotValueProgressCircle: React.FC<CompanyLotValueProgressCircleProps> = ({tenders}) => {
    const {t} = useTranslation();
    let recentTenders: RecentTenderDto[] = tenders.filter(t => DateUtil.getDateMoment(t.publicationDate).isAfter(DateUtil.today().subtract(5, 'years')));
    let averageLotValue: number | undefined = CompanyUtil.getAverageLotValue(recentTenders);
    if (averageLotValue === undefined) return <> </>;
    if (averageLotValue === 0) return <> </>;
    let averageLotValueString = "€ " + averageLotValue.toLocaleString('nl-NL', {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
    });
    let progressPercentage = getProgressBarPercentage(averageLotValue);
    return <div className={styles['container']}>
        <p className={styles['top-text']}>{t("companyPage.averageLotValue")}</p>
        <ProgressCircle progressPercentage={progressPercentage}
                        size={100}
                        stroke={10}
                        text={getProgressCircleText(averageLotValue, t)}
                        color={"var(--pink)"}
        />
        <p className={styles['bottom-text']}>{averageLotValueString}</p>
    </div>
}

function getProgressCircleText(averageLotValue: number, t: TFunction): string {
    if (averageLotValue < 50_000) return t("companyPage.verySmallScale");
    if (averageLotValue < 200_000) return t("companyPage.smallScale");
    if (averageLotValue < 1_000_000) return t("companyPage.mediumScale");
    if (averageLotValue < 5_000_000) return t("companyPage.largeScale");
    return t("companyPage.veryLargeScale");
}

function getProgressBarPercentage(averageLotValue: number): number {
    if (averageLotValue < 50_000) return 20;
    if (averageLotValue < 200_000) return 40;
    if (averageLotValue < 1_000_000) return 60;
    if (averageLotValue < 5_000_000) return 80;
    return 100;
}