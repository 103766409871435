import { ResourceKey } from "i18next";

export const CompanyPage: ResourceKey = {
	accreditations_title: "Accreditations",
	add_crm: "+ CRM",
	add_to_list: "Add to list",
	award: "award",
	bid: "offer",
	company_experience_expert: "Expert",
	company_experience_experienced: "Experienced",
	company_experience_average: "Average",
	company_experience_basic: "Basic",
	company_experience_beginner: "Beginner",
	company_experience_title: "Experience",
	company_experience_last_5_years: "tenders / last 5 years",
	company_information_section_title: "Company information",
	company_name: "Organization",
	competitors: "Competitors",
	copied_to_clipboard_title_1: 'Text "',
	copied_to_clipboard_title_: '" copied to clipboard',
	direct_competitor_title_1: "Direct competitor in",
	direct_competitor_title_2: "public tenders",

    shared_projects_title_1:"Awarded",
    shared_projects_title_2:"to the company above",

	win_ratio_title_1: "Win ratio",
	win_ratio_title_2: "in public tenders",

	disclaimer: "The company page is a beta feature, and a first step toward full-blown market analysis. Submit feedback via info@tenderwolf.com",
	financials: "Financials",
	focus: "Focus",
	follow_company: "Follow this company: keep me informed when this company participates in a public tender and provide an overview in the awards tab",
	follow_short: "Follow",
	followed_company: "Followed company: ",
	lookalikes: "Lookalikes",
	main_activities_section_title: "Types of public tenders",
	main_buyers_section_title: "Main buyers",
	main_competitors_section_title: "Main competitors",
	main_suppliers_section_title: "Main suppliers",
	projects_contracted_title_1: "Contracting authority in",
	projects_contracted_title_2: "public tenders",
	recent_activity_section_title: "Recent public tenders",
	recently_participated_in_section_title: "Participated",
	contracted_open_section_title: "Open public tenders",
	contracted_closed_section_title: "Closed public tenders",
	recently_won_section_title: "Won",
	shared_projects_title: "Shared projects",
	show_company_page: "Show company page",
	tendering: "Tendering",
	tenders_contracted_closed_title: "Recently issued tenders",
	tenders_contracted_open_title: "Open tenders",
	total_results: "Total number of results: ",
	unknown_company: "Unknown company",

	company_info_details_title: "Details",
	company_info_contact_title: "Contact",
	company_info_activities_title: "Activities",
	company_info_accreditations_title: "Accreditations",
	company_info_average_lot_value_title: "Average lot value",
	company_info_start_date_title: "Start date",

	price: "Price",
	quality: "Quality",
	balanced: "Hybrid",
	orientation: "Focus",
	priceOriented: "Price-oriented",
	qualityOriented: "Quality-oriented",
	averageLotValue: "Scope",
	verySmallScale: "Very small",
	smallScale: "Small",
	mediumScale: "Medium",
	largeScale: "Large",
	veryLargeScale: "Very large",

	government_category_title: "Governments",
	company_category_title: "Companies",
	has_tender_experience: "Active in public tenders",

};
