import React from 'react';
import styles from './index.module.scss';

export interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: React.ReactNode;
    color?: 
        "red" | 
        "yellow" |
        "lightblue" |
        "blue" |
        "darkblue" |
        "pink" |
        "purple" |
        "lime" |
        "sand" |
        "moss" |
        "orange" |
        "green" |
        "teal" |
        "coral" |
        "brown" |
        "maroon" |
        "olive" |
        "turquoise" |
        "indigo" |
        "gold" |
        "aqua" |
        "magenta" |
        "navy" |
        "silver" |
        "lavender" |
        "plum" |
        "peach" |
        "mint" |
        "charcoal" |
        "amber" |
        "periwinkle" |
        "raspberry" |
        "jade" |
        "crimson" |
        "beige" |
        "lilac" |
        "aubergine" |
        "emerald" |
        "cobalt" |
        "honey" |
        undefined
    ;
}
export const Tag: React.FC<TagProps> = ({ 
    children,
    color=undefined, 
    ...props 
}) => {
    return (<div 
        className={`
            ${styles['tag']}
            ${color === 'red' ? styles['red'] : ''}
            ${color === 'yellow' ? styles['yellow'] : ''}
            ${color === 'lightblue' ? styles['lightblue'] : ''}
            ${color === 'blue' ? styles['blue'] : ''}
            ${color === 'darkblue' ? styles['darkblue'] : ''}
            ${color === 'pink' ? styles['pink'] : ''}
            ${color === 'purple' ? styles['purple'] : ''}
            ${color === 'lime' ? styles['lime'] : ''}
            ${color === 'sand' ? styles['sand'] : ''}
            ${color === 'moss' ? styles['moss'] : ''}
            ${color === 'orange' ? styles['orange'] : ''}
            ${color === 'green' ? styles['green'] : ''}
            ${color === 'teal' ? styles['teal'] : ''}
            ${color === 'coral' ? styles['coral'] : ''}
            ${color === 'brown' ? styles['brown'] : ''}
            ${color === 'maroon' ? styles['maroon'] : ''}
            ${color === 'olive' ? styles['olive'] : ''}
            ${color === 'turquoise' ? styles['turquoise'] : ''}
            ${color === 'indigo' ? styles['indigo'] : ''}
            ${color === 'gold' ? styles['gold'] : ''}
            ${color === 'aqua' ? styles['aqua'] : ''}
            ${color === 'magenta' ? styles['magenta'] : ''}
            ${color === 'navy' ? styles['navy'] : ''}
            ${color === 'silver' ? styles['silver'] : ''}
            ${color === 'lavender' ? styles['lavender'] : ''}
            ${color === 'plum' ? styles['plum'] : ''}
            ${color === 'peach' ? styles['peach'] : ''}
            ${color === 'mint' ? styles['mint'] : ''}
            ${color === 'charcoal' ? styles['charcoal'] : ''}
            ${color === 'amber' ? styles['amber'] : ''}
            ${color === 'periwinkle' ? styles['periwinkle'] : ''}
            ${color === 'raspberry' ? styles['raspberry'] : ''}
            ${color === 'jade' ? styles['jade'] : ''}
            ${color === 'crimson' ? styles['crimson'] : ''}
            ${color === 'beige' ? styles['beige'] : ''}
            ${color === 'lilac' ? styles['lilac'] : ''}
            ${color === 'aubergine' ? styles['aubergine'] : ''}
            ${color === 'emerald' ? styles['emerald'] : ''}
            ${color === 'cobalt' ? styles['cobalt'] : ''}
            ${color === 'honey' ? styles['honey'] : ''}
        `} 
        {...props}
    >
        {children}
    </div>);
};

