import React from 'react';
import styles from './index.module.scss';

import {
    Button,
    ButtonGroup,
    CompanyName,
    Header,
    Info,
    InfoItem,
    OverviewContainer,
    RecentTenderPreview,
    SubTab,
    SubTabList,
    Tab,
    TabDropdown,
    TabList,
    TabPanel,
    TabPanelBuyers,
    TabPanelCompetitors,
    TabPanelContractedClosed,
    TabPanelContractedOpen,
    TabPanelInfo,
    TabPanelMainActivities,
    TabPanelSuppliers,
    TabPanelTendersFocus,
    TabPanelTendersRecentlyParticipatedIn,
    TabPanelTendersRecentlyWon,
    TabsContainer,
    Tag,
    TagList,
} from './subComponents';


export interface CompanyCardProps extends React.HTMLAttributes<HTMLDivElement> {
}
export const CompanyCard: React.FC<CompanyCardProps> & {
    Button: typeof Button;
    ButtonGroup: typeof ButtonGroup;
    CompanyName: typeof CompanyName;
    Header: typeof Header;
    Info: typeof Info;
    InfoItem: typeof InfoItem;
    OverviewContainer: typeof OverviewContainer;
    RecentTenderPreview: typeof RecentTenderPreview;
    SubTab: typeof SubTab;
    SubTabList: typeof SubTabList;
    Tab: typeof Tab;
    TabDropdown: typeof TabDropdown;
    TabList: typeof TabList;
    TabPanel: typeof TabPanel;
    TabPanelBuyers: typeof TabPanelBuyers;
    TabPanelCompetitors: typeof TabPanelCompetitors;
    TabPanelContractedClosed: typeof TabPanelContractedClosed;
    TabPanelContractedOpen: typeof TabPanelContractedOpen;
    TabPanelInfo: typeof TabPanelInfo;
    TabPanelMainActivities: typeof TabPanelMainActivities;
    TabPanelSuppliers: typeof TabPanelSuppliers;
    TabPanelTendersFocus: typeof TabPanelTendersFocus;
    TabPanelTendersRecentlyParticipatedIn: typeof TabPanelTendersRecentlyParticipatedIn;
    TabPanelTendersRecentlyWon: typeof TabPanelTendersRecentlyWon;
    TabsContainer: typeof TabsContainer;
    Tag: typeof Tag;
    TagList: typeof TagList;

} = ({
    children,
    ...props
}) => {
    return (<div 
        className={styles['container']}
        {...props}
    >
        {children}
    </div>);
}

CompanyCard.Button = Button;
CompanyCard.ButtonGroup = ButtonGroup;
CompanyCard.CompanyName = CompanyName;
CompanyCard.Header = Header;
CompanyCard.Info = Info;
CompanyCard.InfoItem = InfoItem;
CompanyCard.OverviewContainer = OverviewContainer;
CompanyCard.RecentTenderPreview = RecentTenderPreview;
CompanyCard.SubTab = SubTab;
CompanyCard.SubTabList = SubTabList;
CompanyCard.Tab = Tab;
CompanyCard.TabDropdown = TabDropdown;
CompanyCard.TabList = TabList;
CompanyCard.TabPanel = TabPanel;
CompanyCard.TabPanelBuyers = TabPanelBuyers;
CompanyCard.TabPanelCompetitors = TabPanelCompetitors;
CompanyCard.TabPanelContractedClosed = TabPanelContractedClosed;
CompanyCard.TabPanelContractedOpen = TabPanelContractedOpen;
CompanyCard.TabPanelInfo = TabPanelInfo;
CompanyCard.TabPanelMainActivities = TabPanelMainActivities;
CompanyCard.TabPanelSuppliers = TabPanelSuppliers;
CompanyCard.TabPanelTendersFocus = TabPanelTendersFocus;
CompanyCard.TabPanelTendersRecentlyParticipatedIn = TabPanelTendersRecentlyParticipatedIn;
CompanyCard.TabPanelTendersRecentlyWon = TabPanelTendersRecentlyWon;
CompanyCard.TabsContainer = TabsContainer;
CompanyCard.Tag = Tag;
CompanyCard.TagList = TagList;