import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import {Feature, FeatureName} from "../../../../consts/features";
import { RaiseCurrentPlan } from "../../../../hooks/raiseCurrentPlan";
import { Moment } from "moment/moment";
import {
  DateFilter,
  DateFilterType,
  TenderSearchState,
  updateSearchFilters,
} from "../../../../hooks/slices/tenderSearchSlice";
import { DateUtil } from "../../../../utils/date";
import { DateFilterDropdown } from "./DateFilterDropdown";
import { CustomDateRangePicker } from "./CustomDateRangePicker";
import { RaiseUser } from "../../../../hooks/raiseUser";
import { DateFilterUtil } from "../../../../utils/dateFilters";
import { SearchType } from "../../../../consts/searchType";
import {FeatureUtil} from "../../../../utils/features";

interface DateRangePickerProps {
  type: DateFilterType;
  searchType: SearchType;
}

export const DateRangePicker: React.FC<DateRangePickerProps> = ({ type, searchType }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  let userInteractions = RaiseUser().user?.userInteractions ?? [];
  const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  const searchState: TenderSearchState = useAppSelector((state) => state.tenderSearch);
  let earliestStartDate: Moment | undefined = DateFilterUtil.getEarliestStartDateForUser(features);
  const showPin = FeatureUtil.hasFeature(features, FeatureName.DEFAULT_SEARCH_SETTINGS)
      && searchType === SearchType.OPPORTUNITIES
      && type === DateFilterType.PUBLICATION_DATE;
  const relevantDateFilters: DateFilter[] = DateFilterUtil.getRelevantDateFilters(
    type,
    earliestStartDate,
    searchType
  );
  let customDateFilter: DateFilter | undefined = relevantDateFilters.find((f) => f.name.endsWith("_CUSTOM"));
  const [customDatePopupOpen, setCustomDatePopupOpen] = React.useState(false);
  const select = (dateFilter: DateFilter) => {
    let currentDateFilters: DateFilter[] = searchState.searchFilters.selectedDateFilters
      ? searchState.searchFilters.selectedDateFilters
      : [];
    let newDateFilters: DateFilter[] = DateFilterUtil.select(dateFilter, currentDateFilters);
    dispatch(
      updateSearchFilters({
        dateFilters: newDateFilters,
        userInteractions: userInteractions,
      })
    );
  };
  const selectOrOpenCustomDateFilterPopup = (dateFilter: DateFilter) => {
    let isCustom = dateFilter.name.endsWith("_CUSTOM");
    if (isCustom) {
      setCustomDatePopupOpen(true);
    } else select(dateFilter);
  };
  const commitCustomDateRange = (start: Moment, end: Moment) => {
    if (customDateFilter !== undefined) {
      let startIsValid: boolean = (start.isValid() && (earliestStartDate === undefined || start.isSameOrAfter(earliestStartDate)));
      let endIsValid: boolean = (end.isValid() && (earliestStartDate === undefined || end.isSameOrAfter(earliestStartDate)));
      customDateFilter.startDate = startIsValid ? DateUtil.getDateString(start) : undefined;
      customDateFilter.endDate = endIsValid ? DateUtil.getDateString(end) : undefined;
      select(customDateFilter);
      setCustomDatePopupOpen(false);
    }
  };
  let defaultSelectedFilter = DateFilterUtil.getDefaultSelectedDateFilter(relevantDateFilters, searchType);
  if (relevantDateFilters.length === 0 || defaultSelectedFilter === undefined) return <></>;
  return (
    <>
      <DateFilterDropdown
        label={getTitle(type, t)}
        dateFilters={relevantDateFilters}
        defaultSelectedFilter={defaultSelectedFilter.name}
        select={selectOrOpenCustomDateFilterPopup}
        showPin={showPin}
      />
      {customDatePopupOpen && (
        <CustomDateRangePicker
          defaultStart={
            customDateFilter?.startDate ? DateUtil.findDateMoment(customDateFilter.startDate) : DateUtil.today()
          }
          minStart={earliestStartDate}
          defaultEnd={
            customDateFilter?.endDate ? DateUtil.findDateMoment(customDateFilter.startDate) : DateUtil.todayPlusYears(1)
          }
          commit={commitCustomDateRange}
            close={() => setCustomDatePopupOpen(false)}
        />
      )}
    </>
  );
};

function getTitle(type: DateFilterType, t: TFunction) {
    switch (type) {
        case DateFilterType.PUBLICATION_DATE: return t("publications.publicationDateTitle");
        case DateFilterType.DEADLINE: return t("publications.deadlineTitle");
        case DateFilterType.AWARD_OR_OPENING_REPORT_PUBLICATION_DATE: return t("publications.awardPublicationDateTitle");
        case DateFilterType.ESTIMATED_RENEWAL_PUBLICATION_DATE: return t("publications.estimatedRenewalPublicationDateTitle");
        default: return "";
    }
}
