import React from 'react';
import styles from './index.module.scss';


export interface TabProps extends React.HTMLAttributes<HTMLDivElement> {
    isActive?: boolean;
    isDropdownHeader?: boolean;
    isInDropdown?: boolean;
}
export const Tab: React.FC<TabProps> = ({
    children,
    isActive = false,
    isDropdownHeader = false,
    isInDropdown = false,
    ...props
}) => {
    return (<div 
        className={`
            ${styles['tab']}
            ${isActive ? styles['is-active'] : ''}
            ${isDropdownHeader ? styles['is-dropdown-header'] : ''}
            ${isInDropdown ? styles['is-in-dropdown'] : ''}
        `} 
        {...props} 
    >
        {children}
    </div>);
}