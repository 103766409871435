import React, { useEffect, useState } from "react";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";
import {
  CompanyPageDto,
  CompanySuggestionsSource, MarketIntelligenceSearchState,
  updateMarketIntelligenceSearch,
  useCompanySearchMutation,
} from "../../hooks/slices/companySlice";
import { CompanyOverviewCard } from "../../components/companyOverviewCard";
import { Language } from "../../consts/languages";
import { RaiseUser, UserLanguage } from "../../hooks/raiseUser";
import { QueryInput } from "../../components/queryInput";
import { Checkbox } from "../../components/checkbox";
import { CompanyPageInteraction } from "../../hooks/slices/userSlice";
import { Paginate } from "../../components/paginate";
import { CodeBranch, CodeType } from "../../types/code";
import { CodesAutocompleteDropdown } from "../search/sidebar/inputs/CodesAutoCompleteDropdown";
import { CodeUtil } from "../../utils/codes";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {FeatureUtil} from "../../utils/features";
import {Feature, FeatureName} from "../../consts/features";
import {ErrorPage} from "../error";
import {RaiseCurrentPlan} from "../../hooks/raiseCurrentPlan";

export const MarketIntelligence: React.FC = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const language: Language = UserLanguage(i18n.language);
  const [companies, setCompanies] = useState<CompanyPageDto[]>([]);
  const followedCompanyVatNumbers: CompanyPageInteraction[] = RaiseUser().user?.companyPageInteractions ?? [];
  const features: Feature[] | undefined = RaiseCurrentPlan().currentPlan?.features;
  const searchInStore: MarketIntelligenceSearchState = useAppSelector((state) => state.marketIntelligenceSearch);
  const [query, setQuery] = useState<string>(searchInStore.query ?? "");
  const [source, setSource] = useState<CompanySuggestionsSource>(searchInStore.source ?? CompanySuggestionsSource.COMPANY_PAGE_PARTICIPANTS);
  const [pageSize, setPageSize] = useState(searchInStore.pageSize ?? 20);
  const [page, setPage] = useState(searchInStore.page ?? 0);
  const [vatNumberFilters, setVatNumberFilters] = useState<string[]>(searchInStore.vatNumbers ?? []);
  const [cpvFilters, setCpvFilters] = useState<CodeBranch[]>(searchInStore.subjectCodes ?? []);
  const [nutsFilters, setNutsFilters] = useState<CodeBranch[]>(searchInStore.regionCodes ?? []);
  const [accreditationFilters, setAccreditationFilters] = useState<CodeBranch[]>(searchInStore.accreditations ?? []);
  const getCompanySearchQuery = (query: string): MarketIntelligenceSearchState => {
    let search: MarketIntelligenceSearchState = {
      query: query,
      source: source,
      language: language,
      page: page,
      pageSize: pageSize,
      vatNumbers: vatNumberFilters,
      subjectCodes: cpvFilters,
      regionCodes: nutsFilters,
      accreditations: accreditationFilters,
    }
    dispatch(updateMarketIntelligenceSearch(search));
    return search;
  };
  const [searchCompanies, { data, isLoading, isSuccess}] = useCompanySearchMutation();
  const filterFollowedCompanies = () => {
    if (vatNumberFilters.length === 0 && followedCompanyVatNumbers.length > 0) {
      setVatNumberFilters(followedCompanyVatNumbers.map((c) => c.companyPageVatNumber));
    } else setVatNumberFilters([]);
  };
  const toggleTenderExperience = () => {
    if (source === CompanySuggestionsSource.COMPANY_PAGE_ANY) {
      setSource(CompanySuggestionsSource.COMPANY_PAGE_PARTICIPANTS);
    } else {
      setSource(CompanySuggestionsSource.COMPANY_PAGE_ANY);
    }
  };
  const totalHitsString = (): string => {
    if (isLoading) return "...";
    else if (data) return (data.totalHits >= 10000 ? "10.000+" : data.totalHits) + " " + t("publications.results");
    else return "0 " + t("publications.results");
  };
  useEffect(() => {
    searchCompanies(getCompanySearchQuery(query));
  }, [query, pageSize, page, vatNumberFilters, cpvFilters, nutsFilters, accreditationFilters, source]);
  useEffect(() => {
    if (isSuccess && data) {
      setCompanies(data.pages);
    }
  }, [isSuccess, data]);

  if (features !== undefined && !FeatureUtil.hasFeature(features, FeatureName.MARKET_INTELLIGENCE_TAB)) {
    return <ErrorPage errorCode={403}></ErrorPage>;
  }
  return (
    <>
      <div className={"container"}>
        <div className={styles["categories"]}>
          <span
            className={
              styles[
                source === CompanySuggestionsSource.COMPANY_PAGE_PARTICIPANTS ||
                source === CompanySuggestionsSource.COMPANY_PAGE_ANY
                  ? "selected"
                  : ""
              ]
            }
            onClick={() => setSource(CompanySuggestionsSource.COMPANY_PAGE_PARTICIPANTS)}
          >
            {t("companyPage.company_category_title")}
          </span>
          <span
            className={styles[source === CompanySuggestionsSource.COMPANY_PAGE_BUYERS ? "selected" : ""]}
            onClick={() => setSource(CompanySuggestionsSource.COMPANY_PAGE_BUYERS)}
          >
            {t("companyPage.government_category_title")}
          </span>
        </div>
      </div>
      <div className="container">
        <div className="common-grid">
          <div className={styles["sidebar"]}>
            <h1 className={styles["filter-title"]}>Filters</h1>
            <div className={styles["top-search"]}>
              <QueryInput
                query={query}
                queryPlaceholder={t("searchFilterTags.search")}
                isLoading={isLoading}
                updateQueryAndSearch={setQuery}
              />
            </div>
            <div>
              {source !== CompanySuggestionsSource.COMPANY_PAGE_BUYERS && (
                <Checkbox
                  label={t("companyPage.has_tender_experience")}
                  onChange={toggleTenderExperience}
                  selected={source === CompanySuggestionsSource.COMPANY_PAGE_ANY}
                />
              )}
              <Checkbox
                label={t("publications.searchCompanies")}
                selected={vatNumberFilters.length > 0}
                onChange={filterFollowedCompanies}
              />
            </div>
            <div>
              <CodesAutocompleteDropdown
                codeType={CodeType.NUTS}
                updateCodeSearchFilters={(s) => setNutsFilters(s.map((c) => c.code))}
                selectedElements={CodeUtil.getCodeDropdownElements(nutsFilters, t)}
              />
              <CodesAutocompleteDropdown
                codeType={CodeType.CPV}
                updateCodeSearchFilters={(s) => setCpvFilters(s.map((c) => c.code))}
                selectedElements={CodeUtil.getCodeDropdownElements(cpvFilters, t)}
              />
              {source !== CompanySuggestionsSource.COMPANY_PAGE_BUYERS && (
                <CodesAutocompleteDropdown
                  codeType={CodeType.ACCREDITATION}
                  updateCodeSearchFilters={(s) => setAccreditationFilters(s.map((c) => c.code))}
                  selectedElements={CodeUtil.getCodeDropdownElements(accreditationFilters, t)}
                />
              )}
            </div>
          </div>
          <div>
            {/*<div className={styles["filter-buttons"]}>*/}
            {/*  {query !== "" && <FilterButton text={query} close={() => setQuery("")}/>}*/}
            {/*</div>*/}
            <div className={styles["result-hints"]}>
              <div>
                <p>{totalHitsString()}</p>
              </div>

              <div>
                <p>{t("publications.resultsPerPage")}</p>
                <p
                  style={pageSize === 20 ? { fontWeight: "bold" } : { cursor: "pointer" }}
                  onClick={() => setPageSize(20)}
                >
                  20
                </p>
                <p>|</p>
                <p
                  style={pageSize === 50 ? { fontWeight: "bold" } : { cursor: "pointer" }}
                  onClick={() => setPageSize(50)}
                >
                  50
                </p>
                <p>|</p>
                <p
                  style={pageSize === 100 ? { fontWeight: "bold" } : { cursor: "pointer" }}
                  onClick={() => setPageSize(100)}
                >
                  100
                </p>
              </div>
            </div>
            <div>
              {companies.map((company: CompanyPageDto) => {
                return (
                  <CompanyOverviewCard
                    company={company.company}
                    companyType={
                      source === CompanySuggestionsSource.COMPANY_PAGE_BUYERS ? "contracting authority" : "company"
                    }
                    key={company.company.uuid}
                  />
                );
              })}
            </div>
            <div>
              <Paginate currentPage={page} totalHits={data?.totalHits ?? 0} itemsPerPage={pageSize} setPage={setPage} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};